import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const pageMainBlockEditingModalProps = {
  fieldName: {
    type: String,
    required: true,
  },
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Block style",
  },
  pageName: {
    type: String,
    required: false,
    default: "",
  },
  ...modalProps,
} as const;

export type PageMainBlockEditingModalProps = ExtractPropTypes<
  typeof pageMainBlockEditingModalProps
>;
