import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min";
import { findTextElement } from "@cna/common";

export const customCommands = () => {
  const options = {
    "0.0": "0",
    "0.2": "0.2",
    "0.4": "0.4",
    "0.6": "0.6",
    "0.8": "0.8",
    "1.0": "1.0",
    "1.2": "1.2",
    "1.4": "1.4",
    "1.6": "1.6",
    "1.8": "1.8",
    "2.0": "2.0",
  };

  FroalaEditor.DefineIconTemplate(
    "icomoon_design",
    '<i class="icon-[NAME]"></i>'
  );

  FroalaEditor.DefineIcon("topMargin", {
    NAME: "top-margin",
    template: "icomoon_design",
  });
  FroalaEditor.RegisterCommand("topLineSpace", {
    title: "Space Before Paragraph",
    icon: "topMargin",
    type: "dropdown",
    undo: false,
    focus: false,
    options: options,
    refreshAfterCallback: true,
    callback(cmd: string, val: string) {
      const elements = this.selection.blocks() as HTMLElement[];
      const valEm = `${val}em`;
      elements.forEach((el) => {
        const textEl = findTextElement(el);
        textEl.style.marginBlockStart = valEm;
      });
      this.opts.marginBlockStart = valEm;
    },
    refreshOnShow($btn: any, $dropdown: any) {
      const element = this.selection.element();
      const marginValue = element.style.marginBlockStart.slice(0, -2);
      const list = $dropdown.find(
        `[data-param1="${Number(marginValue).toFixed(1)}"]`
      );
      list.addClass("fr-active");
    },
  });

  FroalaEditor.DefineIcon("bottomMargin", {
    NAME: "bottom-margin",
    template: "icomoon_design",
  });
  FroalaEditor.RegisterCommand("bottomLineSpace", {
    title: "Space After Paragraph",
    icon: "bottomMargin",
    type: "dropdown",
    undo: false,
    focus: false,
    options: options,
    refreshAfterCallback: true,
    callback(cmd: string, val: string) {
      const elements = this.selection.blocks() as HTMLElement[];
      const valEm = `${val}em`;
      elements.forEach((el) => {
        const textEl = findTextElement(el);
        textEl.style.marginBlockEnd = valEm;
      });
      this.opts.marginBlockEnd = valEm;
    },
    refreshOnShow($btn: any, $dropdown: any) {
      const element = this.selection.element();
      const marginValue = element.style.marginBlockEnd.slice(0, -2);
      const list = $dropdown.find(
        `[data-param1="${Number(marginValue).toFixed(1)}"]`
      );
      list.addClass("fr-active");
    },
  });

  FroalaEditor.DefineIcon("openInsertButton", {
    NAME: "btn-icon",
    template: "icomoon_design",
  });
  FroalaEditor.RegisterCommand("insertButton", {
    title: "Insert button",
    icon: "openInsertButton",
    popup: true,
    undo: false,
    focus: false,
    callback() {
      if (!this.popups.isVisible("insertButtonPlugin.popup")) {
        this.insertButtonPlugin.showPopup();
      } else {
        this.insertButtonPlugin.hidePopup();
      }
    },
  });
};

FroalaEditor.DefineIcon("liveUserCountIcon", {
  NAME: "user-with-hash",
  template: "icomoon_design",
});
FroalaEditor.RegisterCommand("insertLiveUserCount", {
  title: "Insert Live User count",
  icon: "liveUserCountIcon",
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  callback: function () {
    this.html.insert(
      '<span id="live-user-count" class="non-editable fr-deletable" contenteditable="false">[count]</span>'
    );
  },
});

FroalaEditor.RegisterCommand("insertLink", {
  title: "Insert link",
  icon: "insertLink",
  popup: true,
  undo: false,
  focus: false,
  callback() {
    this.selection.save();
    if (!this.popups.isVisible("insertLinkPlugin.popup")) {
      this.insertLinkPlugin.showPopup();
    } else {
      this.insertLinkPlugin.hidePopup();
    }
  },
});

FroalaEditor.RegisterCommand("imageResize", {
  title: "Image Resize",
  icon: "imageSize",
  popup: true,
  undo: false,
  focus: false,
  callback() {
    if (!this.popups.isVisible("imageResizePlugin.popup")) {
      this.imageResizePlugin.showPopup();
    } else {
      this.imageResizePlugin.hidePopup();
    }
  },
});
