<template>
  <div id="db_welcome" class="page db_welcome-page">
    <HeaderLayout />
    <PageBody />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import {
  FooterLayout,
  HeaderLayout,
  BodyLayout as PageBody,
} from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DBWelcomePage",
  components: { FooterLayout, HeaderLayout, PageBody },
});
</script>
