import { type FilterSettings } from "@/components/vehicleEventsFiltering/types";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { entries } from "@cna/common";
import { computed, type Ref } from "vue";

export default function useVehicleEventsHandler(filters: Ref<FilterSettings>) {
  const versionsHistory = useVersionsHistoryStore();
  const filteredItems = computed(() =>
    versionsHistory.projectVehiclesEvents.events_list.filter((event) => {
      let errors = 0;
      entries(filters.value)?.forEach(([key, value]) => {
        if (!value) {
          return;
        }
        if (value.length === 0) {
          return;
        }

        if (key === "timestamp" || key === "published_at") {
          const fDate = Date.parse(value[0] as string);
          const lDate = Date.parse(value[1] as string);
          const cDate = Date.parse(event[key]!);

          if (!(cDate <= lDate && cDate >= fDate)) {
            errors++;
          }
        } else if (
          typeof value === "string" &&
          !value.includes(event[key] as string)
        ) {
          errors++;
        } else if (!(value as any[]).includes(event[key])) {
          errors++;
        }
      });

      return errors === 0;
    })
  );

  return {
    filteredItems,
  };
}
