<script lang="ts">
import { computed, defineComponent, h, toRefs } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "",
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { text, searchText } = toRefs(props);
    const startIndex = computed(() =>
      text.value.toLowerCase().indexOf(searchText.value.toLowerCase())
    );
    const endIndex = computed(() => startIndex.value + searchText.value.length);
    const highlightedText = computed(() =>
      props.text.slice(startIndex.value, endIndex.value)
    );
    const before = computed(() => text.value.slice(0, startIndex.value));
    const after = computed(() => text.value.slice(endIndex.value));

    return () => {
      if (startIndex.value !== -1 && searchText.value) {
        return h("span", [
          h("span", before.value),
          h("b", highlightedText.value),
          h("span", after.value),
        ]);
      }
      return h("span", props.text);
    };
  },
});
</script>
