const black = "#000000";
const mainWidth = 400;

export const defaultStyles = {
  background_color: "#0a151e",
  background_image_overlay_color: "transparent",
  background_image_overlay_opacity: 0,
  buttons_props_text_color: "#FFFFFF",
  buttons_props_border_color: "#FFFFFF",
  buttons_props_border_width: 0,
  buttons_props_corners_radius: 2.4,
  buttons_props_background_color: "#3D805E",
  disabled_background_color: "#EBEBEB",
  disabled_text_color: "#696969",
  main_background_color: "#FFFFFF",
  main_border_radius: 0,
  main_contact_us_width: `${mainWidth}px`,
  main_email_registration_width: `${mainWidth}px`,
  main_now_connected_width: `${mainWidth}px`,
  main_opacity: 100,
  main_otp_verification_width: `${mainWidth}px`,
  main_padding: "0px 15px 15px 15px",
  main_returning_width: `${mainWidth}px`,
  main_t_and_c_checkbox_alignment: "left",
  main_text_color: "#0A0A0A",
  main_welcome_width: `${mainWidth}px`,
  main_width: `${mainWidth}px`,
  footer_background_color: "#FFFFFF",
  footer_text_color: "#0A0A0A",
  header_background_color: "#FFFFFF",
  form_element_input_style_border_radius: "0px",
  form_element_input_style_border_width: "1px",
  form_element_input_style_border_style: "solid",
  form_element_input_style_border_color: black,
  form_element_input_style_padding_top: "0px",
  form_element_input_style_padding_bottom: "0px",
  form_element_input_style_padding_left: "15px",
  form_element_input_style_padding_right: "15px",
  form_element_input_style_color: black,
  form_element_input_style_hover_border_color: black,
  form_element_input_style_hover_border_width: "2px",
  form_element_input_style_focus_border_color: black,
  form_element_input_style_focus_border_width: "2px",
  form_element_input_style_label_color: black,
  form_element_input_style_error_msg_color: "#ce0e2d",
  form_element_input_style_required_field_color: "#E1335E",
  form_element_checkbox_style_padding: "0px",
  form_element_checkbox_style_border_style: "solid",
  form_element_checkbox_style_border_width: "1px",
  form_element_checkbox_style_border_color: black,
  form_element_checkbox_style_border_radius: "0px",
  form_element_checkbox_style_hover_border_color: black,
  form_element_checkbox_style_hover_border_width: "2px",
  form_element_checkbox_style_focus_border_color: black,
  form_element_checkbox_style_focus_border_width: "2px",
  form_element_checkbox_style_color: black,
  form_element_radio_button_style_label_color: black,
  form_element_radio_button_style_padding: "0px",
  form_element_radio_button_style_border_width: "1px",
  form_element_radio_button_style_border_style: "solid",
  form_element_radio_button_style_border_color: black,
  form_element_radio_button_style_hover_border_width: "2px",
  form_element_radio_button_style_hover_border_color: black,
  form_element_radio_button_style_focus_border_width: "2px",
  form_element_radio_button_style_focus_border_color: black,
  form_element_radio_button_style_color: black,
  main_padding_top: "0px",
  main_padding_bottom: "15px",
  main_padding_left: "15px",
  main_padding_right: "15px",
  two_column_style_gap: "40px",
  traffic_usage_message_background_color: "#dce5df",
  traffic_usage_message_progress_bar_background_color: "#0000001a",
  traffic_usage_message_progress_bar_color: "#4e7e61",
  traffic_usage_message_progress_bar_height: "10px",
  db_title_line_color: "#ec0016",
  edit_text_hover_color: "#007ff5",
  edit_theme_hover_color: "#eb00ff",
  main_checkbox_border_color: "#EC0016",
  main_checkbox_focus_border_color: "rgb(236 0 22 / 25%)",
  background_position: "center center",
  tablet_background_position: "center top",
  mobile_background_position: "center top",
  main_horizontal_position: "center",
  main_vertical_position: "center",
} as const;

export function applyDefaultStyles(
  rootElement: HTMLElement = document.documentElement
): void {
  Object.keys(defaultStyles).forEach((cssVariable) => {
    rootElement.style.setProperty(
      `--${cssVariable}`,
      String(defaultStyles[cssVariable as keyof typeof defaultStyles])
    );
  });
}
