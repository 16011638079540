import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

type imageUploaderConfig = Record<
  string,
  {
    backgroundColor: string;
  }
>;

export type ImageUploaderProps = {
  imageUploaderConfig: imageUploaderConfig;
};

export const imagesEditingModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  fieldName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "{fieldName}",
  },
  ...modalProps,
} as const;

export type ImagesEditingModalProps = ExtractPropTypes<
  typeof imagesEditingModalProps
>;
