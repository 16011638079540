import { useProjectsStore } from "@/stores/projects";
import type { Template } from "@cna/api/editor";
import type {
  BodyTextsProps,
  FooterTextsProps,
  TextFieldValues,
} from "@cna/common";
import { INITIAL_TEXTS, useCommonGeneralStore } from "@cna/common";
import { api } from "./api";
import type { AxiosRequestConfig } from "axios";

class TextsService {
  async fetchTexts(language: string, config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const commonGeneral = useCommonGeneralStore();
    // For new languageKey we should setup init values
    commonGeneral.texts[commonGeneral.currentLanguage] = INITIAL_TEXTS["en"];
    commonGeneral.texts[commonGeneral.currentLanguage] = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .texts.getLang(language, config)
    ).data.result as unknown as TextFieldValues[string];
  }

  async getFieldTextsForAllLanguages(
    templateName: Template,
    fieldName: string
  ) {
    const projects = useProjectsStore();
    const { data } = await api.v1.projects
      .project(projects.currentProjectId!)
      .texts.getField(templateName, fieldName);

    return data.result.values;
  }

  async updateTextField(
    values: Partial<BodyTextsProps | FooterTextsProps>,
    templateName: Template,
    fieldName: string
  ) {
    const projects = useProjectsStore();
    const commonGeneral = useCommonGeneralStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .texts.postField(templateName, {
        field_key: fieldName,
        values,
      });
    await this.fetchTexts(commonGeneral.currentLanguage);
  }
}

const textsService = new TextsService();

export default textsService;
