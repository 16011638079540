<template>
  <div class="settings">
    <LanguagesSection />
    <DomainsSection />
    <FormsSection v-if="isWithEmailRegistration" />
  </div>
</template>
<script lang="ts">
import DomainsSection from "@/routes/settings/domainsSection/DomainsSection.vue";
import FormsSection from "@/routes/settings/formsSection/FormsSection.vue";
import LanguagesSection from "@/routes/settings/languageSection/LanguagesSection.vue";
import { useCommonGeneralStore } from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "Settings",
  components: { LanguagesSection, DomainsSection, FormsSection },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const isWithEmailRegistration = computed(() =>
      [
        "connect_with_email_registration",
        "email_registration_two_column",
      ].includes(commonGeneral.templates.body)
    );

    return {
      isWithEmailRegistration,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings {
  padding: 20px;
}
</style>
