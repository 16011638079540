<template>
  <ButtonWithMenuList
    class="header-sub-menu"
    qa-id="user-menu-button-list"
    :button-config="userMenuParams.buttonConfig"
  >
    <template #default="{ closeMenuList }">
      <MenuListWithButtons
        :config="userMenuParams.config"
        :on-click="closeMenuList"
      />
    </template>
  </ButtonWithMenuList>
</template>

<script lang="ts">
import ButtonWithMenuList from "@/components/buttonWithMenuList/ButtonWithMenuList.vue";
import MenuListWithButtons from "@/components/buttonWithMenuList/menuListsTypes/MenuListWithButtons.vue";
import { usePermissions } from "@/hooks/usePermissions";
import { api } from "@/services/api";
import { useProjectsStore } from "@/stores/projects";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HeaderSubMenu",
  components: {
    ButtonWithMenuList,
    MenuListWithButtons,
  },
  setup() {
    const { adminaccess } = usePermissions();
    const projectsStore = useProjectsStore();
    const router = useRouter();
    const logOut = async () => {
      await api.v1.me.postLogout().then(() => {
        router.replace({ name: "login" });
      });
    };

    const admin = async () => {
      router.push({
        name: "admin",
        params: { projectId: projectsStore.currentProjectId },
      });
    };

    const userMenuParams = computed(() => {
      const cfg = {
        buttonConfig: {
          iconOpen: "icon-dropdown-up",
          iconClose: "icon-dropdown",
          size: "10px",
          menuPosition: "right",
        },
        config: {
          items: [
            {
              title: "Logout",
              icon: "icon-user",
              onClick: logOut,
            },
          ],
        },
      };

      if (adminaccess.value) {
        cfg.config.items.push({
          title: "Admin",
          icon: "icon-settings",
          onClick: admin,
        });
      }

      return cfg;
    });

    return {
      userMenuParams,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-sub-menu:deep(.button-with-menu-list__menu-btn) {
  background-color: #1d4671;
  padding: 8px 12px 8px 12px;
  width: 36px;
  line-height: 20px;
  border-radius: 4px;
  margin-left: 10px;

  &:hover {
    background-color: #39628d;
    border-radius: 4px;
  }
}

.header-sub-menu:deep(.button-with-menu-list__list) {
  color: $dark-blue;
  font-size: 14px;
  top: 38px;
  padding: 5px;
}

.header-sub-menu:deep(.button-with-menu-list__list-item) {
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  top: 38px;

  &:hover {
    background-color: #dbe5e9;
  }
}

.header-sub-menu:deep(.button-with-menu-list__btn--icon) {
  font-size: 18px;
}

.header-sub-menu:deep(.button-with-menu-list__label) {
  margin: 0;
}
</style>
