<template>
  <HoverMenu>
    <template #trigger="{ open }">
      <label qa-id="page-title-dropdown-label" class="label" :class="{ open }"
        >{{ selectedOptionProps?.label ?? selectedOptionProps?.value }}
      </label>
    </template>

    <ul qa-id="page-title-dropdown-menu" class="menu" :aria-hidden="false">
      <li
        v-for="o in options"
        :qa-id="`page-title-dropdown-option.${o.value}`"
        :aria-selected="o.value === optionCopy"
        :key="o.value"
        @click="optionCopy = o.value"
      >
        <span>
          {{ o.label ?? o.value }}
        </span>
      </li>
    </ul>
  </HoverMenu>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { computed, defineComponent, ref, toRefs, type PropType } from "vue";
import HoverMenu from "../hoverMenu/HoverMenu.vue";
import { useWatchPageQuery } from "../../hooks/usePageQuery";

type Option = { label?: string; value: any };

export default defineComponent({
  components: {
    HoverMenu,
  },
  props: {
    option: {
      type: String as PropType<any>,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    arrowColor: {
      type: String,
      default: "white",
    },
  },
  emits: {
    "update:option": (value: any) => !!value,
    updateOption: (value: any) => !!value,
  },
  setup(props, ctx) {
    const { options } = toRefs(props);
    const optionCopy = useVModel(props, "option", ctx.emit);

    const selectedOptionProps = computed(() =>
      options.value.find((x) => x.value === optionCopy.value)
    );

    const showMenu = ref(false);

    useWatchPageQuery(optionCopy);

    return {
      optionCopy,
      selectedOptionProps,
      showMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
  cursor: pointer;

  &:after {
    content: "";
    height: 100%;
    width: 10px;
    background-color: v-bind(arrowColor);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    mask-image: url("@cna/common/assets/images/icons/chevron-down.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    position: absolute;
    padding: 0px 12px;
    top: 0;
    transition: transform 0.3s ease;
  }
}

.menu {
  all: unset;
  border: 1px solid #dbe5e9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.menu li {
  all: unset;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 12px;
  color: #1d4671;
  background-color: white;
  cursor: pointer;

  & span {
    padding-right: 8px;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &[aria-selected="true"] {
    font-weight: bold;
  }

  &:nth-child(even) {
    background-color: #f6f8f9;
  }

  &:hover {
    color: white;
    background-color: #1d4671;
  }
}
</style>
