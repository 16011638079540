<template>
  <header class="modal-header">
    <h2 class="modal-header__title"><slot /></h2>
    <ButtonsGroup v-if="buttonsParams" :buttons-params="buttonsParams" />
  </header>
</template>

<script lang="ts">
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import { type ButtonProps } from "@/components/button/types";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "ModalHeader",
  components: { ButtonsGroup },
  props: {
    buttonsParams: {
      type: Object as PropType<Array<ButtonProps>>,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey1;
  font-family: $primary-font;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid $grey3;
  color: #1d4671;
  padding: 16px 21px 15px 21px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-header__title {
  font-family: inherit;
  line-height: 20px;
  font-size: 16px;
}

.modal-header:deep(.button) {
  border-radius: 4px;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 800;
  height: 32px;
}
</style>
