import { type PageConfig, type Pages } from "@/pages/types";
import type { BodyTemplates, FooterTemplates } from "@cna/api/editor";
import type { AppName } from "@cna/common";
import { type GlobalThemeOverrides } from "naive-ui";

export const PAGES_DEPENDS_ON_FOOTER_TYPE: Map<
  FooterTemplates,
  Array<Pages>
> = new Map<FooterTemplates, Array<Pages>>([
  [
    "with_contact_terms_privacy",
    [
      "welcome",
      "now-connected",
      "contact-us",
      "terms-and-conditions",
      "privacy-policy",
    ],
  ],
  [
    "with_contact_terms_privacy_faq_customSelect",
    [
      "db_welcome",
      "db_now-connected",
      "db_contact-us",
      "db_terms-and-conditions",
      "db_privacy-policy",
      "db_faq",
    ],
  ],
]);

export const PAGES_DEPENDS_ON_BODY_TYPE: Map<
  BodyTemplates,
  Array<Pages>
> = new Map<BodyTemplates, Array<Pages>>([
  ["connect_with_email_registration", ["email-registration-page"]],
  ["email_registration_two_column", ["email-registration-page"]],
]);

export const PAGES_CONFIGS: Map<Pages, PageConfig> = new Map<Pages, PageConfig>(
  [
    ["welcome", { componentName: "WelcomePage", title: "Welcome page" }],
    ["db_welcome", { componentName: "DBWelcomePage", title: "Welcome page" }],
    [
      "now-connected",
      {
        componentName: "NowConnectedPage",
        title: "Now connected",
      },
    ],
    [
      "db_now-connected",
      {
        componentName: "DBNowConnectedPage",
        title: "Now connected",
      },
    ],
    ["contact-us", { componentName: "ContactUsPage", title: "Contact us" }],
    [
      "returning-page",
      { componentName: "ReturningPage", title: "Returning page" },
    ],
    [
      "db_contact-us",
      { componentName: "DBContactUsPage", title: "Contact us" },
    ],
    [
      "terms-and-conditions",
      {
        componentName: "TermsAndConditionsPage",
        title: "T&C",
      },
    ],
    [
      "db_terms-and-conditions",
      {
        componentName: "DBTermsAndConditionsPage",
        title: "T&C",
      },
    ],
    [
      "privacy-policy",
      {
        componentName: "PrivacyPolicyPage",
        title: "Privacy Policy",
      },
    ],
    [
      "db_privacy-policy",
      {
        componentName: "DBPrivacyPolicyPage",
        title: "Privacy Policy",
      },
    ],
    [
      "db_faq",
      {
        componentName: "DBFAQPage",
        title: "FAQ",
      },
    ],
    [
      "otp-verification",
      {
        componentName: "OTPVerificationPage",
        title: "OTP Verification",
      },
    ],
    [
      "email-registration-page",
      {
        componentName: "EmailRegistrationPage",
        title: "Email Registration",
      },
    ],
    [
      "email-verification-page",
      {
        componentName: "EmailVerificationPage",
        title: "Email Verification",
      },
    ],
  ]
);

export const APP_NAME: AppName = "editor";

export const DOMAIN_PROTOCOL = "https://";

export const THEME_OVERRIDES: GlobalThemeOverrides = {
  common: {
    primaryColor: "#123962",
    primaryColorHover: "#123962",
    baseColor: "#FFFFFF",
  },
  Button: {
    color: "#DBE5E9",
    colorHover: "#C2D1D9",
    textColor: "#123962",
    textColorHover: "#123962",
    borderHover: "#C2D1D9",
    fontWeight: "bold",
  },
  Popover: {
    textColor: "#123962",
    padding: "20px",
  },
  Notification: {
    titleFontSize: "18px",
    headerFontWeight: "700",
    headerTextColor: "#123962",
    metaFontSize: "10px",
    descriptionTextColor: "#1D4671",
    iconColorInfo: "#1D4671",
    iconColorWarning: "#B80000",
    iconColorSuccess: "#008163",
    closeIconColor: "#123962",
    padding: "10px",
  },
};
