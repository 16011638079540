<template>
  <div
    class="right-panel-section"
    :class="{
      'color-picker': colorPicker,
      'with-subsections': withSubsections,
    }"
  >
    <div class="right-panel-section__header" v-if="title">
      {{ title }}
      <span
        class="right-panel-section__icon"
        v-if="icon"
        v-tooltip="'Will be applied if there is no background image'"
      >
        <span class="icon" :class="icon" />
      </span>
    </div>
    <div
      class="right-panel-section__content"
      :class="{ 'flex-row-center-space-between': flexRow }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RightPanelSection",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    tooltipText: {
      type: String,
      required: false,
      default: "",
    },
    colorPicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    withSubsections: {
      type: Boolean,
      required: false,
      default: false,
    },
    flexRow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.right-panel-section {
  border-bottom: 1px solid $grey3;
}

.right-panel-section__header {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 20px 8px 20px;
  color: #1d4671;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  font-family: $primary-font;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.3px;
}

.right-panel-section__content {
  padding: 4px 16px 15px 20px;
}

.with-subsections {
  .right-panel-section__content {
    padding: 0 0 3px 0;
  }
}

.right-panel-section:deep(.dropdown__select) {
  height: 26px;
  width: 100%;
  font-size: 12px;
  padding: 4px 7px;
}

.right-panel-section.color-picker {
  .right-panel-section__content {
    padding: 4px 20px 12px 12px;
  }
}

.right-panel-section__icon {
  background: #eaf0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 11px;
}
</style>
