import { useLanguagesStore } from "@/stores/languages";
import { type Language } from "@cna/api/editor";
import { computed, type Ref } from "vue";

export default function useAvailableFoSelectionLanguageTags(
  selectedLanguages: Ref<Array<Language>>
) {
  const languages = useLanguagesStore();
  const allLanguageTags = computed(() => languages.allLanguageTags);

  const availableForSelectionLanguageTags = computed(() => {
    const selectedLanguagesTags = selectedLanguages.value.map(
      ({ lang_tag }) => lang_tag
    );
    const languages = Object.entries(allLanguageTags.value)
      .filter(([tag]) => {
        if (!selectedLanguagesTags.includes(tag)) {
          return tag;
        }
      })
      .map(([tag, value]) => ({
        value: value.isoname,
        key: tag,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));

    return languages;
  });

  return {
    availableForSelectionLanguageTags,
  };
}
