<template>
  <EditorLabel
    :label="label"
    :prepend-icon-class-name="prependIconClassName"
    :prepend-icon-symbol="prependIconSymbol"
  >
    <input
      v-maska="mask"
      class="editor-input__input-element"
      :class="units ? 'editor-input__input-element--with-units' : ''"
      type="text"
      :maxlength="maxlength"
      v-model="syncValue"
      :disabled="disabled"
      @blur="() => $emit('blur')"
      @keydown.enter="() => $emit('keydown-enter')"
    />
    <span v-if="units" class="editor-input__units">{{ units }}</span>
  </EditorLabel>
</template>

<script lang="ts">
import { maska } from "maska";
import { defineComponent } from "vue";
import EditorLabel from "./EditorLabel.vue";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "EditorInput",
  components: {
    EditorLabel,
  },
  directives: { maska },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    prependIconClassName: {
      type: String,
      required: false,
      default: "",
    },
    prependIconSymbol: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      type: [String, Object],
      required: false,
      default: "",
    },
    units: {
      type: String,
    },
    maxlength: String,
  },
  emits: ["update:value", "blur", "keydown-enter"],
  setup(props, { emit }) {
    const syncValue = useVModel(props, "value", emit);

    return {
      syncValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-input__input-element {
  padding: 8px 14px;
  border: solid 1px $grey3;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #1d4671;
  max-width: 95px;
  font-weight: normal;

  &--with-units {
    width: 65px;
    padding-right: 27px;
    text-align: right;
  }

  &:hover {
    border-color: $grey5;
  }

  &:focus {
    outline: none;
    border-color: $dark-blue;
  }
}

.editor-input__units {
  position: absolute;
  right: 12.5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
}

.editor-input__units:hover .editor-input__input-element {
  border-color: $grey3;
  background-color: red;
}
</style>
