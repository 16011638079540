import { useEmailRegistrationStore } from "@/stores/emailRegistration";
import { useProjectsStore } from "@/stores/projects";
import type {
  PostEmailRegistrationFieldInput,
  PostEnableEmailRegistrationFieldsInput,
  PostReorderEmailRegistrationFieldsInput,
  PutEmailRegistrationFieldInput,
} from "@cna/api/editor";
import { useCommonEmailRegistrationStore } from "@cna/common";
import { api } from "./api";
import type { AxiosRequestConfig } from "axios";

class EmailRegistrationService {
  async fetchAllProjectFields(config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const commonEmailRegistration = useCommonEmailRegistrationStore();
    commonEmailRegistration.allProjectFields = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .emailRegistration.fields.get(config)
    ).data.result;
  }

  async fetchAllEmailRegistrationFields(config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const emailRegistration = useEmailRegistrationStore();
    emailRegistration.allEmailRegistrationFields = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .emailRegistration.fields.getAll(config)
    ).data.result;
  }

  async reorderFields(orderedFields: PostReorderEmailRegistrationFieldsInput) {
    const projects = useProjectsStore();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.postReorder(orderedFields);

    await this.fetchAllProjectFields();
  }

  async updateField(fieldKey: string, item: PutEmailRegistrationFieldInput) {
    const projects = useProjectsStore();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.put(fieldKey, item);

    await this.fetchAllProjectFields();
  }

  async toggleField(fieldKey: string, isEnabled: boolean) {
    const projects = useProjectsStore();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.postEnabled([
        {
          key: fieldKey,
          enabled: isEnabled,
        },
      ]);
    await this.fetchAllProjectFields();
    await this.fetchAllEmailRegistrationFields();
  }

  async toggleFields(fields: PostEnableEmailRegistrationFieldsInput) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.postEnabled(fields);

    await this.fetchAllProjectFields();
    await this.fetchAllEmailRegistrationFields();
  }

  async deleteField(fieldKey: string) {
    const projects = useProjectsStore();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.delete(fieldKey);

    await this.fetchAllEmailRegistrationFields();
  }

  async createNewField(data: PostEmailRegistrationFieldInput) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .emailRegistration.fields.post(data);
    await this.fetchAllEmailRegistrationFields();
  }
}

const emailRegistrationService = new EmailRegistrationService();

export default emailRegistrationService;
