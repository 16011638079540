import { debounce } from "@cna/common";
import { computed, ref, watch } from "vue";

export const idWatcher = {
  id: 0,
};
export const editId = ref(-1);
export const isEditing = computed(() => editId.value !== -1);

watch(isEditing, (val) => {
  if (val) {
    document.body.classList.add("editing-text");
    return;
  }
  document.body.classList.remove("editing-text");
});

const scrolling = () => {
  document.body.classList.add("scrolling");

  scrollend();
};
const scrollend = debounce(() => {
  document.body.classList.remove("scrolling");
}, 250);

window.addEventListener("wheel", scrolling);
