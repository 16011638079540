<template>
  <component :is="componentToRender" v-theme-action-box.styles="bodyProps" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import { useCommonGeneralStore } from "../stores/general";
import { bodyProps } from "../utils/themeActionBoxElementProps";

export default defineComponent({
  name: "BodyLayout",
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const { templatesKeys } = commonGeneral;
    const { templateName } = useTemplateNameForLayout("body", templatesKeys);
    const componentToRender = defineAsyncComponent(
      () => import("../templates/body/" + `${templateName}.vue`)
    );

    return {
      componentToRender,
      bodyProps,
    };
  },
});
</script>
