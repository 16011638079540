<template>
  <div class="edit-paddings-element__sizes">
    <div
      class="edit-paddings-element__sizes-item"
      :class="{
        'edit-paddings-element__sizes-item--active': item === selectedSize,
      }"
      v-for="item in sizeOptions"
      @click="handleSizeClick(item)"
      @keyup.enter="handleSizeClick(item)"
      :key="item"
      tabindex="0"
    >
      {{ capitalizeFirstLetter(item) }}
    </div>
  </div>
  <div
    class="edit-paddings-element"
    v-if="isCustomSize"
    ref="customPaddingsBlock"
  >
    <EditorInput
      qa-id="top"
      :value="value[0]"
      @update:value="updatePadding(0, $event)"
      :units="units"
    />
    <div style="display: flex; gap: 58px">
      <EditorInput
        qa-id="left"
        :value="value[3]"
        @update:value="updatePadding(3, $event)"
        :units="units"
      />
      <EditorInput
        qa-id="right"
        :value="value[1]"
        @update:value="updatePadding(1, $event)"
        :units="units"
      />
    </div>
    <EditorInput
      qa-id="bottom"
      :value="value[2]"
      @update:value="updatePadding(2, $event)"
      :units="units"
    />
  </div>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { computed, defineComponent, nextTick, type PropType, ref } from "vue";
import EditorInput from "../../../components/editorInput/EditorInput.vue";
import { capitalizeFirstLetter } from "../../../utils";

interface SizeValues {
  [key: string]: Array<string>;
}

const createArray = (value: string) => Array(4).fill(value);

const sizeValues: SizeValues = {
  s: createArray("20"),
  m: createArray("30"),
  l: createArray("40"),
};

export default defineComponent({
  name: "EditPaddingsElement",
  components: {
    EditorInput,
  },
  props: {
    value: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    sizes: {
      type: Array as PropType<Array<string>>,
      default: () => ["s", "m", "l"],
    },
  },
  emits: ["update:value"],
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);
    const units = "px";
    const selectedSize = ref("");

    const customPaddingsBlock = ref();
    const isCustomSize = computed(() => selectedSize.value === "custom");

    const sizeOptions = computed(() => [...props.sizes, "custom"]);

    const updatePadding = (index: number, value: string) => {
      valueCopy.value = [
        ...valueCopy.value.slice(0, index),
        value,
        ...valueCopy.value.slice(index + 1),
      ];
    };

    const handleSizeClick = (value: string) => {
      selectedSize.value = value;
      if (value !== "custom") {
        valueCopy.value = sizeValues[value];
      } else {
        nextTick(() => {
          customPaddingsBlock.value?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        });
      }
    };

    const arrayEquals = (a: string[], b: string[]) =>
      a.every((val, index) => val === b[index]);

    const determineSizeKey = () => {
      for (const [key, val] of Object.entries(sizeValues)) {
        if (arrayEquals(props.value, val)) {
          selectedSize.value = key;
          return;
        }
      }
      selectedSize.value = "custom";
    };

    determineSizeKey();

    return {
      units,
      updatePadding,
      valueCopy,
      handleSizeClick,
      selectedSize,
      capitalizeFirstLetter,
      customPaddingsBlock,
      sizeOptions,
      isCustomSize,
    };
  },
});
</script>

<style lang="scss" scoped>
.edit-paddings-element {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  font-weight: 400;
}

.edit-paddings-element:deep(input) {
  width: 58px;
}

.edit-paddings-element__sizes {
  display: flex;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

.edit-paddings-element__sizes-item {
  --border: 1px solid #dbe5e9;
  border: var(--border);
  padding: 8px 16px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-collapse: collapse;

  &:hover,
  &:focus {
    outline: none;
    background-color: $grey2;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:not(:first-child) {
    margin-left: -0.5px;
    border-left-color: transparent;
  }

  &:last-child {
    flex: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &--active {
    background-color: $dark-blue;
    border-color: $dark-blue;
    color: $white;
    &:hover,
    &:focus {
      background-color: $grey2;
      color: $dark-blue;
      border-color: #dbe5e9;
      border-left-color: transparent;
    }
  }
}
</style>
