<template>
  <div id="db_contact-us" class="page db_contact-us-page">
    <div class="db-secondary-page">
      <HeaderLayout />
      <DBSecondaryPageBody
        :markdown-data="markdownData"
        :logo-url="headerLogo.link"
        field="contact_us_body"
        template="footer"
      />
      <FooterLayout />
    </div>
  </div>
</template>

<script lang="ts">
import {
  DBSecondaryPageBody,
  FooterLayout,
  HeaderLayout,
  useCommonGeneralStore,
  useCommonImagesStore,
} from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "DBContactUsPage",
  components: {
    HeaderLayout,
    FooterLayout,
    DBSecondaryPageBody,
  },
  setup() {
    const commonImages = useCommonImagesStore();
    const commonGeneral = useCommonGeneralStore();
    const markdownData = computed(
      () => commonGeneral.footerTexts.contact_us_body
    );
    const headerLogo = computed(() => commonImages.headerLogo);

    return {
      markdownData,
      headerLogo,
    };
  },
});
</script>
