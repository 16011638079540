<template>
  <div class="vehicle-events-filtering">
    <filter-field label="Vehicle ID">
      <multi-select-dropdown
        key="system_id_filter"
        :items="getUniqueValues('system_id')"
        :selected-items="selectedFilters.system_id"
        @change="selectedFilters.system_id = $event as string[]"
        placeholder="Select ID"
      />
    </filter-field>
    <filter-field label="Software Version">
      <multi-select-dropdown
        key="gvc_version_filter"
        :items="getUniqueValues('gvc_version')"
        :selected-items="selectedFilters.gvc_version"
        @change="selectedFilters.gvc_version = $event as string[]"
        placeholder="Select Version"
      />
    </filter-field>
    <filter-field label="Last Synced Between">
      <date-picker
        key="published_time_filter"
        v-model:date="selectedFilters.timestamp"
        placeholder="Select Date"
      />
    </filter-field>
    <filter-field label="Published By">
      <multi-select-dropdown
        key="published_by_filter"
        :items="getUniqueValues('published_by')"
        :selected-items="selectedFilters.published_by"
        @change="selectedFilters.published_by = $event as string[]"
        placeholder="Select Email"
      />
    </filter-field>
    <filter-field label="Content Version">
      <multi-select-dropdown
        key="published_by_filter"
        :items="getUniqueValues('content_version')"
        :selected-items="selectedFilters.content_version"
        @change="selectedFilters.content_version = $event as number[]"
        placeholder="Select Version"
      />
    </filter-field>

    <filter-field label="Status">
      <simple-dropdown
        key="published_by_filter"
        :items="
          getUniqueValues('status', [
            { key: 'success', value: 'success' },
            { key: 'failure', value: 'failure' },
          ])
        "
        :current-item="selectedFilters.status"
        :on-change="(v) => (selectedFilters.status = v as string)"
        placeholder="Select Status"
      />
    </filter-field>

    <ButtonsGroup
      class="vehicle-events-filtering__actions"
      :buttons-params="buttonsConfig"
    />
  </div>
</template>

<script lang="ts">
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import DatePicker from "@/components/datePicker/DatePicker.vue";
import { type FilterSettings } from "@/components/vehicleEventsFiltering/types";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import type { VehicleEvent } from "@cna/api/editor";
import { objectDeepCopy, type DropdownItems } from "@cna/common";
import { computed, defineComponent, ref, type PropType } from "vue";
import FilterField from "./FilterField.vue";
import SimpleDropdown from "../dropdown/SimpleDropdown.vue";
import MultiSelectDropdown from "../dropdown/MultiSelectDropdown.vue";

export default defineComponent({
  name: "VehicleEventsFiltering",
  components: {
    ButtonsGroup,
    FilterField,
    DatePicker,
    MultiSelectDropdown,
    SimpleDropdown,
  },
  props: {
    filtering: {
      type: Object as PropType<FilterSettings>,
      required: true,
    },
    initialValue: {
      type: Object as PropType<FilterSettings>,
      required: true,
    },
  },
  emits: ["update:filtering"],
  setup(props, { emit }) {
    const versionsHistory = useVersionsHistoryStore();
    const selectedFilters = ref(objectDeepCopy(props.initialValue));

    const clearFilters = () => {
      selectedFilters.value = objectDeepCopy(props.initialValue);
      emit("update:filtering", objectDeepCopy(selectedFilters.value));
    };
    const applyFilters = () =>
      emit("update:filtering", objectDeepCopy(selectedFilters.value));

    const vehicleEvents = computed(
      () => versionsHistory.projectVehiclesEvents.events_list || []
    );

    const getUniqueValues = (
      key: keyof VehicleEvent,
      additionalValues: DropdownItems = []
    ) => {
      const items: DropdownItems = [];

      const foundValues: Record<keyof any, boolean> = {};

      additionalValues.forEach((option) => {
        items.push(option);
        foundValues[option.value] = true;
      });

      vehicleEvents.value.forEach((element: VehicleEvent) => {
        const val = element[key];
        if (!val) {
          return;
        }

        if (foundValues[val]) {
          return;
        }

        foundValues[val] = true;
        items.push({ key: val, value: val });
      });

      return items;
    };

    const buttonsConfig = [
      {
        title: "Clear filters",
        onClick: clearFilters,
      },
      {
        title: "Apply filters",
        onClick: applyFilters,
      },
    ];

    return {
      selectedFilters,
      buttonsConfig,
      getUniqueValues,
    };
  },
});
</script>

<style lang="scss" scoped>
.vehicle-events-filtering {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px 52px;
  background-color: $grey2;
  padding: 10px 20.4px 15px 20.4px;
  border-radius: 8px;
}

.vehicle-events-filtering__actions {
  grid-column-start: 4;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.vehicle-events-filtering__actions:deep(.button) {
  max-height: 32px;
}
</style>
