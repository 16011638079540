<template>
  <div ref="generalPage" class="general-page fr-view">
    <component :is="pageComponentName" />
  </div>
</template>

<script lang="ts">
import { editorHeaderBounds } from "@/components/header/EditorHeader.vue";
import { PAGES_CONFIGS } from "@/constants";
import { editorNavigationBounds } from "@/routes/editor/navigationBar/NavigationBar.vue";
import { useProjectsStore } from "@/stores/projects";
import { getProjectPagesDependsOnFooterAndBodyTypes } from "@/utils";
import { computed, defineComponent, ref, type PropType } from "vue";
import ContactUsPage from "./ContactUsPage.vue";
import DBContactUsPage from "./DB/DBContactUsPage.vue";
import DBFAQPage from "./DB/DBFAQPage.vue";
import DBNowConnectedPage from "./DB/DBNowConnectedPage.vue";
import DBPrivacyPolicyPage from "./DB/DBPrivacyPolicyPage.vue";
import DBTermsAndConditionsPage from "./DB/DBTermsAndConditionsPage.vue";
import DBWelcomePage from "./DB/DBWelcomePage.vue";
import EmailRegistrationPage from "./EmailRegistrationPage.vue";
import EmailVerificationPage from "./EmailVerificationPage.vue";
import NowConnectedPage from "./NowConnectedPage.vue";
import OTPVerificationPage from "./OTPVerificationPage.vue";
import PrivacyPolicyPage from "./PrivacyPolicyPage.vue";
import ReturningPage from "./ReturningPage.vue";
import TermsAndConditionsPage from "./TermsAndConditionsPage.vue";
import { type Pages } from "./types";
import usePagesTabSwitcher from "./usePagesTabSwitcher";
import WelcomePage from "./WelcomePage.vue";

export default defineComponent({
  name: "GeneralPage",
  components: {
    ContactUsPage,
    TermsAndConditionsPage,
    WelcomePage,
    NowConnectedPage,
    DBWelcomePage,
    PrivacyPolicyPage,
    DBPrivacyPolicyPage,
    DBTermsAndConditionsPage,
    DBNowConnectedPage,
    DBContactUsPage,
    DBFAQPage,
    ReturningPage,
    OTPVerificationPage,
    EmailRegistrationPage,
    EmailVerificationPage,
  },
  props: {
    page: String as PropType<Pages>,
  },
  setup(props) {
    const projects = useProjectsStore();
    const pages = computed(() => projects.projectPages);
    const pageOrDefault = computed(() => {
      if (props.page) return props.page;

      return pages.value.length > 0 ? pages.value[0] : "welcome";
    });
    const getPageComponentName = (page: Pages) => {
      const { componentName } = PAGES_CONFIGS.get(page)!;
      return componentName;
    };
    const pageComponentName = computed(() => {
      return getPageComponentName(pageOrDefault.value);
    });
    const generalPage = ref();

    usePagesTabSwitcher();
    getProjectPagesDependsOnFooterAndBodyTypes();

    const pageHeightOffset = computed(() => {
      if (!editorHeaderBounds.height.value) {
        return "0px";
      }
      return (
        editorHeaderBounds.height.value +
        editorNavigationBounds.height.value +
        40 +
        "px"
      );
    });

    return {
      pageComponentName,
      generalPage,
      pageHeightOffset,
    };
  },
});
</script>

<style lang="scss">
.fixed-header-margin {
  margin-top: 60px;
}

.general-page {
  margin: 0 auto;
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh - v-bind(pageHeightOffset));
  box-shadow: 0px 8px 16px 0px #00334c1a;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  min-height: calc(100vh - v-bind(pageHeightOffset));
  max-height: 100%;
  font-weight: normal;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
