export interface RangeSliderProps {
  minValue: number;
  maxValue: number;
  title?: string;
  units: string;
}

export enum RangeSliderUnits {
  PX = "px",
  PERCENTAGE = "%",
}
