<template>
  <Modal
    @close="$emit('close')"
    class="vehicle-events-modal"
    :closable="closable"
  >
    <ModalHeader :buttons-params="modalButtons">
      {{ modalConfig.modalTitle }}
    </ModalHeader>
    <div class="modal-content">
      <VehicleEventsTable :show-checkboxes="showCheckboxes" />
    </div>
  </Modal>
</template>

<script lang="ts">
import { type ButtonProps } from "@/components/button/types";
import { defineComponent, type PropType } from "vue";
import { Modal, ModalHeader, modalProps } from "../../../elements";
import { type ModalConfig } from "../../../types";
import VehicleEventsTable from "./VehicleEventsTable.vue";

export default defineComponent({
  name: "VehicleEventsModal",
  components: {
    ModalHeader,
    Modal,
    VehicleEventsTable,
  },
  props: {
    modalConfig: {
      type: Object as PropType<ModalConfig>,
      required: true,
    },
    modalButtons: {
      type: Array as PropType<ButtonProps[]>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
    },
    showCheckboxes: Boolean,
    ...modalProps,
  },
});
</script>

<style>
.vehicle-events-modal.modal {
  width: 80vw;
}
</style>

<style scoped>
.modal-content {
  --table-row-height: 60px;
  --table-filters-height: 152px;
  --table-header-height: 54px;
  --table-margin-top: 20px;
  --spacing: 80px;
  min-height: calc(
    v-bind(itemsPerPage) * var(--table-row-height) + var(--table-header-height) +
      var(--table-filters-height) + var(--table-margin-top) + var(--spacing)
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
