import { useRouteQuery } from "@cna/common";
import { useCommonGeneralStore } from "@cna/common/stores";
import { storeToRefs } from "pinia";
import { watch } from "vue";

export const useVenueHealth = () => {
  const commonGeneral = useCommonGeneralStore();

  const { isVenueHealthy } = storeToRefs(commonGeneral);

  // invesrse route query so blank = venue healthy
  const venueUnhealthy = useRouteQuery("venueUnhealthy", {
    type: Boolean,
  });

  watch(
    venueUnhealthy,
    (val) => {
      isVenueHealthy.value = !val;
    },
    { immediate: true }
  );

  watch(
    isVenueHealthy,
    (val) => {
      venueUnhealthy.value = !val;
    },
    { immediate: true }
  );

  return {
    isVenueHealthy,
  };
};
