import { useWatchLangQuery } from "@/hooks/useWatchLangQuery";
import { metaKeyOrEditor } from "@/routes/router";
import projectsService from "@/services/projects.service";
import saveGeneralDataToCommonStore from "@/utils/commonLibUtils";
import {
  setCssVariablesForImages,
  setCssVariablesForStyles,
  useCommonImagesStore,
  useCommonStylesStore,
  resetStyles,
  useRouteQuery,
} from "@cna/common";
import { computed, defineComponent, h, type Ref, toRefs, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import { setCssVariablesForEditorSettings } from "../utils/editorSettingsUtils";
import { useProjectsStore } from "../stores/projects";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import versionsHistoryService from "@/services/versionsHistory.service";

export const WithProjectId = defineComponent({
  name: "with-project-id",
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { projectId } = toRefs(props);

    const commonImages = useCommonImagesStore();
    const commonStyles = useCommonStylesStore();
    const versionsHistory = useVersionsHistoryStore();
    const project = useProjectsStore();

    useWatchLangQuery();
    saveGeneralDataToCommonStore();

    const versionID = useRouteQuery("version_id", {
      type: String,
      default: "",
    }) as Ref<string>;

    const isPreview = computed(
      () => route.path === `/${projectId.value}/preview`
    );

    const wrongVersionId = computed(
      () =>
        !versionsHistory.versions.find(
          (version) => version.id === Number(versionID.value)
        ) && versionID.value !== undefined
    );

    const initSettings = async (projectId: number) => {
      projectsService.updateCurrentProjectId(projectId);
      if (isPreview.value) {
        await handlePreview();
      } else {
        projectsService.initProjectData();
      }
    };

    const handlePreview = async () => {
      await versionsHistoryService.fetchVersions();
      if (wrongVersionId.value) {
        await router.push({ name: "NotFound" });
      } else {
        projectsService.initProjectData(versionID.value);
      }
    };

    watch(
      () => commonStyles,
      () => {
        resetStyles();
        setCssVariablesForStyles(commonStyles.mainBlockStyles);
        setCssVariablesForStyles(commonStyles.footerStyles);
        setCssVariablesForStyles(commonStyles.headerStyles);
        setCssVariablesForStyles(commonStyles.defaultFonts);
      },
      { deep: true }
    );
    watch(
      () => commonImages,
      () => {
        setCssVariablesForImages(commonImages.bodyBackgroundStyles);
      },
      { deep: true }
    );
    watch(
      () => project,
      () => {
        setCssVariablesForEditorSettings(
          project.currentProject.editor_settings ?? {}
        );
      },
      { deep: true }
    );

    watch(
      projectId,
      (val) => {
        initSettings(+val);

        router.push({
          path: `/${val}/${metaKeyOrEditor(route)}`,
          query: { ...route.query },
        });
      },
      { immediate: true }
    );

    return () => h(RouterView);
  },
});
