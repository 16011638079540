import type { GetImagesResult } from "@cna/api/editor";
import parseSides from "parse-css-sides";
import { DEFAULT_BACKGROUND_COLOR } from "../constants";
import type {
  BodyStyles,
  DBBodyStyles,
  DBFooterStyles,
  DefaultFontsData,
  FooterStyles,
  HeaderStyles,
} from "../stores/types";
import { defaultStyles } from "./defaultStyles";
const root: HTMLElement | null = document.querySelector(":root");

const setPaddingVars = (padding: string, key: string) => {
  const styles = parseSides(padding);

  root?.style.setProperty(`--${key}_left`, styles.left);
  root?.style.setProperty(`--${key}_right`, styles.right);
  root?.style.setProperty(`--${key}_top`, styles.top);
  root?.style.setProperty(`--${key}_bottom`, styles.bottom);
};

export const setCssVariablesForStyles = (
  styles:
    | HeaderStyles
    | BodyStyles
    | DBBodyStyles
    | FooterStyles
    | DBFooterStyles
    | DefaultFontsData,
  fieldName?: string
) => {
  for (const [key, value] of Object.entries(styles)) {
    if (typeof value === "object") {
      setCssVariablesForStyles(value, key);
    } else {
      fieldName
        ? root?.style.setProperty(`--${fieldName}_${key}`, value)
        : root?.style.setProperty(`--${key}`, value);
    }

    if (key === "main_padding") {
      setPaddingVars(value, key);
    }
  }
};

export const resetStyles = () =>
  Object.entries(defaultStyles).forEach(([key, value]) =>
    root?.style.setProperty(`--${key}`, String(value))
  );

export const setCssVariablesForImages = (
  imagesData: GetImagesResult["result"][string]
) => {
  for (const [key, value] of Object.entries(imagesData)) {
    const { props } = value;
    if (value.link) {
      root?.style.setProperty(`--${key}`, `url(${value.link})`);
    } else {
      root?.style.setProperty(`--${key}`, "");
    }

    root?.style.setProperty(
      `--${key}-color`,
      props?.background_color ?? DEFAULT_BACKGROUND_COLOR
    );
  }
};

export const getFlagIcon = (name: string) => {
  try {
    return new URL(
      `../assets/images/flags/${name.toLocaleLowerCase()}.webp`,
      import.meta.url
    ).href;
  } catch (e) {
    return "";
  }
};
