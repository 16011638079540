<template>
  <button ref="button" class="db-sm-button" @click="config.onClick">
    <span class="db-sm-button__title">
      <SimpleText :field="field" :template="template">
        {{ config.title }}
      </SimpleText>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { mdFieldAndTemplateProps } from "../../markdown/props";
import { type ButtonProps } from "./types";

export default defineComponent({
  name: "DBSmallButton",
  props: {
    config: {
      type: Object as PropType<ButtonProps>,
      required: true,
    },
    ...mdFieldAndTemplateProps,
  },
});
</script>

<style lang="scss" scoped>
.db-sm-button {
  position: relative;
  min-width: 135px;
  padding: 9px 12px;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  text-decoration: none solid rgb(103, 108, 119);
  z-index: 2;
  cursor: pointer;
  background-color: var(--logout_button_props_laptop_background_color);
  color: var(--logout_button_props_laptop_text_color);
  user-select: none;
  // Needs to convert connect_button_props_corners_radius variable into string with px suffix
  border-radius: calc(var(--logout_button_props_corners_radius) * 1px);

  &:hover {
    background-color: var(--logout_button_props_hover_background_color);
  }

  &:focus {
    background-color: var(--logout_button_props_hover_background_color);
    border-color: var(--logout_button_props_focus_border_color);
  }

  &:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    border-radius: calc(var(--logout_button_props_corners_radius) * 1px);
  }

  &:hover:before,
  &:focus:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.db-sm-button__title {
  position: relative;
  z-index: 2;
}

@media (max-width: $db-tablet-lg) {
  .db-sm-button {
    background-color: var(--logout_button_props_mobile_background_color);
    color: var(--logout_button_props_mobile_text_color);

    &:hover,
    &:focus {
      color: var(--logout_button_props_mobile_hover_color);
    }
  }
}
</style>
