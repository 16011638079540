<template>
  <VueDatePicker
    v-model="sync"
    range
    multi-calendars
    multi-calendars-solo
    clearable
    :start-date="sync ? sync[0] : undefined"
    :focus-start-date="true"
    :format="format"
    placeholder="Select Date"
    input-class-name="date-picker__input"
    calendar-class-name="date-picker__calendar"
    calendar-cell-class-name="date-picker__calendar-cell"
    month-name-format="long"
    :offset="4"
    @open="onOpen"
    @closed="onClose"
  >
    <template #input-icon>
      <span class="date-picker__icon-calendar icon-calendar" />
    </template>
    <template #calendar-header="{ index }">
      <div>
        {{ weekDays[index] }}
      </div>
    </template>
    <template #arrow-left>
      <span class="date-picker__nav-icon icon-arrow-left" />
    </template>
    <template #arrow-right>
      <span class="date-picker__nav-icon icon-arrow-right" />
    </template>
    <template #action-row="{ closePicker, selectDate }">
      <div class="date-picker__actions-row">
        <ButtonsGroup
          :buttons-params="[
            { title: 'Close', onClick: closePicker },
            { title: 'Select', onClick: onSelect(selectDate) },
          ]"
        />
      </div>
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  type PropType,
  type Ref,
  defineComponent,
  nextTick,
  ref,
  toRefs,
  watch,
} from "vue";
import {
  type DatePickerItems,
  type DatePickerProps,
} from "@/components/datePicker/types";

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const format = (date: [Date, Date]) => {
  return `${new Intl.DateTimeFormat().format(
    date[0]
  )} - ${new Intl.DateTimeFormat().format(date[1])}`;
};

export default defineComponent({
  name: "DatePicker",
  components: { VueDatePicker, ButtonsGroup },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    date: {
      type: Array as unknown as PropType<DatePickerProps>,
      required: true,
    },
  },
  emits: {
    "update:date": (date: DatePickerProps) => !!date || undefined,
  },
  setup(props, { emit }) {
    const { date } = toRefs(props);

    const sync = ref(date.value) as Ref<DatePickerProps>;

    watch(date, (value) => {
      sync.value = value;
    });

    const onOpen = () => {
      if (sync.value === undefined) {
        sync.value = [new Date(), new Date()] as DatePickerItems;
      }
    };
    const onClose = () => {
      nextTick(() => {
        if (date.value === undefined) {
          sync.value = undefined;
        }
      });
    };

    const onSelect = (selectDate: () => void) => () => {
      selectDate();
      emit("update:date", sync.value as DatePickerItems);
    };

    return {
      weekDays,
      sync,
      format,
      onOpen,
      onClose,
      onSelect,
    };
  },
});
</script>
