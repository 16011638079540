import {
  inject,
  provide,
  ref,
  type ComputedRef,
  type InjectionKey,
  type Ref,
} from "vue";

type EditingDisabled = {
  editingDisabled: Ref<boolean> | ComputedRef<boolean>;
};

const editingDisabledKey: InjectionKey<EditingDisabled> =
  Symbol("editingDisabledKey");

export const provideEditingDisabled = (
  editingDisabled: EditingDisabled["editingDisabled"]
) => {
  provide(editingDisabledKey, { editingDisabled });
};

export const injectEditingDisabled = () => {
  return inject(editingDisabledKey, { editingDisabled: ref(false) });
};
