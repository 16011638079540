<template>
  <InlineEditor
    :padding="padding"
    :text="text"
    :action-box-id="`edit-text-${field}`"
    :data="data"
    :tag="tag"
    :m-if="mIf"
    :vars="vars"
    enter="div"
    v-model:editing="editing"
    @update:value="editValue = $event"
    @save="save"
  />
</template>

<script lang="ts">
import emailRegistrationService from "@/services/emailRegistration.service";
import type { PostEmailRegistrationFieldInput } from "@cna/api/editor";
import {
  DEFAULT_EMAIL_REGISTRATION_FIELD,
  objectDeepCopy,
  useCommonEmailRegistrationStore,
  useCommonGeneralStore,
} from "@cna/common";
import { mdProps } from "@cna/common/components/markdown/props";
import { storeToRefs } from "pinia";
import { computed, defineComponent, ref, watch } from "vue";
import { useLanguagesStore } from "../../stores/languages";
import InlineEditor from "./components/InlineEditor.vue";

export default defineComponent({
  components: {
    InlineEditor,
  },
  props: {
    ...mdProps,
    padding: {
      type: String,
      default: "3px",
    },
  },
  setup(props) {
    const commonEmailRegistration = useCommonEmailRegistrationStore();
    const commonGeneral = useCommonGeneralStore();
    const languageStore = useLanguagesStore();

    const { currentLanguage } = storeToRefs(commonGeneral);
    const { allProjectFields } = storeToRefs(commonEmailRegistration);
    const { primaryLanguage } = storeToRefs(languageStore);

    let currentField = DEFAULT_EMAIL_REGISTRATION_FIELD;
    const fieldTexts = ref();

    const initTexts = () => {
      allProjectFields.value?.forEach((field) => {
        if (field.key === props.field) {
          currentField = objectDeepCopy(field);
          const {
            payload: { texts },
          } = currentField;
          fieldTexts.value = texts;
        }
      });
    };
    initTexts();

    const text = computed(
      () =>
        fieldTexts.value?.[currentLanguage.value]?.label ??
        fieldTexts.value?.[primaryLanguage.value]?.label
    );

    const editing = ref(false);
    const editValue = ref(text.value);

    watch(text, (val) => {
      editValue.value = val;
      editing.value = false;
    });
    watch(currentLanguage, () => {
      editing.value = false;
    });

    const save = async () => {
      if (!fieldTexts.value[currentLanguage.value]) {
        fieldTexts.value[currentLanguage.value] = { label: "" };
      }
      fieldTexts.value[currentLanguage.value].label = editValue.value;

      await emailRegistrationService.updateField(props.field, {
        ...currentField,
        payload: {
          texts: { ...fieldTexts.value },
          items: currentField.payload?.items,
        },
      } as PostEmailRegistrationFieldInput);

      editing.value = false;
    };

    return {
      text,
      editing,
      editValue,
      save,
    };
  },
});
</script>
