export const setPopupPosition = (
  buttonCMD: string,
  editor: any,
  popup?: HTMLElement
) => {
  const BUTTON_PADDING = 10;
  const BOTTOM_PADDING = 20;
  const viewportHeight = window.innerHeight;
  const $btn = editor.$tb.find(`.fr-command[data-cmd="${buttonCMD}"]`);
  const left = $btn.offset().left + $btn.outerWidth() / 2;
  const buttonPosition = $btn[0].getBoundingClientRect();

  const top =
    $btn.offset().top +
    (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
  editor.popups.show(
    `${buttonCMD}Plugin.popup`,
    left,
    top,
    $btn.outerHeight() - BUTTON_PADDING
  );

  if (popup) {
    popup.style.height = `${
      viewportHeight - buttonPosition.bottom - BOTTOM_PADDING
    }px`;
  }
};

export const getTargetValue = (linkTargetValue: boolean): string =>
  linkTargetValue ? "_blank" : "_self";

export const setTargetValue = (linkTargetValue: string): boolean =>
  linkTargetValue === "_blank";
