<template>
  <button class="db-back-to-login-button" @click="config.onClick">
    <i
      class="db-back-to-login-button__arrow icon-chevron-left"
      role="presentation"
    />
    <span class="db-back-to-login-button__label">
      <SimpleText :template="template" :field="field">
        {{ config.title }}
      </SimpleText>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { mdFieldAndTemplateProps } from "../../markdown/props";
import { type ButtonProps } from "./types";

export default defineComponent({
  name: "BackToLoginButton",
  props: {
    config: {
      type: Object as PropType<ButtonProps>,
      required: true,
    },
    ...mdFieldAndTemplateProps,
  },
});
</script>

<style lang="scss" scoped>
.db-back-to-login-button {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-right: 10px;
  cursor: pointer;
}

.db-back-to-login-button__arrow {
  color: $black;
  line-height: 24px;
  font-size: 24px;
}

.db-back-to-login-button__label {
  color: $black;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
</style>
