<template>
  <div class="save-button-group">
    <div class="button-group">
      <editor-button class="btn" @click="$emit('cancel')"
        ><i class="icon-close" style="font-size: 12px; color: white"></i
      ></editor-button>
      <editor-button
        class="btn"
        @click="$emit('save')"
        :button-style="ButtonStyle.GREEN"
        ><i class="icon-tick" style="font-size: 12px; color: white"></i
      ></editor-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EditorButton from "../../button/EditorButton.vue";
import { ButtonStyle } from "../../button/types";

export default defineComponent({
  components: {
    EditorButton,
  },
  setup() {
    return {
      ButtonStyle,
    };
  },
});
</script>

<style scoped>
.save-button-group {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.save-button-group .button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  gap: 8px;
  padding: 6px 8px;
  padding-top: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.save-button-group .btn {
  padding: 6px;
  min-height: unset;
  font-size: 12px;
}
</style>
