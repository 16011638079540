import { type ExtractPropTypes, type PropType } from "vue";
import { modalProps } from "../../elements";
import { type ModalConfig } from "../../types";

export const plainTextModalProps = {
  config: {
    type: Object as PropType<ModalConfig>,
    required: true,
  },
  ...modalProps,
} as const;

export type PlainTextModalProps = ExtractPropTypes<typeof plainTextModalProps>;
