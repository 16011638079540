<template>
  <div id="welcome" class="page welcome-page">
    <HeaderLayout />
    <BodyLayout />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { BodyLayout, FooterLayout, HeaderLayout } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WelcomePage",
  components: { HeaderLayout, BodyLayout, FooterLayout },
});
</script>
