import { cloneDeep, type Maybe } from "@cna/common";
import { computed, ref, type Ref } from "vue";

export const useEditingStyleUtils = <R extends {}>(
  formElementStyle: Ref<Maybe<R>>
) => {
  const copy = ref(cloneDeep(formElementStyle.value)) as Ref<R>;

  const updateStyleOption = <K extends keyof R>(
    key: K,
    value: Partial<R>[K]
  ) => {
    if (!copy.value) {
      // @ts-ignore
      copy.value = {};
    }

    // @ts-ignore
    copy.value[key] = value;
  };

  const getPixelProp = (key: keyof R, defaultValue: number | string = 0) => {
    const computedProp = computed({
      get() {
        const val = copy.value?.[key];
        if (val === undefined) {
          return Number(String(defaultValue).replace("px", ""));
        }
        return Number(String(val).replace("px", ""));
      },
      set(v: number | undefined) {
        if (v === undefined) {
          updateStyleOption(key, undefined);
          return;
        }
        // @ts-ignore
        updateStyleOption(key, `${Number(v)}px`);
      },
    });

    return computedProp;
  };

  return {
    copy,
    updateStyleOption,
    getPixelProp,
  };
};
