import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import { type PathBuilder } from "../../utils";
import { client } from "./axios";

export type GetBannerResult = Result<string>;

export const banner = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<GetBannerResult>(path.value, config);

  return {
    get,
  };
};
