import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const textAndBackgroundColorEditingModalProps = {
  fieldName: {
    type: String,
    required: true,
  },
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Text and background color",
  },
  ...modalProps,
} as const;

export type TextAndBackgroundColorEditingModalProps = ExtractPropTypes<
  typeof textAndBackgroundColorEditingModalProps
>;
