<template>
  <div class="right-panel-subsection" :class="{ 'color-picker': colorPicker }">
    <div class="right-panel-subsection__header">{{ title }}</div>
    <div
      class="right-panel-subsection__content"
      :class="{ 'flex-row-center-space-between': flexRow }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RightPanelSubsection",
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    colorPicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    flexRow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.right-panel-subsection__header {
  padding: 10px 20px 0 20px;
  color: #1d4671;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  font-family: $primary-font;
  letter-spacing: -0.3px;
}

.right-panel-subsection__content {
  gap: 8px;
  padding: 10px 16px 0 20px;
}

.right-panel-subsection.color-picker {
  .right-panel-subsection__content {
    padding-left: 12px;
    padding-bottom: 4px;
    padding-top: 0px;
  }
}
</style>
