<template>
  <div id="contact-us" class="page contact-us-page">
    <HeaderLayout />
    <SecondaryPageBody
      :markdown-data="footerTexts.contact_us_body"
      template="footer"
      field="contact_us_body"
    />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import {
  FooterLayout,
  HeaderLayout,
  SecondaryPageBody,
  useCommonGeneralStore,
} from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "ContactUsPage",
  components: { FooterLayout, HeaderLayout, SecondaryPageBody },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const footerTexts = computed(() => commonGeneral.footerTexts);

    return {
      footerTexts,
    };
  },
});
</script>
