<template>
  <NPopover v-if="hasAnyOptions" placement="bottom-end" :z-index="20000">
    <template #trigger>
      <slot></slot>
    </template>
    <SpacedList>
      <SpacedListItem v-if="hasVenueHealthSwitch">
        <span>Venue Healthy?</span>
        <Switch
          v-if="hasVenueHealthSwitch"
          qa-id="portal_state_switches.venue_healthy"
          size="small"
          round
          v-model:value="isVenueHealthy"
        ></Switch>
      </SpacedListItem>
    </SpacedList>
  </NPopover>
</template>

<script lang="ts">
import { SpacedList, SpacedListItem } from "@/components/list";
import Switch from "@/components/switch/Switch.vue";
import { useVenueHealth } from "@/hooks/useVenueHealth";
import { useProjectsStore } from "@/stores/projects";
import { NPopover } from "naive-ui";
import { storeToRefs } from "pinia";
import { computed, defineComponent } from "vue";

export default defineComponent({
  components: {
    NPopover,
    Switch,
    SpacedListItem,
    SpacedList,
  },
  setup() {
    const projectStore = useProjectsStore();

    const { editorSettings } = storeToRefs(projectStore);

    const { isVenueHealthy } = useVenueHealth();

    const hasAnyOptions = computed(() => {
      return (
        Object.entries(editorSettings.value.portal_state_switches ?? {}).filter(
          ([_, value]) => !!value
        ).length > 0
      );
    });

    const hasVenueHealthSwitch = computed(
      () => !!editorSettings.value.portal_state_switches?.venue_healthy
    );

    return {
      isVenueHealthy,
      hasVenueHealthSwitch,
      hasAnyOptions,
    };
  },
});
</script>
