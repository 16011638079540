<template>
  <div class="spaced-list-item">
    <slot></slot>
  </div>
</template>

<style scoped>
.spaced-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
</style>
