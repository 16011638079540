export const bodyProps = {
  modalType: "PageBackgroundEditingModal",
  templateName: "body",
  fieldName: "background",
  modalTitle: "Page background",
};

export const headerProps = {
  modalType: "TextAndBackgroundColorEditingModal",
  templateName: "header",
  fieldName: "header",
};

export const headerLogoProps = {
  modalType: "ImagesEditingModal",
  templateName: "header",
  fieldName: "logo",
};

export const buttonProps = {
  modalType: "ButtonStylesEditingModal",
  templateName: "body",
  fieldName: "button",
  spaced: true,
};

export const secondaryPageMainBlockProps = {
  modalType: "TextAndBackgroundColorEditingModal",
  templateName: "body",
  fieldName: "main",
};

export const footerProps = {
  modalType: "TextAndBackgroundColorEditingModal",
  templateName: "footer",
  fieldName: "footer",
};

export const emailRegistrationRadioButtonsGroupProps = {
  modalType: "RadioButtonThemeModal",
  templateName: "body",
  fieldName: "registration_form_element_radio_button_style",
  spaced: true,
};

export const emailRegistrationCheckboxProps = (fieldName: string) => ({
  modalType: "CheckboxThemeModal",
  templateName: "body",
  fieldName,
  spaced: true,
});

export const trafficUsageMessageProps = {
  modalType: "TrafficUsageMessageEditingModal",
  templateName: "header",
  fieldName: "traffic-usage-message-theme-icon",
};

export const mainProps = (pageName?: string) => ({
  pageName: pageName,
  modalTitle: "Block style",
  modalType: "PageMainBlockEditingModal",
  templateName: "body",
  fieldName: "main",
});

export const emailRegistrationInputProps = (fieldName: string) => ({
  modalType: "EmailRegistrationInputSettingsModal",
  templateName: "body",
  fieldName,
  spaced: true,
});
