import { type ExtractPropTypes, type PropType, type StyleValue } from "vue";
import { modalProps } from "../../elements";
import { type ModalConfig } from "../../types";

export type ConfirmationModalButtonConfig = {
  buttonTitle: string;
  buttonIcon?: string;
};

export type ConfirmationModalButtonsTypes = {
  confirmButton: ConfirmationModalButtonConfig;
  cancelButton?: ConfirmationModalButtonConfig;
};

export type ConfirmationModalConfig = ModalConfig & {
  confirmAction: (...args: any[]) => any;
  buttonsConfigs: ConfirmationModalButtonsTypes;
  textArea?: boolean;
  styles?: StyleValue;
};

export const confirmationModalProps = {
  config: {
    type: Object as PropType<ConfirmationModalConfig>,
    required: true,
  },
  ...modalProps,
} as const;

export type ConfirmationModalProps = ExtractPropTypes<
  typeof confirmationModalProps
>;
