<template>
  <div ref="editorNavigation" class="editor-navigation flex-row-center">
    <div class="page-select">
      <span> Pages </span>
      <span> / </span>
      <PageTitleMenu
        qa-id="page-menu"
        style="color: #1d4671; font-weight: bold"
        arrow-color="#1D4671"
        v-model:option="pageCopy"
        :options="pageOptions"
      />
    </div>
    <router-link
      :to="toPreview"
      target="_blank"
      class="editor-navigation__preview-button"
    >
      <span class="icon-open-tab" />
      Preview
    </router-link>
    <div class="portal-controls">
      <PortalStateSwitchPopover>
        <span class="state-options-label"> State Options </span>
      </PortalStateSwitchPopover>
      <EditorDropdown
        class="editor-navigation__language-dropdown"
        :items="dropdownOptions"
        :on-change="onSelectLanguage"
        :current-option-key="currentLanguageKey"
      />
    </div>
  </div>
</template>

<script lang="ts">
import PageTitleMenu from "@/components/pageTitleMenu/PageTitleMenu.vue";
import { PAGES_CONFIGS } from "@/constants";
import { getInjectedHooks } from "@cna/common";
import { useElementBounding, useVModel } from "@vueuse/core";
import { computed, defineComponent, ref, type PropType } from "vue";
import { useRoute } from "vue-router";
import EditorDropdown from "../../../components/dropdown/EditorDropdown.vue";
import { type Pages } from "../../../pages/types";
import { useProjectsStore } from "../../../stores/projects";
import PortalStateSwitchPopover from "./PortalStateSwitchPopover.vue";

const editorNavigation = ref();

export const editorNavigationBounds = useElementBounding(editorNavigation);

export default defineComponent({
  name: "EditorNavigation",
  components: { PageTitleMenu, EditorDropdown, PortalStateSwitchPopover },
  props: {
    page: {
      type: String as PropType<Pages>,
    },
  },
  emits: ["update:page"],
  setup(props, ctx) {
    const languageDropdownProps = getInjectedHooks().useLanguageDropdown();
    const projects = useProjectsStore();
    const pages = computed(() => projects.projectPages);

    const pageOptions = computed(() =>
      pages.value.map((page) => ({
        value: page,
        label: PAGES_CONFIGS.get(page)!.title,
      }))
    );

    const pageCopy = useVModel(props, "page", ctx.emit);

    const route = useRoute();

    const toPreview = computed(() => ({
      path: "preview",
      query: route.query,
    }));

    return {
      pageCopy,
      pageOptions,
      editorNavigation,
      ...languageDropdownProps,
      toPreview,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-navigation {
  position: relative;
  justify-content: space-between;
  padding-bottom: 20px;
  font-family: $primary-font;
  font-weight: bold;
  width: 100%;
}

.editor-navigation .page-select {
  min-width: 35%;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-weight: normal;
  color: #6888ab;
}

.editor-navigation:deep(.dropdown__select) {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: bold;
}

.editor-navigation__preview-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $primary-font;
  color: $dark-blue;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  span {
    margin-right: 5px;
    font-size: 18px;
  }
}

.editor-navigation__language-dropdown {
  text-align: right;
}

.portal-controls {
  min-width: 35%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.state-options-label {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  border: 1px solid $grey3;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #1d4671;
}
</style>
