import { useProjectsStore } from "@/stores/projects";
import { storeToRefs } from "pinia";
import projectsService from "../../services/projects.service";

const swatchesLimit = 16;

const addColor = async (color: string) => {
  const projectStore = useProjectsStore();
  const { swatches } = storeToRefs(projectStore);

  const currentValue = [...swatches.value];
  let newValue: string[];

  const alreadyExists = currentValue.includes(color);
  if (alreadyExists) {
    return;
  }

  if (currentValue.length < swatchesLimit) {
    newValue = [...currentValue, color];
  } else {
    currentValue.shift();
    newValue = [...currentValue, color];
  }

  await projectsService.updateSwatches(newValue);
};

const deleteColor = async (color: string) => {
  const projectStore = useProjectsStore();
  const { swatches } = storeToRefs(projectStore);

  const colors = [...swatches.value];
  const index = colors.indexOf(color.toUpperCase());
  colors.splice(index, 1);

  await projectsService.updateSwatches(colors);
};

export const useSwatches = () => {
  const projectStore = useProjectsStore();
  const { swatches } = storeToRefs(projectStore);

  return {
    swatches,
    deleteColor,
    addColor,
  };
};
