<template>
  <div v-bind="$attrs" ref="div" :id="`froala-editor-${idx}`" />
  <Teleport to="body">
    <ColorPickerPopup
      v-model:value="selectedColor"
      v-model:show="showColorPopup"
      :top="colorPopupTop"
      :left="colorPopupLeft"
    />
  </Teleport>
</template>

<script lang="ts">
import { useStylesStore } from "@/stores/styles";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/plugins/image.min.css";
import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/image.min.js";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  type PropType,
  type Ref,
} from "vue";
import ColorPickerPopup from "./plugins/ColorPickerPopup.vue";
import { customCommands } from "./plugins/customCommands";
import { imageResizePlugin } from "./plugins/imageResizePlugin";
import { insertButtonPlugin } from "./plugins/insertButtonPlugin";
import { insertLinkPlugin } from "./plugins/insertLinkPlugin";
import { useColorPickerPlugin } from "./plugins/useColorPickerPlugin";

const enterBehaviours = {
  p: FroalaEditor.ENTER_P,
  div: FroalaEditor.ENTER_DIV,
  br: FroalaEditor.ENTER_BR,
};

export type EnterBehaviour = keyof typeof enterBehaviours;

export default defineComponent({
  name: "Editor",
  components: { ColorPickerPopup },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    isNowConnectedPage: Boolean,
    toolbarContainer: String,
    idx: {
      type: String,
      default: "0",
    },
    enter: {
      type: String as PropType<EnterBehaviour>,
      default: "p",
    },
  },
  emits: ["update:modelValue", "esc"],
  setup(props, { emit }) {
    const editor: Ref<any> = ref(null);

    const styles = useStylesStore();
    const fontsList = computed(() => styles.fontsList);
    const currentColor = ref("");

    const { selectedColor, colorPopupLeft, colorPopupTop, showColorPopup } =
      useColorPickerPlugin(editor);

    const assertModalHTML = () => {
      if (
        !props.modelValue.trim().startsWith("<") &&
        props.enter !== FroalaEditor.ENTER_BR
      ) {
        return `<${props.enter}>${props.modelValue}</${props.enter}>`;
      }

      return props.modelValue;
    };

    const initFroala = () => {
      editor.value = new FroalaEditor(`#froala-editor-${props.idx}`, {
        key: import.meta.env.VUE_APP_FROALA_LICENSE_KEY,
        events: {
          initialized() {
            editor.value.html.insert(assertModalHTML());
          },
          contentChanged() {
            emit("update:modelValue", editor.value.html.get(true));
          },
          "image.beforeUpload": (images: any) => {
            const image = images[0];

            if (image.size >= 1024 * 200) {
              alert("Image must be smaller than 200kb");
              return false;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              const result = e.target!.result;
              editor.value.image.insert(
                result,
                {},
                {},
                editor.value.image.get()
              );
            };

            reader.readAsDataURL(image);
            return false;
          },
          keyup: (evt: KeyboardEvent) => {
            if (evt.key === "Escape") {
              emit("esc");
              return;
            }

            changeCurrentColor();
          },
          click: changeCurrentColor,
        },
        enter: enterBehaviours[props.enter],
        lineHeights: {
          "0.6": "calc(0.6em + 6px)",
          "0.7": "calc(0.7em + 6px)",
          "0.8": "calc(0.8em + 6px)",
          "0.9": "calc(0.9em + 6px)",
          "1.0 (Default)": "calc(1em + 6px)",
          "1.1": "calc(1.1em + 6px)",
          "1.2": "calc(1.2em + 6px)",
          "1.3": "calc(1.3em + 6px)",
          "1.4": "calc(1.4em + 6px)",
          "1.5": "calc(1.5em + 6px)",
          "1.6": "calc(1.6em + 6px)",
          "1.7": "calc(1.7em + 6px)",
          "1.8": "calc(1.8em + 6px)",
          "1.9": "calc(1.9em + 6px)",
          "2.0": "calc(2em + 6px)",
          Unset: "",
        },
        fontFamilySelection: true,
        fontSize: [
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
          "16",
          "18",
          "20",
          "21",
          "22",
          "24",
          "26",
          "28",
          "30",
          "32",
          "36",
          "48",
          "60",
          "72",
          "96",
        ],
        fontSizeSelection: true,
        imageStyles: {
          "full-width-image": "Full Width",
        },
        fontFamily: {
          "Arial, Helvetica, sans-serif": "Arial",
          "Courier New, Courier, monospace": "Courier New",
          "Georgia, serif": "Georgia",
          "Lucida Sans Unicode, Lucida Grande, sans-serif":
            "Lucida Sans Unicode",
          "Tahoma, Geneva, sans-serif": "Tahoma",
          "Times New Roman, Times, serif": "Times New Roman",
          "Verdana, Geneva, sans-serif": "Verdana",
          "Ubuntu, Arial, sans-serif": "Ubuntu",
          "Ubuntu Mono, Courier New, Courier, monospace": "Ubuntu Mono",
          "Roboto,sans-serif": "Roboto",
          "Oswald,sans-serif": "Oswald",
          "Gotham,sans-serif": "Gotham",
          ...fontsList.value,
        },
        toolbarButtons: {
          moreText: {
            buttons: [
              "fontFamily",
              "fontSize",
              "paragraphFormat",
              "textColor",
              "bold",
              "italic",
              "underline",
            ],
            buttonsVisible: 7,
            align: "left",
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "alignRight",
              "alignJustify",
              "lineHeight",
              "topLineSpace",
              "bottomLineSpace",
            ],
            align: "left",
            buttonsVisible: 7,
          },
          moreRich: {
            buttons: [
              "insertLink",
              "insertImage",
              "insertButton",
              "insertLiveUserCount",
              "formatOL",
              "formatUL",
              "paragraphStyle",
              "outdent",
              "indent",
              "quote",
              "subscript",
              "superscript",
              "strikeThrough",
              "clearFormatting",
            ],
            buttonsVisible: 3,
            align: "left",
          },
          moreMisc: {
            buttons: ["undo", "redo", "selectAll", "html", "help"],
            align: "right",
            buttonsVisible: 3,
          },
        },
        imageInsertButtons: ["imageUpload"],
        quickInsertEnabled: false,
        imageAllowedTypes: ["jpeg", "jpg", "png", "gif", "webp", "svg+xml"],
        zIndex: 102,
        attribution: false,
        charCounterCount: false,
        heightMin: 250,
        linkEditButtons: ["linkOpen", "insert", "linkEdit", "linkRemove"],
        toolbarContainer: props.toolbarContainer,
        imageEditButtons: [
          "imageReplace",
          "imageAlign",
          "imageRemove",
          "|",
          "imageLink",
          "linkOpen",
          "linkEdit",
          "linkRemove",
          "-",
          "imageDisplay",
          "imageAlt",
          "imageResize",
        ],
      });
    };

    customCommands();
    insertButtonPlugin();
    insertLinkPlugin(props.isNowConnectedPage);
    imageResizePlugin();

    onMounted(initFroala);
    onUnmounted(() => {
      editor.value?.destroy();
    });

    const changeCurrentColor = () => {
      const selectedElement = editor.value.selection.element();
      currentColor.value = getComputedStyle(selectedElement).color;
    };

    const div = ref(null);
    return {
      selectedColor,
      colorPopupLeft,
      colorPopupTop,
      showColorPopup,
      currentColor,
      div,
    };
  },
});
</script>

<style scoped>
#froala-editor {
  caret-color: black;
}

#froala-editor:deep(
    .fr-toolbar .fr-command.fr-btn[data-cmd="textColor"] svg path
  ) {
  fill: v-bind(currentColor);
}
</style>
