<template>
  <div class="popup">
    <ColorPicker
      :color="valueSync"
      @update:option="valueSync = $event"
      v-model:show="showSync"
      :to="false"
      :close-on-swatch-select="false"
    />
  </div>
</template>

<script lang="ts">
import ColorPicker from "@/components/colorPicker/ColorPicker.vue";
import { computed, toRefs } from "vue";

export default {
  components: { ColorPicker },
  props: {
    value: String,
    show: Boolean,
    top: String,
    left: String,
  },
  emits: {
    "update:value": (_val?: string) => true,
    "update:show": (_val?: boolean) => true,
    close: () => true,
  },
  setup(props, { emit }) {
    const { value, show } = toRefs(props);

    const valueSync = computed({
      get: () => value.value,
      set: (val) => {
        emit("update:value", val);
      },
    });

    const showSync = computed({
      get: () => show.value,
      set: (val) => {
        emit("update:show", val);
      },
    });

    return {
      valueSync,
      showSync,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: v-bind("top");
  left: v-bind("left");
  z-index: 106;
  width: 0px;
  height: 0px;
}

/** hide color picker trigger */
.popup:deep(.n-color-picker-trigger) {
  height: 18px !important;
  border: none !important;
  visibility: hidden;
}
</style>
