<template>
  <div class="editor-container">
    <div class="editor__route-content">
      <NavigationBar v-model:page="pageOrDefault" />
      <GeneralPage :page="pageOrDefault" />
    </div>

    <div
      ref="rightPanel"
      id="right-panel"
      v-if="projectdesignwrite"
      class="right-panel"
    />
  </div>
</template>

<script lang="ts">
import { provideEditingDisabled } from "@/components/editor/providables";
import { usePermissions } from "@/hooks/usePermissions";
import GeneralPage from "@/pages/GeneralPage.vue";
import type { Pages } from "@/pages/types";
import { useProjectsStore } from "@/stores/projects";
import { onClickOutside } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, defineComponent, ref, watch } from "vue";
import { closeModal } from "../../modals";
import NavigationBar from "./navigationBar/NavigationBar.vue";

export default defineComponent({
  name: "Editor",
  components: { GeneralPage, NavigationBar },
  setup() {
    const { projectdesignwrite } = usePermissions();
    const editingDisabled = computed(() => !projectdesignwrite.value);
    provideEditingDisabled(editingDisabled);

    const projects = useProjectsStore();
    const page = ref<Pages | undefined>(undefined);

    const { projectPages } = storeToRefs(projects);

    const pageOrDefault = computed({
      get() {
        const isValidPage =
          page.value &&
          projectPages.value &&
          projectPages.value.includes(page.value);
        if (isValidPage) {
          return page.value;
        }

        if (projectPages.value) {
          return projectPages.value[0];
        }

        return "welcome";
      },
      set(v) {
        page.value = v;
      },
    });

    const rightPanel = ref(null);

    onClickOutside(
      rightPanel,
      () => {
        const editedElement = document.querySelector(
          ".theme-editable-selected"
        );
        editedElement?.classList.remove("theme-editable-selected");
        closeModal();
      },
      {
        ignore: [
          ".n-color-picker-panel",
          ".editor-navigation__preview-button",
          ".page",
        ],
      }
    );

    watch(pageOrDefault, closeModal);

    return {
      projectdesignwrite,
      pageOrDefault,
      rightPanel,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-container {
  display: flex;
}

.editor__route-content {
  flex: 1;
  padding: 20px;
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  border-left: 1px solid $grey3;
  max-height: calc(100vh - 60px);
  background: $white url("../../assets/images/default.svg") no-repeat center;
}
</style>
