import type { EmailRegistrationField } from "@cna/api/editor";
import { computed, type Ref } from "vue";
import { useCommonGeneralStore } from "../../../stores/general";
import { addRequiredFieldToHTML } from "../../../utils/requiredFields";
import { useLanguagesStore } from "../../../../../editor/src/stores/languages";
import { storeToRefs } from "pinia";

export const useLabel = (config: Ref<EmailRegistrationField>) => {
  const commonGeneral = useCommonGeneralStore();
  const languageStore = useLanguagesStore();
  const { primaryLanguage } = storeToRefs(languageStore);

  const currentLanguage = computed(() => commonGeneral.currentLanguage);
  const fieldTitle = computed(
    () =>
      config.value.payload.texts?.[currentLanguage.value]?.label ??
      config.value.payload.texts?.[primaryLanguage.value]?.label ??
      "<p></p>"
  );
  const label = computed(() =>
    config.value.required
      ? addRequiredFieldToHTML(fieldTitle.value)
      : fieldTitle.value
  );

  return {
    label,
  };
};

export default useLabel;
