import {
  useDocumentVisibility,
  useIdle,
  usePageLeave,
  useWindowScroll,
  useWindowSize,
} from "@vueuse/core";
import { nextTick, onMounted, watch } from "vue";

export const useStayFixedPlease = (cb: () => any) => {
  onMounted(() => {
    nextTick(cb);
    setTimeout(cb, 250);
  });

  const windowScroll = useWindowScroll();
  const windowSize = useWindowSize();
  const hasLeft = usePageLeave();
  const documentVisible = useDocumentVisibility();
  const { idle } = useIdle(60 * 1000);

  watch(
    [
      windowScroll.y,
      windowScroll.x,
      windowSize.height,
      windowSize.width,
      hasLeft,
      idle,
      documentVisible,
    ],
    () => {
      nextTick(cb);
    },
    {
      deep: true,
    }
  );
};
