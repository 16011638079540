<template>
  <div class="color-picker-with-input">
    <div class="color-picker__select-color">
      <ColorPicker
        @update:option="(v) => (withoutOpacity = v)"
        :color="`#${withoutOpacity}`"
        :show-label="false"
        :actions="[]"
      />
      <span class="hash">#</span>
      <EditorInput
        qa-id="color-input"
        class="color-picker__input-color"
        maxlength="6"
        v-model:value="withoutOpacity"
        @blur="saveColor"
        @keydown-enter="saveColor"
      />
    </div>
    <EditorInput
      qa-id="alpha-input"
      class="color-picker__input-alpha"
      v-if="showAlpha"
      v-model:value="opacityOnly"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type WritableComputedRef } from "vue";
import ColorPicker from "../../../components/colorPicker/ColorPicker.vue";
import EditorInput from "../../../components/editorInput/EditorInput.vue";
import { useSwatches } from "../../../components/colorPicker/useSwatches";
import { useVModel } from "@vueuse/core";

const isValidHexColor = (hex: string) => {
  const regex = /^([0-9A-F]{6}|[0-9A-F]{6})$/i;
  return regex.test(hex);
};

export default defineComponent({
  name: "ColorPickerElement",
  components: {
    EditorInput,
    ColorPicker,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    showAlpha: {
      type: Boolean,
      required: false,
      default: false,
    },
    opacity: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  emits: ["update:color"],
  setup(props, { emit }) {
    const syncColor = useVModel(props, "color", emit);
    const { addColor } = useSwatches();

    const withoutOpacity = computed({
      get() {
        if (syncColor.value === "transparent") {
          return "000000";
        }

        return syncColor.value.substring(1, 7);
      },
      set(val) {
        if (val.includes("#")) {
          val = val.substring(1);
        }

        if (!isValidHexColor(val)) return;
        let alpha = "FF";

        if (syncColor.value) {
          alpha = syncColor.value.substring(7, 9);
        }

        if (syncColor.value === "transparent") {
          alpha = "00";
        }

        syncColor.value = `#${val + alpha}`;
      },
    });

    const opacityOnly: WritableComputedRef<number> = computed({
      get() {
        if (syncColor.value === "transparent") {
          return 0;
        }

        if (syncColor.value.length < 8) {
          return props.opacity;
        }

        return Math.round(
          (parseInt(syncColor.value.slice(7, 9), 16) / 255) * 100
        );
      },
      set(val) {
        if (!val || val > 100 || isNaN(val)) return;
        let hex = "#000000";
        if (!syncColor.value || syncColor.value !== "transparent") {
          hex = syncColor.value.substring(0, 7);
        }

        const alpha = Math.floor((Number(val) / 100) * 255)
          .toString(16)
          .toUpperCase();
        syncColor.value = hex + alpha;
      },
    });

    const saveColor = () => {
      if (isValidHexColor(withoutOpacity.value)) {
        addColor("#" + withoutOpacity.value);
      }
    };

    return {
      withoutOpacity,
      opacityOnly,
      syncColor,
      saveColor,
    };
  },
});
</script>

<style lang="scss" scoped>
.color-picker-with-input {
  display: flex;
  gap: 12px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.color-picker__select-color {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 7px;
  max-width: 106px;
  border-radius: 4px;

  &:hover {
    border-color: $grey3;
  }

  &:focus-within {
    border-color: $dark-blue;
  }
}

.hash {
  margin-left: 9px;
  margin-right: 2px;
}

.color-picker__select-color:deep(.editor-input__input-element) {
  padding: 0px;
  outline: none;
  max-width: 56px;
  border: none;
  text-transform: uppercase;
}

.color-picker__select-color:deep(.editor-input__icon) {
  left: 0;
}

.color-picker__select-color:deep(.n-color-picker-trigger) {
  inset: 0;
  width: 20px;
  height: 20px;
}

.color-picker__select-color:deep(.n-color-picker-trigger__fill) {
  overflow: hidden;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid rgba(18, 57, 98, 0.15);
  inset: 0;
}

.color-picker__input-alpha:deep(.editor-input__input-element) {
  border: 1px solid transparent;
  max-width: 58px;
  padding: 8px 24px 8px 8px;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
  text-align: right;
  height: 100%;

  &:hover {
    border-color: $grey3;
  }

  &:focus {
    border-color: $dark-blue;
  }
}

.color-picker__input-alpha:after {
  position: absolute;
  content: "%";
  right: 12px;
  top: 50%;
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
}
</style>
