<template>
  <EmbeddedInputWrap>
    <FloatingLabel :active="Boolean(valueCopy)" :labelFor="config.id!">
      <CommonInput
        :data-field-id="config.id"
        v-model:model-value="valueCopy"
        :config="config"
        :type="type"
      />
      <template #label>
        <EmailField :data="label" :field="field" />
      </template>
    </FloatingLabel>
  </EmbeddedInputWrap>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent, type PropType } from "vue";
import FloatingLabel from "../../floatingLabel/FloatingLabel.vue";
import CommonInput from "../../input/CommonInput.vue";
import { type InputConfig } from "../../types";
import EmbeddedInputWrap from "./EmbeddedInputWrap.vue";

export default defineComponent({
  name: "EmbeddedInput",
  components: { EmbeddedInputWrap, FloatingLabel, CommonInput },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<InputConfig>,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    field: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const valueCopy = useVModel(props, "modelValue", ctx.emit);

    return {
      valueCopy,
    };
  },
});
</script>
