<template>
  <BasicInlineEditor
    :padding="padding"
    :text="text"
    :action-box-id="`edit-text-${field}`"
    v-model:editing="editing"
    @update:value="editValue = $event"
    @save="save"
    v-bind="$props"
  >
    <slot />
  </BasicInlineEditor>
</template>

<script lang="ts">
import { mdFieldAndTemplateProps } from "@cna/common/components/markdown/props";
import { defineComponent, toRefs } from "vue";
import BasicInlineEditor from "./components/BasicInlineEditor.vue";
import { useField } from "./hooks/useField";

export default defineComponent({
  components: {
    BasicInlineEditor,
  },
  props: {
    ...mdFieldAndTemplateProps,
    padding: String,
  },

  setup(props) {
    const { template, field } = toRefs(props);
    const { editValue, editing, save, text } = useField(template, field);

    return {
      text,
      editing,
      editValue,
      save,
    };
  },
});
</script>
