<template>
  <div class="pop-menu" @mouseenter="open = true" @mouseleave="open = false">
    <slot name="trigger" :open="open" />
    <Transition>
      <div v-if="open" class="list-wrapper">
        <slot :open="open" />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const open = ref(false);

    return {
      open,
    };
  },
});
</script>

<style lang="scss" scoped>
.pop-menu {
  position: relative;
}

.pop-menu .list-wrapper {
  position: absolute;
  z-index: 20000001;
  top: 100%;
  left: -4px;
  padding-top: 4px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
