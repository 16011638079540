<template>
  <div v-click-outside="onClose" class="custom-dropdown">
    <div
      v-bind="$attrs"
      ref="selectedItemRef"
      class="custom-dropdown__selected-item"
      tabindex="0"
      @click.prevent="toggleMenu"
      @keydown.enter="toggleMenu"
    >
      <span v-if="!selectedProject?.name" class="custom-dropdown__placeholder">
        {{ placeholder }}
      </span>
      <span class="custom-dropdown__selection" v-else>{{
        selectedProject?.name
      }}</span>
      <span
        v-if="areMenuItemsShown"
        class="icon-dropdown-up custom-dropdown__icon"
      />
      <span v-else class="icon-dropdown custom-dropdown__icon" />
    </div>
    <div v-if="areMenuItemsShown" class="custom-dropdown__dropdown-items">
      <div class="custom-dropdown__search-item">
        <SearchInput
          ref="searchInputRef"
          :config="searchInputConfig"
          :value="searchValue"
          no-results-search-icon="icon-search-default"
          @update:searchInput="onSearchInputUpdate"
        />
      </div>

      <ul class="custom-dropdown__list" @scroll.prevent="">
        <template v-for="(item, index) in items" :key="item.key">
          <li
            ref="menuItemsRefs"
            class="custom-dropdown__list-item"
            :class="{
              'custom-dropdown__list-item--selected':
                item.value === selectedProject?.name,
            }"
            :tabindex="index"
            @click.prevent="onSelectMenuItem(item)"
            @keydown.tab.exact="moveNext"
            @keydown.shift.tab="movePrev"
            @keydown.esc="closeMenu"
            @keyup.enter.exact="onSelectMenuItem(item)"
            @keydown.down="moveNext"
            @keydown.up="movePrev"
          >
            <HighlightedText
              :text="String(item.value)"
              :search-text="searchValue"
            />
          </li>
        </template>
      </ul>

      <div v-if="items.length === 0" class="custom-dropdown__no-items">
        <span>{{ textIfNoItems }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  SearchInput,
  type DropdownItem,
  type DropdownItems,
} from "@cna/common";
import vClickOutside from "click-outside-vue3";
import { defineComponent, toRefs, type PropType } from "vue";
import HighlightedText from "./HighlightedText.vue";
import { useDropdownHelper } from "./useDropdownHelper";

export default defineComponent({
  name: "DropdownWithSearch",
  components: { SearchInput, HighlightedText },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    items: {
      type: Array as PropType<DropdownItems>,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    textIfNoItems: {
      type: String,
      required: false,
      default: "Your search didn't have any matches",
    },
    selectedProject: {
      type: Object,
      required: false,
    },
    fixedPopupWidth: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["select", "change", "close"],
  setup(props, ctx) {
    const { items, fixedPopupWidth } = toRefs(props);

    const {
      areMenuItemsShown,
      selectedItemRef,
      menuItemsRefs,
      searchValue,
      searchInputRef,
      searchInputConfig,
      moveNext,
      movePrev,
      closeMenu,
      toggleMenu,
      onClose,
      popupWidth,
    } = useDropdownHelper(items, fixedPopupWidth, ctx);

    const onSelectMenuItem = (item: DropdownItem) => {
      ctx.emit("select", Number(item.key));
      closeMenu();
    };
    const onSearchInputUpdate = (value: string) => {
      searchValue.value = value;
      ctx.emit("change", value);
    };

    return {
      areMenuItemsShown,
      selectedItemRef,
      menuItemsRefs,
      searchValue,
      searchInputRef,
      moveNext,
      movePrev,
      closeMenu,
      searchInputConfig,
      toggleMenu,
      onSelectMenuItem,
      onSearchInputUpdate,
      onClose,
      popupWidth,
    };
  },
});
</script>

<style scoped>
.custom-dropdown__dropdown-items {
  --dropdown-width: v-bind(popupWidth);
}
</style>
