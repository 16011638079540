import {
  computed,
  defineComponent,
  h,
  ref,
  watch,
  type ExtractPropTypes,
} from "vue";

import { useRoute } from "vue-router";
import { modalMap, type ModalName } from "./asyncModals";

const modalName = ref<ModalName>();
const showModal = computed(() => !!modalName.value);

export const modalProps = ref<any>();

export const closeModal = () => {
  modalName.value = undefined;
  modalProps.value = undefined;
};
export const openModal = <N extends ModalName>(
  name: N,
  props?: Partial<ExtractPropTypes<(typeof modalMap)[N][1]>>
) => {
  if (props) {
    modalProps.value = props;
  }
  modalName.value = name;
};

export const GlobalModal = defineComponent({
  name: "GlobalModal",
  setup() {
    const route = useRoute();

    watch(route, closeModal);

    return () => {
      const modName = modalName.value;
      if (!modName) {
        return null;
      }

      if (!showModal.value) {
        return null;
      }

      return h(modalMap[modName][0], {
        key: modalProps.value.fieldName,
        ...modalProps.value,
        onClose: closeModal,
      });
    };
  },
});
