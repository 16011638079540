import { useRouteQuery } from "@cna/common";
import { type Ref, watch } from "vue";
import router from "../routes/router";
import { storeToRefs } from "pinia";
import { useProjectsStore } from "../stores/projects";

const dbPrefix: string = "db_";
const welcomePageName = "welcome";

export const handleDBPortalPageQueryParams = (pageName: string) => {
  if (!pageName) {
    pageName = `${dbPrefix}${welcomePageName}`;
  }

  if (!pageName.includes(dbPrefix)) {
    pageName = `${dbPrefix}${pageName}`;
  }

  return pageName;
};

export const handlePortalPageQueryParams = (pageName: string) => {
  if (!pageName) {
    pageName = welcomePageName;
  }

  if (pageName.includes(dbPrefix)) {
    pageName = pageName.replace(dbPrefix, "");
  }

  return pageName;
};

export const getPageNameBasedOnPortalType = (pageName: string) => {
  const projects = useProjectsStore();
  const { isDBPortalType } = storeToRefs(projects);

  if (isDBPortalType.value) {
    pageName = handleDBPortalPageQueryParams(pageName);
  } else {
    pageName = handlePortalPageQueryParams(pageName);
  }

  return pageName;
};

export const useWatchPageQuery = (activePage: Ref<string>) => {
  const page = useRouteQuery("page", { type: String });

  watch(
    activePage,
    (page) => {
      if (page) {
        page = getPageNameBasedOnPortalType(page);
        router.push({
          path: router.currentRoute.value.path,
          query: { ...router.currentRoute.value.query, page },
        });
      }
    },
    { immediate: true }
  );

  watch(
    page,
    (v) => {
      if (v) {
        activePage.value = getPageNameBasedOnPortalType(v);
      }
    },
    { immediate: true }
  );
};
