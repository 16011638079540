<template>
  <OneColumnLayout>
    <div
      class="page__main-block"
      v-theme-action-box.styles="mainProps('email_verification')"
    >
      <div class="page__text-block">
        <div class="page__text">
          <div class="page__markdown">
            <Markdown
              :data="texts.email_verification_text"
              field="email_verification_text"
            />
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import { useCommonGeneralStore } from "@cna/common";
import { computed, defineComponent } from "vue";
import { type EmailVerificationPageTexts } from "../../../stores/types";
import { OneColumnLayout } from "../../columns";
import { mainProps } from "../../../utils/themeActionBoxElementProps";

export default defineComponent({
  name: "EmailVerificationPageBody",
  components: {
    OneColumnLayout,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const texts = computed(
      () => commonGeneral.bodyTexts as EmailVerificationPageTexts
    );

    return {
      texts,
      mainProps,
    };
  },
});
</script>
