<template>
  <button :class="buttonStyleClass" :disabled="disabled" @click="handleClick">
    <i v-if="buttonIcon" class="button__icon" :class="buttonIcon" />
    <slot>
      {{ title }}
    </slot>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { buttonsSharedProps } from "./buttonsSharedProps";

export default defineComponent({
  name: "EditorButton",
  props: { ...buttonsSharedProps },
  setup(props) {
    const buttonStyleClass = computed(() =>
      props.disabled
        ? "button button--disabled"
        : `button button--${props.buttonStyle}`
    );

    const handleClick = () => {
      if (!props.onClick) {
        return;
      }

      if (Array.isArray(props.onClick)) {
        props.onClick.forEach((c) => c());
        return;
      }
      props.onClick();
    };

    return {
      buttonStyleClass,
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
  min-height: 36px;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
}

.button--light {
  background-color: $grey3;
  color: $dark-blue;

  &:hover {
    background-color: $grey4;
  }
}

.button--dark {
  background-color: $dark-blue;
  color: $white;

  &:hover {
    background-color: $dark-blue;
  }
}

.button--white {
  background-color: $white;
  color: $dark-blue;
  padding: 8px 11px 8px 12px;
  border-radius: 4px;
  border: 1px solid $grey4;

  &:hover {
    background-color: $grey-dark;
    color: $white;
  }
}

.button--disabled {
  background-color: $grey-dark;
  color: $white;
  cursor: auto;
}

.button__icon {
  font-size: 18px;
  margin-right: 7px;
  margin-top: -2px;
}

.button--green {
  background-color: #008163;
  color: $white;
  padding: 8px 15px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #000000;
    opacity: 0;
    z-index: -1;
  }

  &:hover:after {
    opacity: 0.1;
  }
}
</style>
