<template>
  <div id="email-verification-page" class="page email-verification-page">
    <HeaderLayout />
    <EmailVerificationPageBody />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import {
  FooterLayout,
  HeaderLayout,
  EmailVerificationPageBody,
  useCommonGeneralStore,
  type OTPVerificationPageTexts,
} from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "EmailVerificationPage",
  components: { FooterLayout, HeaderLayout, EmailVerificationPageBody },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const bodyTexts = computed(
      () => commonGeneral.bodyTexts as OTPVerificationPageTexts
    );

    return {
      bodyTexts,
    };
  },
});
</script>
