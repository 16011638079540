import { ref, watch } from "vue";

export function useCustomPopupHelper() {
  const show = ref(false);

  const topPosition = ref("");
  const leftPosition = ref("");
  let button: HTMLButtonElement;

  const positionPopup = (button: HTMLButtonElement) => () => {
    if (!button) return;

    const viewportOffset = button.getBoundingClientRect();
    leftPosition.value = `${viewportOffset.left}px`;
    topPosition.value = `${viewportOffset.top + 37}px`;
  };

  let onUnmount: () => void;

  watch(show, () => {
    if (button) {
      positionPopup(button)();
    }
  });

  const init = (btn: HTMLButtonElement) => {
    button = btn;
    const positionFunction = positionPopup(btn);
    positionFunction();
    window.addEventListener("resize", positionFunction);
    window.addEventListener("scroll", positionFunction);

    onUnmount = () => {
      window.removeEventListener("resize", positionFunction);
      window.addEventListener("scroll", positionFunction);
    };
  };

  const close = (event?: any) => {
    // Need to check that ColorPicker wheel is open and don't close it after color selecting by click
    const isShownColorPicker = document.getElementsByClassName(
      "n-color-picker-panel"
    );
    // In the Froala Editor click on toolbar don't close popups and dropdowns
    const clickedToolbar: boolean =
      event?.target?.className.includes("toolbar");

    if (!isShownColorPicker.length && !clickedToolbar) {
      show.value = false;
    }
    if (onUnmount) {
      onUnmount();
    }
  };

  return {
    show,
    close,
    init,
    leftPosition,
    topPosition,
  };
}
