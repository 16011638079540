import { createClient } from "@cna/api/editor";
import { asyncMemoize } from "@cna/common/utils/memoize";
import type { AxiosInstance } from "axios";
import { v4 } from "uuid";
import { catcher } from "./helpers";

const correlationId = v4();

const setupAxios = (a: AxiosInstance) => {
  a.interceptors.response.use(
    (res) => res,
    (err) => {
      catcher(err);
      return Promise.reject(err);
    }
  );

  a.defaults.headers.common = {
    "x-caller": "cna-editor-frontend",
    "x-correlation-id": correlationId,
  };
};

export const api = createClient(setupAxios);

const _memoFlags = asyncMemoize(api.v1.flags.get);
const _memoPermissions = asyncMemoize(api.v1.me.getPermissions);

// adds constant cache key
export const memoFlags = () => _memoFlags(["flags"], false, 60000);
export const memoPermissions = () =>
  _memoPermissions(["permissions"], false, 60 * 60 * 1000);

//@ts-ignore
window.$$api = api;
