<template>
  <div class="phone-input-with-country-code-dropdown__wrapper">
    <div
      v-click-outside="closeMenu"
      class="phone-input-with-country-code-dropdown"
      tabindex="0"
      @focus="onFocus"
    >
      <CountryCodesDropdown
        ref="countryCodesDropdownRef"
        :phone-input-ref="phoneInputRef"
        :preferred-countries-iso="preferredCountriesIso"
        :selected-country-info="selectedCountryInfo"
        @update:option="onSelectCountry"
      />
      <CommonInput
        ref="phoneInputRef"
        v-model="phoneInput"
        class="phone-input-with-country-code-dropdown__phone_input"
        :config="phoneInputConfig"
        mask="#*"
        @click="closeMenu"
      />
    </div>
    <ErrorMessageInput :message="error" />
  </div>
</template>

<script lang="ts">
import vClickOutside from "click-outside-vue3";
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  watch,
  type PropType,
  type Ref,
} from "vue";
import { type SelectedPhoneNumberWithCountryCodeAndISO } from "../../components/types";
import CountryCodesDropdown from "../countryCodesDropdown/CountryCodesDropdown.vue";
import {
  type CountryData,
  type PreferredCountriesIso,
} from "../countryCodesDropdown/types";
import ErrorMessageInput from "../errorMessageInput/ErrorMessageInput.vue";
import CommonInput from "../input/CommonInput.vue";

export default defineComponent({
  name: "PhoneInputWithCountryCodeDropdown",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    CommonInput,
    ErrorMessageInput,
    CountryCodesDropdown,
  },
  props: {
    preferredCountriesIso: {
      type: Array as PropType<PreferredCountriesIso>,
      required: true,
    },
    phoneNumber: {
      type: Object as PropType<SelectedPhoneNumberWithCountryCodeAndISO>,
      required: false,
      default: () => ({
        code: "",
        phoneNumber: "",
        iso: "",
      }),
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    inputId: String,
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const selectedCountryInfo: Ref<CountryData> = ref({
      name: "",
      code: props.phoneNumber.code,
      iso: props.phoneNumber.iso,
      native: "",
    });
    const phoneInput = ref(props.phoneNumber.phoneNumber);
    const phoneInputRef = ref();
    const countryCodesDropdownRef = ref();

    const selectedPhoneNumberWithCountryCode = computed(() => ({
      code: selectedCountryInfo.value.code,
      phoneNumber: phoneInput.value,
      iso: selectedCountryInfo.value.iso,
    }));

    const closeMenu = () => {
      countryCodesDropdownRef.value.closeMenu();
    };

    const onSelectCountry = (country: CountryData) => {
      selectedCountryInfo.value = country;
    };

    const onFocus = () => {
      nextTick(() => phoneInputRef.value.root.focus());
    };

    const phoneInputConfig = {
      onKeydownShiftTab: (event: Event) => {
        countryCodesDropdownRef.value.selectedItemRef.focus();
        event.preventDefault();
      },
      id: props.inputId,
    };

    watch(
      () => selectedPhoneNumberWithCountryCode.value,
      (value) => {
        emit("update:option", value);
      }
    );

    return {
      phoneInput,
      phoneInputRef,
      countryCodesDropdownRef,
      selectedPhoneNumberWithCountryCode,
      phoneInputConfig,
      selectedCountryInfo,
      onFocus,
      closeMenu,
      onSelectCountry,
    };
  },
});
</script>

<style lang="scss" scoped>
.phone-input-with-country-code-dropdown__wrapper {
  position: relative;
}

.phone-input-with-country-code-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  @include input-styles;
}

.phone-input-with-country-code-dropdown__phone_input:deep(input) {
  height: 23px;
  width: calc(100% - 2px);
  border: none;

  &:focus-visible,
  &:hover {
    outline: none;
  }
}
</style>
