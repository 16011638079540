<template>
  <div
    class="page__main-block"
    v-theme-action-box.styles="mainProps('email_registration')"
  >
    <div class="page__text-block">
      <div class="page__text">
        <div class="page__markdown">
          <Markdown
            :data="bodyTexts.email_registration_text"
            field="email_registration_text"
          />
          <form
            class="page__form"
            method="post"
            @submit="onRegisterButtonClick"
          >
            <div class="page__text">
              <EmailRegistrationFormElement
                v-for="item in allProjectFields"
                :key="item.key"
                :class="item.key"
                :config="item"
                :value="
                  emailRegistrationFormData.info[item.key] ||
                  emailRegistrationFormData.consents?.[item.key]
                "
                @update:input="onChangeInput(item, $event)"
              />
              <ErrorMessageInput :message="error" />
            </div>
            <div class="page__main-block-actions">
              <CommonButton
                :type="connectButtonType"
                @click="resetErrors"
                :config="buttonConfig"
                field="connect_button"
              />
            </div>
          </form>
          <div class="page__after-form-text">
            <Markdown
              v-if="bodyTexts.after_registration_form_text"
              :data="bodyTexts.after_registration_form_text"
              field="after_registration_form_text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useOnceAtATime } from "../../../hooks/useOnceAtATime";
import type { EmailRegistrationPageTexts } from "../../../stores";
import { useCommonEmailRegistrationStore } from "../../../stores/emailRegistration";
import { useCommonGeneralStore } from "../../../stores/general";
import { errorHandler } from "../../../utils/";
import CommonButton from "../../button/CommonButton.vue";
import EmailRegistrationFormElement from "../../emailRegistrationFormElement/EmailRegistrationFormElement.vue";
import ErrorMessageInput from "../../errorMessageInput/ErrorMessageInput.vue";
import { useEmailRegistrationHandler } from "./useEmailRegistrationHandler";
import { mainProps } from "../../../utils/themeActionBoxElementProps";

export default defineComponent({
  name: "EmailRegistrationPageBody",
  components: {
    CommonButton,
    EmailRegistrationFormElement,
    ErrorMessageInput,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const commonEmailRegistration = useCommonEmailRegistrationStore();

    const emailRegistrationFormData = computed(
      () => commonEmailRegistration.emailRegistrationFormData
    );
    const bodyTexts = computed(
      () => commonGeneral.bodyTexts as EmailRegistrationPageTexts
    );

    const connectButtonType = computed(
      () => commonGeneral.typesOfButtons.connectButtonType
    );
    const buttonConfig = computed(() => ({
      title: bodyTexts.value.connect_button,
      disabled: isButtonDisabled.value,
      loading: commonEmailRegistration.isSendingForm,
    }));
    const { isButtonDisabled, allProjectFields } =
      useEmailRegistrationHandler();
    const error = computed(() => errorHandler(commonEmailRegistration.error));

    const onChangeInput = (item: any, value: string | boolean) => {
      if (item.kind === "checkbox") {
        commonEmailRegistration.emailRegistrationFormData.consents[item.key] =
          Boolean(value);
      } else {
        commonEmailRegistration.emailRegistrationFormData.info[item.key] =
          String(value);
      }
    };
    const onRegisterButtonClick = useOnceAtATime(
      commonGeneral.buttonsActions.onRegisterButtonClick
    );

    const resetErrors = () => {
      commonEmailRegistration.error = {};
    };

    return {
      emailRegistrationFormData,
      connectButtonType,
      bodyTexts,
      buttonConfig,
      error,
      onChangeInput,
      onRegisterButtonClick,
      allProjectFields,
      resetErrors,
      mainProps,
    };
  },
});
</script>

<style scoped>
.page__after-form-text:empty {
  display: none;
}
</style>
