import { useNotification } from "@/hooks/use-notification";
import router from "@/routes/router";
import { transformMessage } from "@/utils/getErrorMessage";
import type { EditorError } from "@cna/api/types";
import { HttpStatusCode } from "axios";

export const isNotAuthenticated = (status?: number) =>
  status == HttpStatusCode.Unauthorized;

export const catcher = (error: EditorError) => {
  const notification = useNotification();
  console.error(error.response);

  if (isNotAuthenticated(error?.response?.status)) {
    console.log(error.response?.data.error);

    if (error.config?.url !== "/me/authenticated") {
      router.replace("/login");
    }
    return;
  }

  if (error.response?.status === HttpStatusCode.PayloadTooLarge) {
    notification.error({
      title: "The image is larger than 20MB",
    });
    return;
  }

  if (error.response?.status === HttpStatusCode.NotFound) {
    router.push({ name: "NotFound" });
  }

  notification.error({
    title: transformMessage(error.response?.data.error),
    duration: durationByStatusCode(error.response?.status),
  });
};

const durationByStatusCode = (code?: number) => {
  switch (code) {
    case 400:
      return 3000;
    default:
      return undefined;
  }
};
