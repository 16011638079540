<template>
  <div class="checkbox">
    <label class="checkbox__label">
      <div v-theme-action-box.styles="emailRegistrationCheckboxProps(field)">
        <input
          :id="`checkbox-${uid}`"
          type="checkbox"
          class="checkbox__input"
          :disabled="checkboxDisabled"
          :required="required"
          @change="onCheck"
        />
      </div>
      <slot> </slot>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import confirmTick from "../../assets/images/DB/checkbox/confirm.svg?raw";
import { emailRegistrationCheckboxProps } from "../../utils/themeActionBoxElementProps";
import { injectDisableCheckbox } from "./providables";

const confirmTickCss = `url("data:image/svg+xml;charset=utf8,${encodeURIComponent(
  confirmTick
)}")`;

let uid = 0;

export default defineComponent({
  props: {
    uid: {
      type: Number,
      default() {
        return uid++;
      },
    },
    required: {
      type: Boolean,
      default: true,
    },
    field: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:checked": (_: boolean) => true,
  },
  setup(props, ctx) {
    const checkboxDisabled = injectDisableCheckbox();

    const onCheck = (evt: Event) => {
      const { target } = evt;

      ctx.emit(
        "update:checked",
        (target as HTMLInputElement)?.checked || false
      );
    };

    return {
      onCheck,
      confirmTickCss,
      checkboxDisabled,
      emailRegistrationCheckboxProps,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkbox__label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 14px;
}

input[type="checkbox"] {
  --width: calc(1.4em + var(--form_element_checkbox_style_padding));
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  min-width: var(--width);
  width: var(--width);
  height: var(--width);
  border-style: var(--form_element_checkbox_style_border_style);
  border-width: var(--form_element_checkbox_style_border_width);
  border-color: var(--form_element_checkbox_style_border_color);
  border-radius: var(--form_element_checkbox_style_border_radius);
  display: grid;
  place-content: center;
  transition: border-width 120ms ease-in-out;
  transform: translateY(-1px);
}

input[type="checkbox"]:hover {
  border-color: var(--form_element_checkbox_style_hover_border_color);
  border-width: var(--form_element_checkbox_style_hover_border_width);
}

input[type="checkbox"]:focus {
  outline: none;
  border-color: var(--form_element_checkbox_style_focus_border_color);
  border-width: var(--form_element_checkbox_style_focus_border_width);
}

input[type="checkbox"]::before {
  --tick-space: 0.3em;
  content: "";
  background: var(--form_element_checkbox_style_color);
  mask: v-bind(confirmTickCss) no-repeat 50%/100% 100%;
  width: calc(var(--width) - var(--tick-space));
  height: calc(var(--width) - var(--tick-space));
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
