import { useLanguagesStore } from "@/stores/languages";
import { useProjectsStore } from "@/stores/projects";
import type { PutReorderSequenceEntry } from "@cna/api/editor/v1/projects/project/languages";
import { useCommonGeneralStore } from "@cna/common";
import { api } from "./api";
import type { AxiosRequestConfig } from "axios";

class LanguagesService {
  async fetchAllLanguages(config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const languages = useLanguagesStore();

    const data = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .languages.get(config)
    ).data.result;
    languages.languages = [...data];
  }

  async fetchPrimaryLanguage(config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const languages = useLanguagesStore();
    const data = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .languages.getPrimary(config)
    ).data.result;
    languages.primaryLanguage = data.lang_tag;
  }

  async updatePrimaryLanguage(langTag: string) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .languages.postPrimaryLanguage(langTag);
  }

  async fetchAllLanguageTags() {
    const languages = useLanguagesStore();
    languages.allLanguageTags = (await api.v1.languageTags.get()).data.result;
  }

  initLanguagesSettings(config?: AxiosRequestConfig) {
    const languages = useLanguagesStore();
    const commonGeneral = useCommonGeneralStore();
    return Promise.all([
      this.fetchPrimaryLanguage(config),
      this.fetchAllLanguages(config),
      this.fetchAllLanguageTags(),
    ]).then(() => {
      if (!commonGeneral.currentLanguage) {
        commonGeneral.currentLanguage = languages.primaryLanguage;
      }
      return commonGeneral.currentLanguage;
    });
  }

  async addNewLanguage(languageTag: string) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .languages.postLanguage(languageTag);
  }

  async reorderLanguages(data: PutReorderSequenceEntry[]) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .languages.putReorder({
        new_sequence: data,
      });
  }

  async deleteLanguage(languageTag: string) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .languages.deleteLanguage(languageTag);
  }
}

const languagesService = new LanguagesService();

export default languagesService;
