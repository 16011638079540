import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const emailRegistrationInputSettingsModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Form input field",
  },
  ...modalProps,
} as const;

export type EmailRegistrationInputSettingsModalProps = ExtractPropTypes<
  typeof emailRegistrationInputSettingsModalProps
>;
