<template>
  <label class="switch" :class="size">
    <input type="checkbox" v-model="valueCopy" />
    <span class="slider" :class="{ round }"></span>
  </label>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    value: {
      type: [Boolean, Object] as PropType<boolean | null>,
    },
    round: Boolean,
    size: String as PropType<"small">,
  },
  emits: {
    "update:value": (_: boolean | null) => true,
  },
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);

    return { valueCopy };
  },
});
</script>

<style scoped lang="scss">
.switch {
  --height: 34px;
  --background-border-radius: 34px;
}
.switch.small {
  --height: 24px;
  --background-border-radius: 26px;
}
.switch {
  --switch-margin: 4px;
  --switch-diameter: calc(var(--width) / 2 - var(--switch-margin));
  --width: calc((var(--height) - var(--switch-margin)) * 2);
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbe5e9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--switch-diameter);
  width: var(--switch-diameter);
  left: var(--switch-margin);
  bottom: var(--switch-margin);
  border-radius: 2px;
  background-color: white;
  -webkit-transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out;
}

input:checked + .slider {
  background-color: $dark-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $dark-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(var(--switch-diameter));
  -ms-transform: translateX(var(--switch-diameter));
  transform: translateX(var(--switch-diameter));
}

/* Rounded sliders */
.slider.round {
  border-radius: var(--background-border-radius);
}

.slider.round:before {
  border-radius: 50%;
}
</style>
