import { computed, watch } from "vue";
import { useRoute } from "vue-router";

export const usePreviewClass = (selector: string) => {
  const route = useRoute();

  const isPreview = computed(() => route.meta.key === "preview");

  watch(isPreview, (val) => {
    const app = document.querySelector(selector);
    if (val) {
      app?.classList.add("preview");
      return;
    }

    app?.classList.remove("preview");
  });
};
