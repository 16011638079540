import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const vehiclesMonitoringModalProps = {
  ...modalProps,
};

export type VehiclesMonitoringModalProps = ExtractPropTypes<
  typeof vehiclesMonitoringModalProps
>;
