<template>
  <div ref="editorHeader" class="editor-header">
    <DropdownWithSearch
      :items="projectsDropdownOptions"
      :selected-project="currentProject"
      @select="redirectToProject"
      @change="onChangeSearch"
      @close="onChangeSearch('')"
      fixed-popup-width="400px"
    />
    <HeaderNavigation class="header-navigation" />
    <div class="more-buttons flex-row-center">
      <EditorButton
        v-if="projectversionwrite"
        :title="buttonsParams.title"
        :button-style="buttonsParams.buttonStyle"
        :on-click="buttonsParams.onClick"
      />
      <HeaderSubMenu />
    </div>
  </div>
  <ConfirmationModal
    v-if="showConfirmationModal && confirmationModalConfig"
    @close="showConfirmationModal = false"
    :config="confirmationModalConfig"
  />
</template>

<script lang="ts">
import EditorButton from "@/components/button/EditorButton.vue";
import { type ButtonProps } from "@/components/button/types";
import { type ConfirmationModalProps } from "@/modals";
import HeaderSubMenu from "./components/HeaderSubMenu.vue";

import { useNotification } from "@/hooks/use-notification";
import { usePermissions } from "@/hooks/usePermissions";
import versionsHistoryService from "@/services/versionsHistory.service";
import { useElementBounding } from "@vueuse/core";
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { useOnceAtATime } from "../../../../common/src/hooks/useOnceAtATime";
import { ButtonStyle } from "../button/types";
import DropdownWithSearch from "../dropdown/DropdownWithSearch.vue";
import { usePagesDropdownHelper } from "../dropdown/usePagesDropdownHelper";
import HeaderNavigation from "./components/HeaderNavigation.vue";

const editorHeader = ref();

export const editorHeaderBounds = useElementBounding(editorHeader);

export default defineComponent({
  name: "EditorHeader",
  components: {
    HeaderNavigation,
    DropdownWithSearch,
    ConfirmationModal: defineAsyncComponent(
      () => import("@/modals/components/common/ConfirmationModal.vue")
    ),
    EditorButton,
    HeaderSubMenu,
  },
  setup() {
    const { projectversionwrite } = usePermissions();

    const showConfirmationModal = ref(false);
    const confirmationModalConfig = ref<ConfirmationModalProps["config"]>();
    const notification = useNotification();

    const buttonsParams: ButtonProps = {
      title: "Save version",
      buttonStyle: ButtonStyle.GREEN,
      onClick: () => {
        confirmationModalConfig.value = {
          confirmAction: useOnceAtATime(async (value: string) => {
            await versionsHistoryService.createVersion(value).then(() => {
              notification.success();
            });
            await versionsHistoryService.fetchVersions();
          }),
          modalTitle: "Save version",
          modalText:
            "You’re about to create an updated version of the Portal. This new version will be saved to the Version history. Please add a description of the changes made to this version:",
          textArea: true,
          buttonsConfigs: {
            confirmButton: {
              buttonTitle: "Save",
            },
            cancelButton: {
              buttonTitle: "Cancel",
            },
          },
        };

        showConfirmationModal.value = true;
      },
    };

    const {
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
    } = usePagesDropdownHelper();

    return {
      editorHeader,
      showConfirmationModal,
      confirmationModalConfig,
      buttonsParams,
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      projectversionwrite,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-header {
  padding: 12px 20px;
  background-color: $dark-blue;
  color: $white;
  font-family: $primary-font;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.header-navigation {
  justify-content: center;
}
.more-buttons {
  justify-content: end;
}

.editor-header:deep(.dropdown__select) {
  height: 32px;
  padding: 0 12px;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: bold;
}

.editor-header:deep(.button) {
  border-radius: 4px;
  line-height: 16px;
  font-weight: 800;
  font-size: 14px;
}

.editor-header:deep(.custom-dropdown__selected-item) {
  background-color: transparent;
  color: $white;
  border-color: #6888ab;
  width: fit-content;
}
</style>
