<template>
  <template v-if="!actionBoxEnabled">
    <slot></slot>
  </template>
  <template v-else>
    <Teleport to="body">
      <ActionBox
        ref="actionBox"
        :id="actionBoxId"
        :passthrough="editingCopy"
        @click="editingCopy = true"
      />
    </Teleport>

    <template v-if="!editingCopy">
      <div ref="readonlyContainer">
        <slot></slot>
      </div>
    </template>
    <div v-else class="simple-inline-editor-container" ref="editorContainer">
      <input
        class="simple-inline-editor-input"
        v-model="valueCopy"
        @keyup.esc="cancel"
      />
      <div class="simple-toolbar-container">
        <SaveButtonGroup @save="save" @cancel="cancel" />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { mdFieldAndTemplateProps } from "@cna/common/components/markdown/props";
import { useElementBounding, useResizeObserver, useVModel } from "@vueuse/core";
import { defineComponent, nextTick, onMounted, ref, toRefs, watch } from "vue";
import { ButtonStyle } from "../../button/types";
import { injectEditingDisabled } from "../providables";
import ActionBox from "./ActionBox.vue";
import { editId } from "./global";
import { onElementInvisible } from "./onElementInvisible";
import { editorProps } from "./props";
import SaveButtonGroup from "./SaveButtonGroup.vue";
import { useActionBox, useContainerPosition } from "./useActionBox";
import { useStayFixedPlease } from "./useResizing";

export default defineComponent({
  components: {
    SaveButtonGroup,
    ActionBox,
  },
  props: {
    ...editorProps,
    ...mdFieldAndTemplateProps,
  },
  emits: {
    save: (_data: string) => true,
    "update:editing": (_editing: boolean) => true,
    "update:value": (_val: string) => true,
  },
  setup(props, ctx) {
    const { idx, text, padding } = toRefs(props);

    const editingCopy = useVModel(props, "editing", ctx.emit);

    const valueCopy = ref(text.value);

    const save = async () => {
      if (valueCopy.value === text.value) {
        editingCopy.value = false;
        return;
      }

      nextTick(() => {
        ctx.emit("save", valueCopy.value);
      });
    };

    const reset = () => {
      valueCopy.value = text.value;
    };
    const cancel = () => {
      reset();
      editingCopy.value = false;
    };

    const editorContainer = ref<HTMLDivElement | null>(null);
    const readonlyContainer = ref<HTMLDivElement | null>(null);
    const readonlyContainerBounds = useElementBounding(readonlyContainer);
    const { editingDisabled } = injectEditingDisabled();

    const { getContainerPosition } = useContainerPosition(
      padding,
      () => readonlyContainer.value,
      readonlyContainerBounds
    );

    const { actionBox, actionBoxEnabled, positionActionBox } = useActionBox(
      idx,
      editingDisabled,
      getContainerPosition
    );

    useStayFixedPlease(positionActionBox);
    onMounted(() => {
      positionActionBox();
    });

    watch(
      [valueCopy, actionBoxEnabled],
      () => {
        nextTick(positionActionBox);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    useResizeObserver(readonlyContainer, positionActionBox);
    watch(Object.values(readonlyContainerBounds), positionActionBox);

    onElementInvisible(editorContainer, cancel);

    watch(editingCopy, (val) => {
      if (val) {
        editId.value = props.idx;
        return;
      }
      editId.value = -1;
      positionActionBox();
    });

    watch(
      valueCopy,
      (val) => {
        ctx.emit("update:value", val);
      },
      { immediate: true }
    );

    return {
      editId,
      readonlyContainer,
      actionBox,
      editorContainer,
      valueCopy,
      editingCopy,
      save,
      cancel,
      reset,
      ButtonStyle,
      actionBoxEnabled,
    };
  },
});
</script>

<style scoped>
.simple-inline-editor-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  border: 2px solid var(--edit-text-hover-color);
}

.simple-toolbar-container {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.simple-inline-editor-input {
  width: 220px;
  max-width: 100%;
  max-width: 400px;
  outline: none;
}
</style>
