<template>
  <OneColumnLayout id="secondary-page-content">
    <div
      class="page__main-block"
      v-theme-action-box.styles="
        field?.includes('contact_us')
          ? mainProps('contact_us')
          : secondaryPageMainBlockProps
      "
    >
      <div class="page__text-block">
        <div class="page__text">
          <div :class="mainBlockClass" :tabindex="tabindex">
            <Markdown
              :data="markdownData"
              :field="field"
              :template="template"
            />
            <div v-if="actionBlock" class="page__main-block-actions">
              <CommonButton :config="buttonConfig" field="back_button_label" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import { footerHeight } from "@cna/common/templates/footer/globals";
import { headerHeight } from "@cna/common/templates/header/globals";
import { computed, defineComponent } from "vue";
import { useCommonGeneralStore } from "../../../stores";
import {
  bodyProps,
  mainProps,
  secondaryPageMainBlockProps,
} from "../../../utils/themeActionBoxElementProps";
import CommonButton from "../../button/CommonButton.vue";
import { OneColumnLayout } from "../../columns";
import { mdFieldAndTemplateProps } from "../../markdown/props";

export default defineComponent({
  name: "SecondaryPageBody",
  components: {
    CommonButton,
    OneColumnLayout,
  },
  props: {
    markdownData: {
      type: String,
      required: true,
    },
    ...mdFieldAndTemplateProps,
    isScrolled: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const commonGeneral = useCommonGeneralStore();

    const mainBlockClass = computed(() => {
      return props.isScrolled
        ? "page__markdown page__markdown-with-scroll"
        : "page__markdown";
    });

    const tabindex = computed(() => (props.isScrolled ? "0" : undefined));

    const buttonConfig = computed(() => ({
      title: commonGeneral.bodyTexts.back_button_label,
      onClick: () => commonGeneral.buttonsActions.onBackButtonClick?.(),
    }));

    const footerHeightAsPx = computed(() => `${footerHeight.value}px`);
    const headerHeightAsPx = computed(() => `${headerHeight.value}px`);

    return {
      mainBlockClass,
      buttonConfig,
      secondaryPageMainBlockProps,
      bodyProps,
      mainProps,
      footerHeightAsPx,
      headerHeightAsPx,
      tabindex,
    };
  },
});
</script>

<style scoped>
.page__markdown-with-scroll {
  max-height: calc(
    100vh - v-bind(footerHeightAsPx) - v-bind(headerHeightAsPx) - 48px
  );
}
</style>
