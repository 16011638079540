import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
import insertButtonForm from "./forms/insertButtonForm.html?raw";
import { setPopupPosition } from "./utils";

export const insertButtonPlugin = () => {
  FroalaEditor.POPUP_TEMPLATES["insertButtonPlugin.popup"] = "[_CUSTOM_LAYER_]";

  FroalaEditor.PLUGINS.insertButtonPlugin = function (editor: any) {
    let $popup: HTMLElement[];
    let title: HTMLInputElement;
    let link: HTMLInputElement;
    let width: HTMLInputElement;

    function initPopup() {
      const template = {
        custom_layer: insertButtonForm,
      };

      $popup = editor.popups.create("insertButtonPlugin.popup", template);
      $popup[0]
        .querySelector('button[data-cmd="confirmInsertButton"]')
        ?.addEventListener("click", function () {
          editor.selection.restore();
          const element = editor.selection.element();

          if (!element.text) {
            editor.html.insert(
              `<a class="page__btn" href="${
                link.value
              }" target="_blank" style="width: ${
                width.value || "100%"
              }; display: inline-block">${title.value}</a>`
            );
          } else {
            element.href = link.value;
            element.text = title.value;
            element.style.width = width.value;
          }
          hidePopup();
        });
      return $popup;
    }

    const showPopup = () => {
      if (!$popup) $popup = initPopup();
      const selection = editor.selection.element();
      editor.popups.setContainer("insertButtonPlugin.popup", editor.$tb);
      setPopupPosition("insertButton", editor);
      title = $popup[0].querySelector("#button-title")!;
      link = $popup[0].querySelector("#button-link")!;
      width = $popup[0].querySelector("#button-width")!;

      title.value = selection.text || "";
      link.value = selection.href || "";
      width.value = selection.style.width || "";

      // Need to focus inputs for correct rendering of the initial values
      link.focus();
      width.focus();
      title.focus();
    };

    const hidePopup = () => {
      editor.popups.hide("insertButtonPlugin.popup");
      editor.selection.clear();
    };

    return {
      showPopup,
      hidePopup,
    };
  };
};
