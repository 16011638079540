<template>
  <InlineEditor
    :padding="padding"
    :text="text"
    :action-box-id="`edit-text-${field}`"
    :data="data"
    :tag="tag"
    :m-if="mIf"
    :vars="vars"
    :enter="enter"
    :is-now-connected-page="field.includes('now_connected')"
    v-model:editing="editing"
    @update:value="editValue = $event"
    @save="save"
  >
    <slot />
  </InlineEditor>
</template>

<script lang="ts">
import { mdProps } from "@cna/common/components/markdown/props";
import { defineComponent, toRefs } from "vue";
import InlineEditor from "./components/InlineEditor.vue";
import { useField } from "./hooks/useField";

export default defineComponent({
  components: {
    InlineEditor,
  },
  props: {
    ...mdProps,
    padding: String,
  },

  setup(props) {
    const { template, field } = toRefs(props);
    const { editValue, editing, save, text } = useField(template, field);

    return {
      text,
      editing,
      editValue,
      save,
    };
  },
});
</script>
