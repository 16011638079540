<template>
  <div :key="imageData.file" class="image-uploader">
    <input
      ref="imageInput"
      type="file"
      class="image-uploader__input"
      accept="image/*"
      @change="onImageChange"
    />

    <div v-if="isImageUploaded" class="image-uploader__image-wrapper">
      <section class="image-uploader__section">
        <vue-cropper
          ref="cropper"
          class="image-uploader__cropper image-uploader__preview-area"
          :src="imageData.file"
          :auto-crop-area="1"
          @cropend="onImageCrop"
          @ready="onCropperReady"
        />
      </section>

      <LoaderWithText v-if="!croppedImage" class="image-uploader__loader">
        Data is loading
      </LoaderWithText>
    </div>

    <div class="image-uploader__actions">
      <EditorButton
        v-if="removeButton && isImageUploaded"
        title="Remove"
        :on-click="removeImage"
        :button-style="ButtonStyle.LIGHT"
      />
      <EditorButton :title="buttonTitle" :on-click="changeImage" />
    </div>
    <div class="image-uploader__info">
      Image not to exceed {{ imageSizeDescription }}.
      <a
        target="_blank"
        style="text-decoration: underline"
        href="https://image.online-convert.com/convert-to-webp"
        >WEBP</a
      >
      file type recommended, but JPEG, PNG and GIF files accepted.
    </div>
    <div class="modal-content__slider-wrapper">
      <h2 class="modal-content__title">Image size</h2>
      <RangeSlider
        :key="imageData.file"
        :slider-params="rangeSliderParams"
        :init-value="100"
        @update:option="onImageZoom"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonStyle } from "@/components/button/types";
import RangeSlider from "@/components/rangeSlider/RangeSlider.vue";
import {
  RangeSliderUnits,
  type RangeSliderProps,
} from "@/components/rangeSlider/types";
import { useNotification } from "@/hooks/use-notification";
import { LoaderWithText } from "@cna/common";
import "cropperjs/dist/cropper.css";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import VueCropper from "vue-cropperjs";
import EditorButton from "../button/EditorButton.vue";

export default defineComponent({
  name: "ImageUploader",
  components: {
    EditorButton,
    VueCropper,
    RangeSlider,
    LoaderWithText,
  },
  props: {
    imageUrl: {
      type: String,
    },
    backgroundPosition: {
      type: String,
    },
    removeButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "transparent",
    },
    overlayColor: {
      type: String,
      required: false,
      default: "transparent",
    },
    overlayOpacity: {
      type: Number,
      required: false,
    },
    maxImageSize: {
      type: Number,
      required: false,
      default: 1024 * 300,
    },
    imageSizeDescription: {
      type: String,
      false: true,
      default: "300kB",
    },
    minSizeRange: {
      type: Number,
      required: false,
      default: 100,
    },
    maxSizeRange: {
      type: Number,
      required: false,
      default: 200,
    },
  },
  emits: ["update:option", "remove"],
  setup(props, { emit }) {
    const notification = useNotification();
    const cropper = ref();
    const DEFAULT_PERCENT_IMAGE_SIZE = 100;
    const imageData = reactive({ file: props.imageUrl });
    const imageInput: any = ref(null);
    const currentImageSize = ref(DEFAULT_PERCENT_IMAGE_SIZE);
    const croppedImage = ref();
    const imageType = ref();

    watch(
      () => props.imageUrl,
      (val) => {
        imageData.file = val;
        currentImageSize.value = DEFAULT_PERCENT_IMAGE_SIZE;
      }
    );

    const defineImageType = () => {
      if (!props.imageUrl) {
        imageType.value = "jpeg";
        return;
      }
      fetch(props.imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          imageType.value = blob.type.split("/")?.[1];
        })
        .catch((err) => {
          console.error(
            `Failed to identify image type for ${props.imageUrl}, using default`,
            err
          );
          imageType.value = "jpeg";
        });
    };
    defineImageType();

    const changeImage = () => {
      imageInput.value.click();
    };

    const removeImage = () => {
      imageData.file = "";
      emit("update:option", null);
      emit("remove");
    };

    const onCropperReady = () => {
      if (!cropper.value) {
        return;
      }
      const canvas = cropper.value.getCroppedCanvas();
      croppedImage.value = canvas.toDataURL();
      return canvas;
    };
    const onImageCrop = () => {
      if (!cropper.value) {
        return;
      }
      const canvas = onCropperReady();

      canvas.toBlob(
        (blob: Blob) => emit("update:option", blob),
        `image/${imageType.value}`
      );
    };

    const onImageZoom = (value: number) => {
      const delta = value - currentImageSize.value;
      currentImageSize.value = value;
      cropper.value.relativeZoom(delta / 100);
      onImageCrop();
    };

    const onImageChange = (e: any) => {
      const file = e.target.files[0];

      if (file.size < props.maxImageSize) {
        croppedImage.value = null; // Delete croppedImage that shows loader when loading image
        imageType.value = file.type.split("/")[1];
        imageData.file = URL.createObjectURL(file);
        emit("update:option", file);
      } else {
        e.target.value = null;
        notification.error({
          title: `The image is larger than ${props.imageSizeDescription}`,
        });
      }
    };

    const buttonTitle = computed(() => {
      return isImageUploaded.value ? "Change" : "Upload image";
    });

    const previewBlockClass = computed(() => {
      /* Because jpeg image doesn't support transparency so in case if image smaller than preview area we should handle
      preview block background color and set up black color */
      return imageType.value === "jpeg"
        ? "image-uploader__preview-area-wrapper--black"
        : "";
    });

    const isImageUploaded = computed(() => !!imageData.file);

    const rangeSliderParams: RangeSliderProps = {
      minValue: props.minSizeRange,
      maxValue: props.maxSizeRange,
      title: "Zoom",
      units: RangeSliderUnits.PERCENTAGE,
    };

    return {
      imageData,
      buttonTitle,
      isImageUploaded,
      imageInput,
      changeImage,
      onImageChange,
      removeImage,
      onCropperReady,
      onImageCrop,
      onImageZoom,
      cropper,
      rangeSliderParams,
      croppedImage,
      previewBlockClass,
      ButtonStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.image-uploader {
  width: 100%;
}

.image-uploader__input {
  display: none;
  background-size: 150px 150px;
}

.image-uploader__actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button:first-child {
    margin-right: 10px;
  }
}

.image-uploader__info {
  margin-top: 37px;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
}

.image-uploader__image {
  width: 100%;
  max-height: 250px;
  object-position: center;
}

.image-uploader__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image-uploader__cropper {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  object-fit: cover;
}

.image-uploader__image-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
}

.image-uploader__preview-area {
  @include image-overlay(v-bind(overlayColor), v-bind(overlayOpacity));
}

.image-uploader__preview-area-wrapper {
  display: flex;
  justify-content: center;
  background-color: $grey2;
  border: 1px solid $grey-dark;

  &--black {
    background-color: $black;
  }
}

.image-uploader__heading {
  display: block;
  margin-bottom: 20px;
  color: $dark-blue;
  font-weight: bold;
}

.image-uploader__loader {
  min-height: 200px;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}
</style>
