<template>
  <n-config-provider abstract :theme-overrides="THEME_OVERRIDES">
    <banner v-if="bannerEnabled" />
    <router-view />
    <global-modal />
  </n-config-provider>
</template>

<script lang="ts">
import { THEME_OVERRIDES } from "@/constants";
import {
  provideDisableCheckbox,
  provideDisableFloatingLabel,
  provideShowLogoPlaceholder,
} from "@cna/common";
import { provideAppName } from "@cna/common/providers";
import { NConfigProvider } from "naive-ui";
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { isEditing } from "./components/editor/components/global";
import { usePreviewClass } from "./hooks/usePreviewClass";
import { GlobalModal } from "./modals";
import { memoFlags } from "./services/api";

export default defineComponent({
  name: "App",
  components: {
    NConfigProvider,
    GlobalModal,
    Banner: defineAsyncComponent(
      () => import("./components/banner/Banner.vue")
    ),
  },
  setup() {
    provideAppName("editor");
    provideDisableCheckbox(isEditing);
    provideDisableFloatingLabel(isEditing);
    provideShowLogoPlaceholder(ref(true));

    const bannerEnabled = ref(false);

    memoFlags().then((res) => {
      bannerEnabled.value = !!res.data.result.banner;
    });

    usePreviewClass("#app");

    return {
      bannerEnabled,
      THEME_OVERRIDES,
    };
  },
});
</script>

<style lang="scss">
#app {
  height: 100%;
}

@media (max-width: 1000px) {
  #app:not(.preview) {
    overflow-x: auto;
    min-width: 1000px;
  }
}

html,
body {
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
