<template>
  <footer class="footer">
    <component :is="componentToRender" />
  </footer>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import { useCommonGeneralStore } from "../stores/general";

export default defineComponent({
  name: "FooterLayout",
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const { templatesKeys } = commonGeneral;
    const { templateName } = useTemplateNameForLayout("footer", templatesKeys);
    const componentToRender = defineAsyncComponent(
      () => import("../templates/footer/" + `${templateName}.vue`)
    );

    return {
      componentToRender,
    };
  },
});
</script>
