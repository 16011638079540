<template>
  <div class="vehicle-events-filtering__item">
    <div class="vehicle-events-filtering__item-heading">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "fitler-field",
  props: {
    label: String,
  },
});
</script>

<style lang="scss" scoped>
.vehicle-events-filtering__item-heading {
  margin-bottom: 6px;
  font-family: $primary-font;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue;
}

.vehicle-events-filtering__item:deep(.simple-dropdown__selected-item) {
  padding: 8px 11.7px 8px 11px;
  max-height: 32px;
  border-radius: 4px;
  font-family: $primary-font;
  border-color: $grey3;
  color: $dark-blue;
}

.vehicle-events-filtering__item:deep(.simple-dropdown__placeholder) {
  color: $grey-dark;
  opacity: 1;
}

.vehicle-events-filtering__item:deep(.simple-dropdown__menu-list) {
  font-family: $primary-font;
  color: $dark-blue;
  border-color: $grey3;
  font-weight: normal;
}

.vehicle-events-filtering__item:deep(.simple-dropdown__icon-arrow) {
  font-size: 10px;
  color: $dark-blue;
}

.vehicle-events-filtering__item:deep(.simple-dropdown__menu-item) {
  &:hover {
    background-color: $grey2;
  }
}
</style>
