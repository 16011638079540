<template>
  <!-- eslint-disable vue/no-v-text-v-html-on-component -->
  <component
    v-if="mIf"
    v-bind="$attrs"
    ref="content"
    data-markdown=""
    :is="tag"
    v-html="dataWithVars"
  />
  <slot v-else v-bind="$attrs" />
</template>

<script lang="ts">
import Mustache from "mustache";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  toRefs,
} from "vue";
import { findTextElement } from "../../utils";
import { mdProps } from "./props";
import { injectGetLiveUserCount } from "./providables";

export default defineComponent({
  name: "Markdown",
  props: mdProps,
  setup(props) {
    const handleLiveUserCount = () => {
      // inject the live user count function
      const { getLiveUserCount } = injectGetLiveUserCount();
      // if the function does not exist, then we do nothing
      // so, for the editor, we do not have to provide a function,
      // and for the portal we can provide the ombord function
      if (!getLiveUserCount) {
        return;
      }
      const element = document.getElementById("live-user-count")!;

      // missed check here, we should not make any requests if the live-user-count feature is not enabled
      if (!element) {
        return;
      }
      element.innerHTML = "";

      getLiveUserCount()
        .then((count) => {
          element.innerHTML = count;
        })
        .catch(() => {
          const parentParagraph = findTextElement(element, ["div"]);
          parentParagraph.style.display = "none";
        });
    };

    onMounted(handleLiveUserCount);
    onUpdated(handleLiveUserCount);

    const { data, vars } = toRefs(props);
    const dataWithVars = computed(() =>
      data.value
        ? Mustache.render(data.value, {
            ...vars.value,
            RESOURCES_URL: __RESOURCES_URL__,
          })
        : ""
    );

    const content = ref<HTMLElement | null>(null);

    return {
      dataWithVars,
      content,
    };
  },
});
</script>
