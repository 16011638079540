import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { useCommonEmailRegistrationStore } from "@cna/common";
import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
import insertLinkForm from "./forms/insertLinkForm.html?raw";
import { getTargetValue, setPopupPosition, setTargetValue } from "./utils";

export const insertLinkPlugin = (isNowConnectedPage: boolean) => {
  FroalaEditor.POPUP_TEMPLATES["insertLinkPlugin.popup"] = "[_CUSTOM_LAYER_]";

  FroalaEditor.PLUGINS.insertLinkPlugin = function (editor: any) {
    let $popup: HTMLElement[];
    let text: HTMLInputElement;
    let url: HTMLInputElement;
    let linkTarget: HTMLInputElement;
    let warningMessage: HTMLInputElement | null;
    const versionsHistory = useVersionsHistoryStore();
    const commonEmailRegistration = useCommonEmailRegistrationStore();

    let selectedElement = editor.selection.element();

    function initPopup() {
      const template = {
        custom_layer: insertLinkForm,
      };

      $popup = editor.popups.create("insertLinkPlugin.popup", template);
      const onClickPageElement = (event: Event) => {
        const value = (event?.target as HTMLInputElement).value;
        url.value = `${
          versionsHistory.isLocalPortalEnv ? "/cna/" : "/"
        }${value}`;
        url?.focus();
      };

      const insertLink = () => {
        editor.selection.restore();
        const link = editor.link.get();
        if (!selectedElement.text && !link) {
          editor.html.insert(
            `<a href="${url?.value}" target="${getTargetValue(
              linkTarget?.checked
            )}" >${text?.value}</a>`
          );
        } else {
          editor.link.insert(url?.value, text?.value, {
            target: getTargetValue(linkTarget?.checked),
          });
        }
        hidePopup();
      };

      const handleInfoMessage = () => {
        const isExternalURL = url?.value.includes("://");
        if (isExternalURL && !isNowConnectedPage && warningMessage) {
          warningMessage.style.display = "block";
        }
      };

      const hideInfoMessage = () => {
        if (warningMessage?.style.display === "block") {
          warningMessage.style.display = "none";
        }
      };

      $popup[0]
        .querySelector('button[data-cmd="confirmInsertLink"]')
        ?.addEventListener("click", insertLink);

      $popup[0]
        .querySelector('button[data-cmd="cancelInsertLink"]')
        ?.addEventListener("click", hidePopup);

      $popup[0]
        .querySelector("#link-url")
        ?.addEventListener("focusout", handleInfoMessage);

      $popup[0]
        .querySelector("#link-url")
        ?.addEventListener("focus", hideInfoMessage);

      const pagesListElements: Element[] = Array.from(
        document.getElementsByClassName("insert-link-form__pages-list-input")
      );
      pagesListElements.forEach((element: Element) => {
        element.addEventListener("click", onClickPageElement);
      });

      return $popup;
    }

    const setFormElements = () => {
      text = $popup[0].querySelector("#link-text")!;
      url = $popup[0].querySelector("#link-url")!;
      linkTarget = $popup[0].querySelector("#link-target")!;
      warningMessage = $popup[0].querySelector("#warning-message");
    };

    const handleEmailRegistrationPageLink = () => {
      if (!commonEmailRegistration.isEmailregPortal) {
        const emailRegistrationPageLink: HTMLElement = $popup[0].querySelector(
          "#email-registration-page-link"
        )!;
        emailRegistrationPageLink.style.display = "none";
      }
    };

    function isInternalLink(href: string): boolean {
      return new URL(href).origin === location.origin;
    }

    const getLinkHref = (linkHref: string) => {
      if (!linkHref) return "";

      // Need to remove origin for internal links
      const internalLink = linkHref.replace(window.location.origin, "");

      return isInternalLink(linkHref) ? internalLink : linkHref;
    };

    const showPopup = () => {
      if (!$popup) $popup = initPopup();
      editor.popups.setContainer("insertLinkPlugin.popup", editor.$tb);
      const popup = $popup[0].querySelector(
        ".insert-link-form"
      )! as HTMLElement;
      // Set popup position and input values
      setPopupPosition("insertLink", editor, popup);

      editor.selection.restore();
      selectedElement = editor.selection.element();
      setFormElements();

      const link = editor.link.get();
      text.value = editor.selection.text() || link?.text || "";
      url.value = getLinkHref(link?.href);
      linkTarget.checked = setTargetValue(link?.target);

      handleEmailRegistrationPageLink();
      editor.selection.save();

      text.focus();
      url.focus();
    };

    const hidePopup = () => {
      editor.popups.hide("insertLinkPlugin.popup");
      editor.selection.clear();
    };

    return {
      showPopup,
      hidePopup,
    };
  };
};
