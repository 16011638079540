import type { TemplatesKeys } from "@cna/api/editor";
import { defineStore } from "pinia";
import { computed, ref, watch, type Ref } from "vue";
import { INITIAL_TEMPLATES_KEYS } from "../templates/INITIAL_VALUES";
import { INITIAL_FEATURES, INITIAL_TEXTS } from "./INITIAL_VALUES";
import type {
  AppName,
  ButtonsActions,
  Features,
  TextFieldValues,
  TypesOfButtons,
  TypesOfLinksBlocks,
} from "./types";

const INITIAL_TYPES_OF_LINKS_BLOCKS: TypesOfLinksBlocks = {
  headerLogoLink: "",
  footerLink: "",
  bodyTermsLink: "",
};

const INITIAL_TYPES_OF_BUTTONS: TypesOfButtons = {
  connectButtonType: "submit",
};

const INITIAL_BUTTONS_ACTIONS: ButtonsActions = {
  onConnectButtonClick: () => {},
  onLogoutButtonClick: () => {},
  onRegisterButtonClick: () => {},
};

export const useCommonGeneralStore = defineStore("commonGeneral", () => {
  const appName: Ref<AppName | null> = ref(null);
  const templates: Ref<TemplatesKeys> = ref(INITIAL_TEMPLATES_KEYS);
  const typesOfLinksBlocks: Ref<TypesOfLinksBlocks> = ref(
    INITIAL_TYPES_OF_LINKS_BLOCKS
  );
  const typesOfButtons: Ref<TypesOfButtons> = ref(INITIAL_TYPES_OF_BUTTONS);
  const currentLanguage = ref("");
  const features: Ref<Features> = ref(INITIAL_FEATURES);
  const buttonsActions: Ref<ButtonsActions> = ref(INITIAL_BUTTONS_ACTIONS);
  const isVenueHealthy: Ref<null | boolean> = ref(null);
  const liveUserCount = ref(0);

  const texts: Ref<TextFieldValues> = ref(INITIAL_TEXTS);
  const bodyTexts = computed(() => texts.value[currentLanguage.value].body);
  const footerTexts = computed(() => texts.value[currentLanguage.value].footer);

  const headerTexts = computed(() => texts.value[currentLanguage.value].header);

  const templatesKeys = computed(() => templates.value);
  const isWithBelowMainBlockContent = computed(
    () => features.value.with_below_main_block_content
  );
  const isPortal = computed(() => appName.value === "portal");
  const isAutoRedirect = computed(() => features.value.auto_redirect);
  const isWithVenueRedirectButton = computed(
    () =>
      !!bodyTexts.value.venue_redirect_button_link &&
      !features.value.hide_venue_button
  );
  const venueRedirectLink = computed(
    () =>
      texts.value[currentLanguage.value].body.venue_redirect_button_link || ""
  );

  const hasTnCCheckbox = computed(() => !features.value.disable_tnc_checkbox);

  const setLangAttributeToDocument = () => {
    document.documentElement.lang = currentLanguage.value;
  };

  watch(currentLanguage, setLangAttributeToDocument, {
    deep: true,
    immediate: true,
  });

  return {
    appName,
    templates,
    typesOfLinksBlocks,
    typesOfButtons,
    currentLanguage,
    features,
    buttonsActions,
    isVenueHealthy,
    templatesKeys,
    isWithBelowMainBlockContent,
    isPortal,
    isAutoRedirect,
    isWithVenueRedirectButton,
    venueRedirectLink,
    hasTnCCheckbox,
    liveUserCount,
    bodyTexts,
    headerTexts,
    footerTexts,
    texts,
  };
});
