import { useProjectsStore } from "@/stores/projects";
import type { PostImageAttributesInput } from "@cna/api/editor";
import { useCommonImagesStore } from "@cna/common";
import type { AxiosRequestConfig } from "axios";
import { api } from "./api";

class ImagesService {
  async fetchImagesData(config?: AxiosRequestConfig) {
    const projects = useProjectsStore();
    const commonImages = useCommonImagesStore();
    commonImages.imagesData = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .images.get(config)
    ).data.result;
  }

  async updateImage(templateName: string, fieldName: string, image: File) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .images.post(templateName, fieldName, image);
    await this.fetchImagesData();
  }

  async updateImageAttributes(
    templateName: string,
    fieldName: string,
    imageProps: PostImageAttributesInput["props"]
  ) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .images.postAttributes(templateName, fieldName, { props: imageProps });
    await this.fetchImagesData();
  }

  updateImageWithAttributes(
    templateName: string,
    fieldName: string,
    image: File,
    imageProps: PostImageAttributesInput["props"]
  ) {
    return Promise.all([
      this.updateImage(templateName, fieldName, image),
      this.updateImageAttributes(templateName, fieldName, imageProps),
    ]).then(() => this.fetchImagesData());
  }

  async deleteImage(templateName: string, fieldName: string) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .images.postEmpty(templateName, fieldName);
  }

  updateBackgroundColorWithoutImage(
    templateName: string,
    fieldName: string,
    backgroundColor: string
  ) {
    return Promise.all([
      this.deleteImage(templateName, fieldName),
      this.updateImageAttributes(templateName, fieldName, {
        background_color: backgroundColor,
      }),
    ]).then(() => this.fetchImagesData());
  }
}

const imagesService = new ImagesService();

export default imagesService;
