<template>
  <div class="range-slider">
    <div class="range-slider__center">
      <Slider
        v-model="sliderValue"
        :min="sliderParams.minValue"
        :max="sliderParams.maxValue"
        @change="onSliderChange"
      />
    </div>
    <label class="range-slider__units">
      <input
        class="range-slider__units-value"
        maxlength="3"
        :value="sliderValue"
        @change="onInputChange(($event.target as HTMLInputElement).value)"
      />
      <span class="range-slider__units-type">{{ sliderParams.units }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { type RangeSliderProps } from "@/components/rangeSlider/types";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import { defineComponent, ref, type PropType } from "vue";

export default defineComponent({
  name: "RangeSlider",
  components: {
    Slider,
  },
  props: {
    sliderParams: {
      type: Object as PropType<RangeSliderProps>,
      required: true,
    },
    initValue: {
      type: Number,
      required: false,
    },
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const sliderValue = ref(props.initValue ?? 0);
    const onSliderChange = (value: number) => emit("update:option", value);

    const onInputChange = (value: string) => {
      sliderValue.value = Number(value);
      emit("update:option", value);
    };

    return {
      sliderValue,
      onSliderChange,
      onInputChange,
    };
  },
});
</script>

<style lang="scss">
.range-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0 0 0;
  font-weight: 400;
}

.range-slider__title {
  font-size: 14px;
  color: $dark-blue;
  margin-right: 10px;
  user-select: none;
}

.range-slider__center {
  width: 100%;
  max-width: 330px;
}

.range-slider__units {
  position: relative;
  font-size: 12px;
  line-height: 1.2;
  margin-left: 10px;
}

.range-slider__units-value {
  padding: 6px 21px 6px 6px;
  width: 49px;
  max-width: 49px;
  color: $dark-blue;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: right;

  &:hover {
    border-color: $grey3;
  }

  &:focus {
    outline: none;
    border-color: $dark-blue;
  }
}

.range-slider__units-type {
  position: absolute;
  margin-left: 4px;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.slider-horizontal {
  height: 2px;
}

.slider-connect {
  background-color: #1d4671;
}

.slider-handle {
  background-color: $white;
  border: 2px solid #1d4671;
  box-shadow: none;
}

.slider-horizontal .slider-handle {
  height: 12px;
  width: 12px;
  top: -5px;
  right: -6px;
}

.slider-touch-area {
  transition: transform 0.3s ease;
}

.slider-handle:focus {
  background-color: #1d4671;
}

.slider-tooltip {
  display: none;
}

.slider-base,
.slider-connects {
  cursor: pointer;
  background-color: #1d4671;
}
</style>
