<template>
  <EmbeddedInputWrap>
    <FloatingLabel
      :active="Boolean(valueCopy) || Boolean(formDropdownRef.placeholderText)"
      :labelFor="dataFieldId"
    >
      <FormDropdown
        ref="formDropdownRef"
        :id="dataFieldId"
        :data-field-id="dataFieldId"
        v-model:value="valueCopy"
        :config="config"
      />
      <template #label>
        <EmailField :data="label" :field="config.key" />
      </template>
    </FloatingLabel>
  </EmbeddedInputWrap>
</template>

<script lang="ts">
import type { EmailRegistrationField } from "@cna/api/editor";
import { useVModel } from "@vueuse/core";
import { defineComponent, ref, type PropType } from "vue";
import FloatingLabel from "../../floatingLabel/FloatingLabel.vue";
import EmbeddedInputWrap from "./EmbeddedInputWrap.vue";
import FormDropdown from "./FormDropdown.vue";

export default defineComponent({
  name: "EmbeddedFormDropdown",
  components: { FloatingLabel, EmbeddedInputWrap, FormDropdown },
  props: {
    value: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    dataFieldId: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);
    const formDropdownRef = ref({ placeholderText: "" });

    return {
      valueCopy,
      formDropdownRef,
    };
  },
});
</script>
