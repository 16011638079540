<template>
  <RightPanelModal
    :title="title"
    :on-apply-changes="saveInputSettings"
    scrolled
  >
    <RightPanelSection title="Input field type">
      <EditorDropdown
        :items="labelTypesOptions"
        :current-option-key="styles.label_type"
        :on-change="onLabelTypeChange"
      />
    </RightPanelSection>

    <RightPanelSection title="Text colour" with-subsections>
      <RightPanelSubsection title="Field label" color-picker>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.label_color ||
            defaultStyles.form_element_input_style_label_color
          "
          @update:color="(v) => updateStyleOption('label_color', v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Input field text" color-picker>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.color ||
            defaultStyles.form_element_input_style_color
          "
          @update:color="(v) => updateStyleOption('color', v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Error message" color-picker>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.error_msg_color ||
            defaultStyles.form_element_input_style_error_msg_color
          "
          @update:color="(v) => updateStyleOption('error_msg_color', v)"
        />
      </RightPanelSubsection>
    </RightPanelSection>

    <RightPanelSection title="Asterix colour" color-picker>
      <ColorPickerElement
        :color="
          formElementStyleCopy?.required_field_color ||
          defaultStyles.form_element_input_style_required_field_color
        "
        @update:color="(v) => updateStyleOption('required_field_color', v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Border" with-subsections>
      <RightPanelSubsection title="Default state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.border_color ||
            defaultStyles.form_element_input_style_border_color
          "
          @update:color="(v) => updateStyleOption('border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="borderWidth"
          @update:option="(v) => (borderWidth = v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Hover state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.hover_border_color ||
            defaultStyles.form_element_input_style_hover_border_color
          "
          @update:color="(v) => updateStyleOption('hover_border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="hoverBorderWidth"
          @update:option="(v) => (hoverBorderWidth = v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Focus state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.focus_border_color ||
            defaultStyles.form_element_input_style_focus_border_color
          "
          @update:color="(v) => updateStyleOption('focus_border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="focusBorderWidth"
          @update:option="(v) => (focusBorderWidth = v)"
        />
      </RightPanelSubsection>
    </RightPanelSection>

    <RightPanelSection title="Corner radius">
      <RangeSlider
        :slider-params="borderRadiusSliderOptions"
        :init-value="borderRadius"
        @update:option="(v) => (borderRadius = v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Padding">
      <EditPaddingsElement v-model:value="paddings" />
    </RightPanelSection>
  </RightPanelModal>
</template>

<script lang="ts">
import EditorDropdown from "@/components/dropdown/EditorDropdown.vue";
import RangeSlider from "@/components/rangeSlider/RangeSlider.vue";
import { RangeSliderUnits } from "@/components/rangeSlider/types";
import stylesService from "@/services/styles.service";
import { type Template } from "@cna/api/editor";
import {
  defaultStyles,
  useCommonStylesStore,
  type BodyStyles,
  type DropdownItems,
} from "@cna/common";
import { computed, defineComponent, ref } from "vue";
import { RightPanelModal } from "../../elements";
import { emailRegistrationInputSettingsModalProps } from "./EmailRegistrationInputSettingsModalProps";
import { useEditingStyleUtils } from "./hooks/useEditingStyleUtils";
import ColorPickerElement from "../../elements/rightPanel/ColorPickerElement.vue";
import EditPaddingsElement from "../../elements/rightPanel/EditPaddingsElement.vue";
import RightPanelSection from "../../elements/rightPanel/RightPanelSection.vue";
import RightPanelSubsection from "../../elements/rightPanel/RightPanelSubsection.vue";

const labelTypesOptions: DropdownItems = [
  {
    key: "default",
    value: "Default",
  },
  {
    key: "embedded",
    value: "Embedded",
  },
];

const widthSliderOptions = {
  minValue: 0,
  maxValue: 10,
  units: RangeSliderUnits.PX,
} as const;

const borderRadiusSliderOptions = {
  minValue: 0,
  maxValue: 25,
  units: RangeSliderUnits.PX,
} as const;

export default defineComponent({
  name: "EmailRegistrationInputSettingsModal",
  components: {
    RightPanelSubsection,
    RightPanelSection,
    RightPanelModal,
    EditorDropdown,
    RangeSlider,
    ColorPickerElement,
    EditPaddingsElement,
  },
  props: emailRegistrationInputSettingsModalProps,
  setup(props) {
    const styles = useCommonStylesStore().mainBlockStyles as BodyStyles;
    const labelType = ref(styles.label_type);
    const onLabelTypeChange = (type: string) => (labelType.value = type);

    const formElementStyle = ref(styles.form_element_input_style);

    const {
      copy: formElementStyleCopy,
      getPixelProp,
      updateStyleOption,
    } = useEditingStyleUtils(formElementStyle);

    const borderWidth = getPixelProp(
      "border_width",
      defaultStyles.form_element_input_style_border_width
    );
    const borderRadius = getPixelProp("border_radius");
    const paddingTop = getPixelProp(
      "padding_top",
      defaultStyles.form_element_input_style_padding_top
    );
    const paddingBottom = getPixelProp(
      "padding_bottom",
      defaultStyles.form_element_input_style_padding_bottom
    );
    const paddingLeft = getPixelProp(
      "padding_left",
      defaultStyles.form_element_input_style_padding_left
    );
    const paddingRight = getPixelProp(
      "padding_right",
      defaultStyles.form_element_input_style_padding_right
    );

    const paddings = computed({
      get() {
        return [
          String(paddingTop.value),
          String(paddingRight.value),
          String(paddingBottom.value),
          String(paddingLeft.value),
        ];
      },
      set(v: Array<string>) {
        paddingTop.value = Number(v[0]);
        paddingRight.value = Number(v[1]);
        paddingBottom.value = Number(v[2]);
        paddingLeft.value = Number(v[3]);
      },
    });

    const hoverBorderWidth = getPixelProp(
      "hover_border_width",
      defaultStyles.form_element_input_style_hover_border_width
    );
    const focusBorderWidth = getPixelProp(
      "focus_border_width",
      defaultStyles.form_element_input_style_focus_border_width
    );

    const saveInputSettings = () => {
      stylesService
        .updateTemplateStyles(
          {
            label_type: labelType.value,
            form_element_input_style: {
              ...formElementStyleCopy.value,
              border_style: "solid",
            },
          },
          props.templateName as Template
        )
        .then(() => stylesService.fetchStyles());
    };

    return {
      saveInputSettings,
      styles,
      labelTypesOptions,
      onLabelTypeChange,
      formElementStyleCopy,
      updateStyleOption,
      widthSliderOptions,
      borderWidth,
      borderRadius,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
      hoverBorderWidth,
      focusBorderWidth,
      defaultStyles,
      borderRadiusSliderOptions,
      paddings,
    };
  },
});
</script>
