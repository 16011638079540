<template>
  <TwoColumnLayout>
    <template #first>
      <EmailRegistrationPageContent />
    </template>
    <template #second>
      <SecondaryContent>
        <Markdown
          :data="registerPageSecondaryText"
          field="register_page_secondary_text"
        />
      </SecondaryContent>
    </template>
  </TwoColumnLayout>
</template>

<script lang="ts">
import { useEmailRegistrationSecondaryText } from "@cna/common/hooks/useEmailRegistrationSecondaryText";
import { defineComponent } from "vue";
import SecondaryContent from "../../columns/SecondaryContent.vue";
import TwoColumnLayout from "../../columns/TwoColumnLayout.vue";
import EmailRegistrationPageContent from "./EmailRegistrationPageContent.vue";
export default defineComponent({
  components: {
    EmailRegistrationPageContent,
    TwoColumnLayout,
    SecondaryContent,
  },

  setup() {
    const registerPageSecondaryText = useEmailRegistrationSecondaryText(
      "register_page_secondary_text"
    );
    return {
      registerPageSecondaryText,
    };
  },
});
</script>
