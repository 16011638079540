<template>
  <div
    :class="{
      'floating-label__wrap': true,
      'floating-label__wrap-active': active,
      'floating-label__wrap-disabled': floatingDisabled,
    }"
  >
    <slot></slot>
    <label class="floating-label" :for="labelFor">
      <slot name="label" />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { injectDisableFloatingLabel } from "./providables";

export default defineComponent({
  name: "FloatingLabel",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
  },
  setup() {
    const floatingDisabled = injectDisableFloatingLabel();

    return {
      floatingDisabled,
    };
  },
});
</script>

<style lang="scss">
.floating-label {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 12px 16px;
  transition: all 120ms ease-in-out;
}

.floating-label__wrap.floating-label__wrap-disabled input {
  pointer-events: none;
}

.floating-label__wrap-active:not(.floating-label__wrap-disabled)
  .floating-label,
.floating-label__wrap:not(.floating-label__wrap-disabled):focus-within
  .floating-label {
  top: -8px;
  left: 12px;
  padding: 0 5px;
  line-height: 18px;
  position: absolute;
  background: var(--main_background_color);
  transform: scale(0.85);
  transform-origin: top left;
}
</style>
