import type { PropType } from "vue";
import type { EnterBehaviour } from "./Editor.vue";
import { idWatcher } from "./global";

export const editorProps = {
  text: {
    type: String,
    default: "",
  },
  editing: {
    type: Boolean,
    default: false,
  },
  isNowConnectedPage: Boolean,
  idx: {
    default() {
      return idWatcher.id++;
    },
  },
  padding: {
    type: String,
    default: "8px",
  },
  actionBoxId: {
    type: String,
    required: true,
  },
  enter: {
    type: String as PropType<EnterBehaviour>,
    default: "p",
  },
} as const;
