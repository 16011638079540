import { useProjectsStore } from "@/stores/projects";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { api } from "./api";
import { useNotification } from "@/hooks/use-notification";

class VersionsHistoryService {
  async createVersion(snapshotDescription: string) {
    const projects = useProjectsStore();
    return (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .versions.postCreate({
          description: snapshotDescription,
        })
    ).data.result;
  }

  async publishVersion(versionID: number) {
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .publish.publishVersion({
        versionId: versionID,
        type: versionsHistory.isLocalPortalEnv ? "local" : "cloud",
        system_ids: versionsHistory.selectedVehiclesIDs,
      });
    await this.fetchPublishedDomains();
  }

  async restoreVersion(snapshotId: number) {
    const projects = useProjectsStore();
    const notification = useNotification();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .versions.postRestore(snapshotId)
      .then(() => {
        notification.success();
      });
  }

  async restoreLatestSnapshot() {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .versions.putRestoreLatest();
  }

  async fetchVersions() {
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();

    versionsHistory.versions = (
      await api.v1.projects.project(projects.currentProjectId!).versions.get()
    ).data.result;
  }

  async fetchPublishedDomains() {
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();
    versionsHistory.publishedDomains = (
      await api.v1.projects
        .project(projects.currentProjectId!)
        .versions.getPublishedDomains()
    ).data.result;
  }

  async updateDomain(prefix: string) {
    const projects = useProjectsStore();
    await api.v1.projects.project(projects.currentProjectId!).postDomain({
      prefix,
    });
  }

  async fetchProjectVehiclesEvents() {
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();

    const res = await api.v1.projects
      .project(projects.currentProjectId!)
      .vehicleEvents.get();

    versionsHistory.projectVehiclesEvents = res.data.result;
  }

  async publishPreviewVersion(versionId: number) {
    const projects = useProjectsStore();
    const notification = useNotification();

    await api.v1.projects
      .project(projects.currentProjectId!)
      .publish.postPreview(versionId)
      .then(() => {
        notification.success();
      });
    await this.fetchVersions();
    await this.fetchPublishedDomains();
  }
}

const versionsHistoryService = new VersionsHistoryService();

export default versionsHistoryService;
