import { api } from "@/services/api";
import textsService from "@/services/texts.service";
import type { Template } from "@cna/api/editor";
import { useCommonGeneralStore } from "@cna/common/stores";
import Mustache from "mustache";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch, type Ref } from "vue";

const extractImgSrc = (html: string) => {
  const dom = document.createElement("html");
  dom.innerHTML = html;
  const images = dom.querySelectorAll("img");
  return Array.from(images).map((img) => img.src);
};

const difference = <T>(setA: Set<T>, setB: Set<T>) => {
  const result = new Set<T>();
  setA.forEach((value) => {
    if (!setB.has(value)) {
      result.add(value);
    }
  });
  return result;
};

/**
 * @param dataurl A value from the src attribute of an img HTML tag. For it to be a
 * valid Data URL, it must be in the form
 * "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAMAA...".
 * @returns if the `dataurl` is a valid Data URL, it returns the MIME type, the Base64
 * representation of the file, and a True boolean value.
 * If the `dataurl` is not a valid Data URL, it returns an empty array and false.
 */
const isDataURL = (dataurl: string): [string, string, boolean] => {
  const arr = dataurl.split(",");
  if (!arr || arr.length === 0) {
    return ["", "", false];
  }
  const match = arr[0].match(/:(.{0,50});/);
  if (!match || match.length === 0) {
    return ["", "", false];
  }
  return [match[1], arr[arr.length - 1], true];
};

const dataURLToFile = (
  mimeType: string,
  base64FileContent: string,
  filename: string
) => {
  const bstr = atob(base64FileContent);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);
  for (let i = n - 1; i >= 0; i--) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mimeType });
};

const saveNewImages = async (imgsToBeSaved: Set<string>, newHTML: string) => {
  for (const img of imgsToBeSaved) {
    const [mimeType, base64, dataURL] = isDataURL(img);
    if (!dataURL) {
      continue;
    }
    const file = dataURLToFile(mimeType, base64, "filename");
    await api.v1.files
      .post(file, { params: { random_name: true } })
      .then((r) => {
        newHTML = newHTML.replace(
          img,
          `{{RESOURCES_URL}}/${r.data.result.file_name}`
        );
      });
  }
  return newHTML;
};

const deleteOldImages = async (imgsToBeDeleted: Set<string>) => {
  for (const img of imgsToBeDeleted) {
    const [_, __, dataURL] = isDataURL(img);
    if (!dataURL) {
      const fileName = img.split("/").pop() as string;
      await api.v1.files.del(fileName);
    }
  }
};

export const useField = (
  template: Ref<Exclude<Template, "general">>,
  field: Ref<string>
) => {
  const commonGeneral = useCommonGeneralStore();

  const { currentLanguage, bodyTexts, footerTexts, headerTexts } =
    storeToRefs(commonGeneral);

  const text = computed(() => {
    const texts = {
      body: bodyTexts,
      footer: footerTexts,
      header: headerTexts,
    };

    const t = texts[template.value].value ?? {};
    const f = field.value as keyof typeof t;

    return Mustache.render(t[f] || "", {
      RESOURCES_URL: __RESOURCES_URL__,
    }) as string;
  });

  const editing = ref(false);

  const editValue = ref(text.value);
  watch(text, (val) => {
    editValue.value = val;
    editing.value = false;
  });
  watch(currentLanguage, () => {
    editing.value = false;
  });

  const save = async () => {
    editing.value = false;
    nextTick(async () => {
      // new Intersect old -> no change
      // new - old -> new images to be saved
      // old - new -> old images to be deleted
      const newImgSet = new Set(extractImgSrc(editValue.value));
      const oldImgSet = new Set(extractImgSrc(text.value));
      const imgsToBeSaved = difference(newImgSet, oldImgSet);
      const imgsToBeDeleted = difference(oldImgSet, newImgSet);

      editValue.value = await saveNewImages(imgsToBeSaved, editValue.value);

      await textsService.updateTextField(
        {
          [currentLanguage.value]: editValue.value,
        },
        template.value,
        field.value
      );

      await deleteOldImages(imgsToBeDeleted);
    });
  };

  return {
    save,
    text,
    editing,
    editValue,
  };
};
