import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
import imageResizeForm from "./forms/imageResizeForm.html?raw";

export const imageResizePlugin = () => {
  FroalaEditor.POPUP_TEMPLATES["imageResizePlugin.popup"] = "[_CUSTOM_LAYER_]";

  // Increment formCounter for unique form elements in the popup
  let formCounter = 0;

  FroalaEditor.PLUGINS.imageResizePlugin = function (editor: any) {
    let $popup: HTMLElement[];
    let imageWidth: HTMLInputElement;
    let imageHeight: HTMLInputElement;
    let fullWidthCheckbox: HTMLInputElement;

    let selectedImage = editor.selection.element();

    function initPopup() {
      formCounter += 1;

      const template = {
        custom_layer: imageResizeForm.replace(
          /{{formCounter}}/g,
          formCounter.toString()
        ),
      };
      $popup = editor.popups.create("imageResizePlugin.popup", template);

      // Initialize input fields and checkbox
      imageWidth = $popup[0].querySelector(`#image-width-${formCounter}`)!;
      imageHeight = $popup[0].querySelector(`#image-height-${formCounter}`)!;

      // Setup event listeners for the popup buttons
      const confirmResizeBtn = $popup[0].querySelector(
        'button[data-cmd="confirmResize"]'
      );
      confirmResizeBtn?.addEventListener("click", () => {
        if (selectedImage) {
          if (fullWidthCheckbox.checked) {
            selectedImage.style.width = imageWidth.value = "100%";
            selectedImage.style.height = imageHeight.value = "auto";
          } else {
            selectedImage.style.width = imageWidth.value;
            selectedImage.style.height = imageHeight.value;
          }
        }
        hidePopup();
      });

      // Add event listener for the full-width checkbox
      fullWidthCheckbox = $popup[0].querySelector(
        `#full-width-${formCounter}`
      )!;
      fullWidthCheckbox.addEventListener("change", () => {
        if (fullWidthCheckbox.checked) {
          disableInputs();
        } else {
          enableInputs();
        }
      });

      return $popup;
    }

    // Disable input fields and apply 'disabled' CSS class
    function disableInputs() {
      imageWidth.disabled = true;
      imageHeight.disabled = true;
      imageWidth.classList.add("disabled");
      imageHeight.classList.add("disabled");
    }

    // Enable input fields and remove 'disabled' CSS class
    function enableInputs() {
      imageWidth.disabled = false;
      imageHeight.disabled = false;
      imageWidth.classList.remove("disabled");
      imageHeight.classList.remove("disabled");
    }

    // Set form elements (image width, height, and checkbox state) based on the selected image
    function setFormElements() {
      if (selectedImage) {
        if (
          selectedImage.style.width == "100%" &&
          selectedImage.style.height == "auto"
        ) {
          imageWidth.value = "100%";
          imageHeight.value = "auto";
          fullWidthCheckbox.checked = true;
          disableInputs();
        } else {
          imageWidth.value = `${selectedImage.clientWidth.toString()}px`;
          imageHeight.value = `${selectedImage.clientHeight.toString()}px`;
          fullWidthCheckbox.checked = false;
          enableInputs();
        }
      }
    }

    const showPopup = () => {
      if (!$popup) $popup = initPopup();
      editor.popups.setContainer("imageResizePlugin.popup", editor.$tb);

      // Get the selected image and set the popup position
      selectedImage = editor.image.get()[0] as HTMLImageElement;
      if (selectedImage) {
        const imgOffset = selectedImage.getBoundingClientRect();
        const imgHeight = selectedImage.offsetHeight;

        // Positioned directly below the image
        const left = imgOffset.left;
        const top = imgOffset.top + imgHeight - 10;

        // Show the image popup
        editor.popups.show(`imageResizePlugin.popup`, left, top);
      }

      setFormElements();
      imageWidth?.focus();
    };

    const hidePopup = () => {
      editor.popups.hide("imageResizePlugin.popup");
      editor.selection.clear();
    };

    return {
      showPopup,
      hidePopup,
    };
  };
};
