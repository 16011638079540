<template>
  <div class="modal-alignment-element">
    <span
      class="modal-alignment-element__item"
      v-for="option in options"
      :key="option"
      :class="{ 'modal-alignment-element__item--active': option === value }"
      @click="() => $emit('update:value', option)"
      @keyup.enter="() => $emit('update:value', option)"
      tabindex="0"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "AlignmentElement",
  props: {
    options: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => ["left", "center", "right"],
    },
    value: {
      type: String,
      required: true,
      default: "left",
    },
  },
  emits: ["update:value"],
});
</script>

<style lang="scss" scoped>
.modal-alignment-element {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 11px;
  border-radius: 5px;
  border: solid 1px $border-color;
  max-width: 66px;
  flex-wrap: wrap;
}

.modal-alignment-element__item {
  height: 6px;
  width: 6px;
  background-color: $default-element-color;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: #6888ab;
  }

  &--active {
    background-color: $active-element-color;
  }

  &:focus {
    outline: none;
    background-color: #6888ab;
  }
}
</style>
