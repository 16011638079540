<template>
  <div class="spaced-list">
    <slot></slot>
  </div>
</template>

<style scoped>
.spaced-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
