<template>
  <div id="email-registration-page" class="page email-registration-page">
    <HeaderLayout />
    <EmailRegistrationPageBodyTwoColumn v-if="isTwoColumn" />
    <EmailRegistrationPageBody v-else />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { useProjectsStore } from "@/stores/projects";
import {
  EmailRegistrationPageBody,
  EmailRegistrationPageBodyTwoColumn,
  FooterLayout,
  HeaderLayout,
} from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailRegistrationPage",
  components: {
    HeaderLayout,
    FooterLayout,
    EmailRegistrationPageBody,
    EmailRegistrationPageBodyTwoColumn,
  },
  setup() {
    const projects = useProjectsStore();
    const { isTwoColumn } = storeToRefs(projects);

    return {
      isTwoColumn,
    };
  },
});
</script>
