import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const radioButtonThemeModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Edit Radio Button Field Theme",
  },
  ...modalProps,
} as const;

export type RadioButtonThemeModalProps = ExtractPropTypes<
  typeof radioButtonThemeModalProps
>;
