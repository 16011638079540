<template>
  <div>
    <Teleport to="body">
      <Transition name="modal-t">
        <div
          v-if="isOpen"
          class="modal-overlay"
          @mouseup.self="onMouseup"
          @mousedown.self="onMousedown"
        >
          <div class="modal-container" @mouseup="onContainerMouseup">
            <div class="modal" v-bind="$attrs">
              <slot />
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, toRefs } from "vue";
import { modalEmits, modalProps } from "./props";

export default defineComponent({
  name: "Modal",
  props: modalProps,
  emits: modalEmits,
  setup(props, ctx) {
    const { closable } = toRefs(props);

    const isOpen = ref(false);

    const open = () => {
      setTimeout(() => {
        document.body.style.overflow = "hidden";
        isOpen.value = true;
      }, 50);
    };

    const close = () => {
      isOpen.value = false;
      setTimeout(() => {
        ctx.emit("close");
      }, 100);
    };

    onMounted(open);

    onUnmounted(() => {
      document.body.style.overflow = "";
    });

    const closeModal = () => {
      if (closable.value) {
        close();
      }
    };

    const clickingOutside = ref(false);
    const onMousedown = () => {
      clickingOutside.value = true;
    };

    const onMouseup = () => {
      if (!clickingOutside.value) return;

      clickingOutside.value = false;
      closeModal();
    };

    const onContainerMouseup = () => {
      clickingOutside.value = false;
    };

    return {
      isOpen,
      onMousedown,
      onMouseup,
      onContainerMouseup,
    };
  },
});
</script>

<style>
.modal-overlay {
  transition: opacity 0.3s ease;
}
.modal-container {
  transition: all 0.3s ease;
}

.modal-t-enter-from {
  opacity: 0;
}

.modal-t-leave-to {
  opacity: 0;
}
.modal-t-enter-from .modal-container,
.modal-t-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
