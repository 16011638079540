<template>
  <n-color-picker
    :value="syncValue"
    :modes="['hex']"
    :show-alpha="showAlpha"
    :swatches="swatches"
    :actions="actions"
    :on-update-show="onUpdateShow"
    show-preview
    :on-update:value="onSelectColor"
    :style="{ 'max-width': '100px' }"
    @confirm="onConfirm"
    v-model:show="showSync"
    size="large"
    :theme-overrides="{
      heightLarge: '100%',
      borderRadius: '4px',
      border: 'none',
    }"
    :to="to"
  >
    <template #label>
      <span v-if="showLabel">{{ syncValue }}</span>
    </template>
  </n-color-picker>
</template>

<script lang="ts">
import { useSwatches } from "@/components/colorPicker/useSwatches";
import { RGBtoHex } from "@/utils/general";
import ContextMenu from "@imengyu/vue3-context-menu";
import { NColorPicker } from "naive-ui";
import type { ActionType } from "naive-ui/es/color-picker/src/utils";
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  toRefs,
  watch,
  type PropType,
  type Ref,
} from "vue";

export default defineComponent({
  name: "ColorPicker",
  components: { NColorPicker },
  props: {
    color: {
      type: String,
    },
    actions: {
      type: Array as PropType<ActionType[]>,
      required: false,
      default: () => ["confirm"],
    },
    show: {
      type: Boolean,
      default: undefined,
    },
    showAlpha: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Boolean],
      default: "body",
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeOnSwatchSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:option", "update:show"],
  setup(props, { emit }) {
    const { color, show } = toRefs(props);

    const syncValue: Ref<string> = ref(color.value ?? "");
    const { swatches, addColor, deleteColor } = useSwatches();
    const withConfirmButton: Ref<boolean> = computed(() =>
      props.actions?.includes("confirm")
    );

    const onUpdateShow = (value: boolean) => {
      if (!props.actions.includes("confirm") && value === false) {
        emit("update:option", syncValue.value);
        addColor(syncValue.value);
      }
    };

    const onSelectColor = (value: string) => {
      syncValue.value = value;
      if (!withConfirmButton.value) {
        emit("update:option", value);
      }
    };

    const onConfirm = (value: string) => {
      emit("update:option", value);
      addColor(value);
    };

    const onToggle = (value: boolean) => {
      if (!value && withConfirmButton.value) {
        syncValue.value = color.value ?? "";
      }
    };

    watch(
      () => props.color,
      (color) => {
        syncValue.value = color ?? "";
      }
    );

    const showCopy = ref(show.value ?? false);

    watch(show, (val) => {
      showCopy.value = val ?? false;
    });

    const showSync = computed({
      get: () => showCopy.value,
      set: (val) => {
        if (contextMenuOpen.value) {
          return;
        }

        showCopy.value = val;
        emit("update:show", val);
      },
    });

    watch(showCopy, onToggle);

    const contextMenuOpen = ref(false);

    const transformAndDeleteColor = (event: MouseEvent | KeyboardEvent) => {
      const color = RGBtoHex(
        (event.target as HTMLElement).style.backgroundColor ||
          (document.activeElement?.children[0] as HTMLElement).style
            .backgroundColor
      );

      deleteColor(color);
    };

    const onSwatchContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      contextMenuOpen.value = true;
      ContextMenu.showContextMenu({
        getContainer: () => event.target as HTMLElement,
        x: 0,
        y: 0,
        items: [
          {
            label: "Delete color",
            onClick(e) {
              e?.preventDefault();
              e?.stopImmediatePropagation();
              transformAndDeleteColor(event);
              contextMenuOpen.value = false;
            },
          },
        ],
        theme: "dark",
        zIndex: 100001,

        onClose() {
          contextMenuOpen.value = false;
        },
      });
    };

    const onSwatchKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Delete") {
        transformAndDeleteColor(event);
      }
    };

    const onSwatchClick = (event: MouseEvent) => {
      if (props.closeOnSwatchSelect) {
        event.preventDefault();
        showCopy.value = false;
      }
    };

    watch(showSync, (val) => {
      nextTick(() => {
        const swatches: NodeListOf<HTMLElement> = document.querySelectorAll(
          ".n-color-picker-swatch"
        );
        if (val === true) {
          swatches?.forEach((s) => {
            s.addEventListener("contextmenu", onSwatchContextMenu);
            s.addEventListener("keydown", onSwatchKeyDown);
            s.addEventListener("click", onSwatchClick);
          });
          return;
        }

        swatches?.forEach((s) => {
          s.removeEventListener("contextmenu", onSwatchContextMenu);
          s.removeEventListener("keydown", onSwatchKeyDown);
          s.removeEventListener("click", onSwatchClick);
        });
      });
    });

    return {
      syncValue,
      swatches,
      onToggle,
      addColor,
      onSelectColor,
      onConfirm,
      showSync,
      onUpdateShow,
    };
  },
});
</script>

<style>
.mx-menu-ghost-host {
  position: static;
}

.mx-context-menu {
  height: 42px !important;
  left: 18px !important;
  top: 13px !important;
  max-height: unset !important;
}

.mx-context-menu-scroll {
  display: none !important;
}
</style>
