<template>
  <component :is="config.type" to="/" class="header__logo">
    <img
      v-if="config.imageUrl"
      class="header__img"
      :src="config.imageUrl"
      alt="Logo"
    />
    <span v-else-if="showLogoPlaceholder" class="logo-placeholder">
      <i class="icon-edit-image" />
      Add Logo
    </span>
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { type LogoConfig } from "../types";
import { injectShowLogoPlaceholder } from "./providables";

export default defineComponent({
  name: "LogoImage",
  props: {
    config: {
      type: Object as PropType<LogoConfig>,
      required: true,
    },
  },
  setup() {
    const showLogoPlaceholder = injectShowLogoPlaceholder();

    return {
      showLogoPlaceholder,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__img {
  max-height: 3rem;
  max-width: min(100%, 400px);
}

.logo-placeholder {
  height: 40px;
  width: 100px;
  display: flex;
  color: var(--header_background_color);
  align-items: center;
  justify-content: center;
  border: 1px var(--header_background_color) dashed;
  font-size: 12px;
  border-radius: 6px;
  gap: 4px;
  filter: invert(80%);
}
</style>
