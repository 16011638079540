import { memoFlags, memoPermissions } from "@/services/api";
import {
  permissions as permissionsArr,
  type Permission,
} from "@cna/api/editor";
import type { Replace } from "type-fest";
import { computed, ref, type ComputedRef } from "vue";

const permissions = ref<readonly Permission[]>([]);

const createComputedRef = (p: Permission) =>
  computed(() => permissions.value.includes(p));

const allPermissionRefs = () => {
  const res: any = {};

  for (const p of permissionsArr) {
    const key = p.replace(/\./g, "");

    res[key] = createComputedRef(p);
  }

  return res as Record<
    Replace<Permission, ".", "", { all: true }>,
    ComputedRef<boolean>
  >;
};

const allComputedRefs = allPermissionRefs();

export const usePermissions = () => {
  memoFlags().then((res) => {
    if (!res.data.result.permissions) {
      // if not enabled, then everyone has all permissions
      permissions.value = permissionsArr;
      return;
    }

    memoPermissions().then((res) => {
      permissions.value = res.data.result;
    });
  });

  return {
    permissions,
    ...allComputedRefs,
  };
};
