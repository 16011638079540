<template>
  <div class="vehicle-events-table">
    <VehicleEventsFiltering
      v-model:filtering="filters"
      :initial-value="INITIAL_FILTER_SETTINGS"
    />
    <SimpleTable
      :rows="filteredItems"
      :columns="columns"
      row-key="system_id"
      fixed
      fixed-height="min(90vh - 280px, 500px)"
    />
  </div>
</template>

<script lang="ts">
import SimpleTable from "@/components/table/SimpleTable.vue";
import StatusColumn from "@/components/table/columns/StatusColumn.vue";
import type { TableColumnConfig } from "@/components/table/types";
import VehicleEventsFiltering from "@/components/vehicleEventsFiltering/VehicleEventsFiltering.vue";
import { vTooltip } from "@/directives/tooltip";
import { INITIAL_FILTER_SETTINGS } from "@/stores/INITIAL_VALUES";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import type { VehicleEvent } from "@cna/api/editor";
import {
  computed,
  defineComponent,
  h,
  ref,
  type ComponentObjectPropsOptions,
} from "vue";
import useVehicleEventsHandler from "./useVehicleEventsHandler";

export default defineComponent({
  name: "VehicleEventsTable",
  components: {
    SimpleTable,
    VehicleEventsFiltering,
  },
  props: {
    showCheckboxes: Boolean,
  },
  setup(props) {
    const versionsHistory = useVersionsHistoryStore();
    const filters = ref(INITIAL_FILTER_SETTINGS);
    versionsHistory.selectedVehiclesIDs = [];

    const { filteredItems } = useVehicleEventsHandler(filters);

    versionsHistory.selectedVehiclesIDs = [];

    const lastSnapshotVersion = computed(() => versionsHistory.lastVersionId);
    const filteredItemIDs = computed(() =>
      filteredItems.value.map((x) => x.system_id)
    );

    const columns = computed(
      () =>
        [
          props.showCheckboxes
            ? {
                type: "Checkbox",
                heading: "Vehicle ID",
                headerColumnConfig: {
                  type: "CheckboxColumn",
                  onClick: (value: boolean) =>
                    value
                      ? (versionsHistory.selectedVehiclesIDs =
                          filteredItemIDs.value)
                      : (versionsHistory.selectedVehiclesIDs = []),
                },
                width: "20%",
                columnKey: "system_id",
              }
            : {
                type: "Text",
                heading: "Vehicle ID",
                width: "20%",
                columnKey: "system_id",
              },
          {
            type: "Text",
            heading: "Software V.",
            width: "10%",
            columnKey: "gvc_version",
          },
          {
            type: "Render",
            heading: "Content V.",
            width: "10%",
            columnKey: "content_version",
            render(row) {
              const latestVersion = lastSnapshotVersion.value;
              const hasTooltip = latestVersion > row.content_version;
              const el = h("span", {}, { default: () => row.content_version });

              return !hasTooltip
                ? el
                : vTooltip("This version is older than the latest one")(el);
            },
          },
          {
            type: "Text",
            heading: "Published By",
            width: "20%",
            columnKey: "published_by",
          },
          {
            type: "DateTime",
            heading: "Last Synced",
            width: "20%",
            columnKey: "timestamp",
          },
          {
            type: "Render",
            heading: "Status",
            width: "calc(20% - 30px)",
            ellipsis: true,
            columnKey: "status",
            render(row) {
              return h(
                StatusColumn as ComponentObjectPropsOptions,
                { status: row.status },
                {
                  default: () => row.message,
                }
              );
            },
          },
          {
            type: "Details",
            width: "30px",
            columnStyle: "padding: 0px",
            show: (row) => row.status !== "success",
            render: (row) =>
              h(
                "div",
                { style: "padding: 24px; width: 100%; font-size: 14px" },
                row.message
              ),
          },
        ] as TableColumnConfig<VehicleEvent>[]
    );

    return {
      filters,
      filteredItems,
      INITIAL_FILTER_SETTINGS,
      columns,
    };
  },
});
</script>

<style lang="scss" scoped>
.vehicle-events-table {
  margin-bottom: 20px;
}
</style>
