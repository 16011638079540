const colorToHex = (color: string) => {
  const hexadecimal = Number(color).toString(16);
  return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
};

export const RGBtoHex = (color: string) => {
  const colors = color
    .substring(4, color.length - 1)
    .replace(/ /g, "")
    .split(",");
  return (
    "#" + colorToHex(colors[0]) + colorToHex(colors[1]) + colorToHex(colors[2])
  );
};
