import type { Template } from "@cna/api/editor";
import type { PropType } from "vue";

export const mdFieldAndTemplateProps = {
  field: {
    type: String,
    required: true,
  },
  template: {
    type: String as PropType<Exclude<Template, "general">>,
    default: "body",
  },
} as const;

export const readonlyMdProps = {
  mIf: {
    type: Boolean,
    required: false,
    default: true,
  },
  data: {
    type: String,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  vars: Object as PropType<any>,
};

export const mdProps = {
  ...readonlyMdProps,
  // unused for readonly, added to match editor version of component
  ...mdFieldAndTemplateProps,
  enter: {
    type: String as PropType<"p" | "div" | "br">,
    default: "p",
  },
  padding: String,
} as const;
