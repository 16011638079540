import CheckboxColumn from "@/components/table/columns/CheckboxColumn.vue";
import DateTimeColumn from "@/components/table/columns/DateTimeColumn.vue";
import LinkColumn from "@/components/table/columns/LinkColumn.vue";
import StatusColumn from "@/components/table/columns/StatusColumn.vue";
import TextColumn from "@/components/table/columns/TextColumn.vue";
import { type TableColumnConfig } from "@/components/table/types";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { computed, defineComponent, h, ref, type SetupContext } from "vue";
import ActionsColumn from "./columns/ActionsColumn.vue";

import { usePermissions } from "@/hooks/usePermissions";
import { useProjectsStore } from "@/stores/projects";
import useTableButtonsConfigs from "./useTableButtonsConfigs";

const undefinedComponent = defineComponent({
  name: "empty-component",
  setup: () => () => undefined,
});

const rowDetailsButton = defineComponent({
  name: "row-details-button",
  setup: () => {
    const icon = ref("icon-dropdown");
    const toggleIcon = () => {
      if (icon.value === "icon-dropdown") {
        icon.value = "icon-dropdown-up";
        return;
      }
      icon.value = "icon-dropdown";
    };

    return () =>
      h(
        "button",
        { onClick: toggleIcon },
        h("span", { class: icon.value, style: "cursor: pointer" })
      );
  },
});

export const getSnapshotsTableColumnComponentAndProps = (
  row: any,
  column: TableColumnConfig,
  hasRowDetails: boolean,
  ctx: SetupContext
) => {
  const projects = useProjectsStore();
  const versionsHistory = useVersionsHistoryStore();
  const {
    projectversionpublishpreview,
    projectversionpublishproduction,
    projectversionrestore,
  } = usePermissions();

  let columnComponent;
  let columnProps;

  if (column.type === "DateTime") {
    columnComponent = DateTimeColumn;
    columnProps = { dateTime: row[column.columnKey] };
  } else if (column.type === "Text") {
    columnComponent = TextColumn;
    columnProps = { text: row[column.columnKey], ellipsis: column.ellipsis };
  } else if (column.type === "Checkbox") {
    const index = computed(() =>
      versionsHistory.selectedVehiclesIDs.indexOf(row.system_id)
    );
    const isSelected = computed(() => index.value !== -1);
    columnComponent = CheckboxColumn;
    columnProps = {
      title: row.system_id,
      value: isSelected.value,
      onClick: (value: boolean) =>
        value
          ? versionsHistory.selectedVehiclesIDs.push(row.system_id)
          : versionsHistory.selectedVehiclesIDs.splice(index.value, 1),
    };
  } else if (column.type === "Link") {
    columnComponent = LinkColumn;
    columnProps = { text: row };
  } else if (column.type === "Status") {
    columnComponent = StatusColumn;
    columnProps = {
      text: row[column.columnKey],
      status: row[column.columnKey],
    };
  } else if (column.type === "Actions") {
    columnComponent = ActionsColumn;
    const isLocalPortalEnv = computed(() => versionsHistory.isLocalPortalEnv);
    const {
      publishButtonConfig,
      publishToStgButtonConfig,
      restoreButtonConfig,
      setDefaultVersionConfig,
      previewButtonConfig,
    } = useTableButtonsConfigs(row);
    const canPreview =
      !row.previewing &&
      !isLocalPortalEnv.value &&
      projectversionpublishpreview.value;

    const canPublish =
      (!row.published || isLocalPortalEnv.value) &&
      projectversionpublishproduction.value;
    const canSetDefaultVersion =
      isLocalPortalEnv.value &&
      row.id !== projects.currentProject.default_version;

    const columnButtons: any[] = [previewButtonConfig];

    if (projectversionrestore.value) {
      columnButtons.push(restoreButtonConfig);
    }

    if (canPreview) {
      columnButtons.push(publishToStgButtonConfig);
    }
    if (canPublish) {
      columnButtons.push(publishButtonConfig);
    }

    if (canSetDefaultVersion) {
      columnButtons.push(setDefaultVersionConfig);
    }

    columnProps = {
      text: "",
      buttonConfig: {
        iconClose: "icon-dropdown",
        iconOpen: "icon-dropdown-up",
        menuPosition: "right",
      },
      menuListConfig: {
        items: columnButtons,
      },
    };
  } else if (column.type === "Details") {
    columnComponent = hasRowDetails ? rowDetailsButton : undefinedComponent;
    columnProps = {
      onClick: () => ctx.emit("toggle-details"),
    };
  }

  return { columnComponent, columnProps };
};
