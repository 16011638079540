import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { type ListProjectData } from "../../../../api/src/editor";
import { metaKeyOrEditor } from "../../routes/router";
import { useProjectsStore } from "../../stores/projects";

export const usePagesDropdownHelper = () => {
  const router = useRouter();
  const route = useRoute();
  const selectedProjectId = ref(0);
  const searchValue = ref("");
  const projectsStore = useProjectsStore();
  const { projects, currentProject } = storeToRefs(projectsStore);

  const areProjectsLoaded = computed(() => projects.value.length > 0);

  const matchProjectAttributes = (projectAttr: string | number | undefined) => {
    if (!projectAttr) return;

    return String(projectAttr)
      .toLowerCase()
      .includes(searchValue.value.toLowerCase(), 0);
  };

  const projectsDropdownOptions = computed(() => {
    const searchTerm = searchValue.value.toLowerCase();
    const filteredItems = [...projects.value]
      .map((project: ListProjectData) => {
        let points = 1; // Default points for each project
        const name = project.name.toLowerCase();

        const includeName = name.includes(searchTerm);
        const matchesAttributes =
          matchProjectAttributes(project.icomera_customer_id) ||
          matchProjectAttributes(project.icomera_account_id) ||
          matchProjectAttributes(project.local_attributes?.[0].portal_key);

        if (!includeName && !matchesAttributes) {
          return { ...project, points: -10 };
        }

        if (name === searchTerm) {
          return { ...project, points: 10 };
        }

        if (name.startsWith(searchTerm)) {
          points += 3;
        }
        if (name.endsWith(searchTerm)) {
          points += 2;
        }
        const regex = new RegExp(`\\b${searchTerm}\\b`, "i");
        if (regex.test(name)) {
          points += 1;
        }

        return { ...project, points };
      })
      .filter((project) => project.points > -10)
      .sort((a, b) => b.points - a.points);

    return filteredItems.map((project) => ({
      key: project.id,
      value: project.name,
    }));
  });

  const onSelectProject = (projectId: number) => {
    selectedProjectId.value = projectId;
    searchValue.value = "";
  };

  const selectedProject = computed(() => {
    searchValue.value = "";
    const index = [...projects.value].findIndex(
      (project) => project.id === selectedProjectId.value
    );
    return projects.value[index];
  });
  const redirectToProject = async (value: string | number) => {
    searchValue.value = "";
    await router.push({
      path: `/${value}/${metaKeyOrEditor(route)}`,
      query: { ...route.query },
    });
  };

  const onChangeSearch = (value: string | number) => {
    searchValue.value = String(value);
  };

  return {
    searchValue,
    projectsDropdownOptions,
    currentProject,
    selectedProject,
    selectedProjectId,
    areProjectsLoaded,
    onSelectProject,
    onChangeSearch,
    redirectToProject,
  };
};
