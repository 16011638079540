const route = (opts: { path: string; title: string }) =>
  ({
    path: opts.path,
    name: `admin-${opts.path}`,
    component: () => import(`@/admin/views/${opts.path}/index.vue`),
    meta: {
      title: opts.title,
      key: `admin/${opts.path}`,
    },
  } as const);

export const views = [
  route({ path: "attributes", title: "Attributes" }),
  route({ path: "features", title: "Features" }),
  route({ path: "templates", title: "Templates" }),
  route({ path: "environment", title: "Environment" }),
  route({ path: "venue-url", title: "Venue URL" }),
  route({ path: "fonts", title: "Fonts" }),
  route({ path: "editor-settings", title: "Editor settings" }),
  route({ path: "copy-project", title: "Copy project" }),
  route({ path: "page-title", title: "Page title" }),
  route({ path: "migrate-project", title: "Migrate project" }),
] as const;
