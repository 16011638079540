import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const trafficUsageMessageEditingModalProps = {
  ...modalProps,
} as const;

export type TrafficUsageMessageEditingModalProps = ExtractPropTypes<
  typeof trafficUsageMessageEditingModalProps
>;
