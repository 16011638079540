<template>
  <div id="db_now-connected" class="page db_now-connected-page">
    <HeaderLayout />
    <DBNowConnectedMainBlock />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import {
  DBNowConnectedMainBlock,
  FooterLayout,
  HeaderLayout,
} from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DBNowConnectedPage",
  components: {
    HeaderLayout,
    FooterLayout,
    DBNowConnectedMainBlock,
  },
});
</script>
