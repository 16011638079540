<template>
  <div
    ref="self"
    class="action-box"
    :class="{ passthrough: passthrough }"
    @click.prevent="$emit('click')"
  ></div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    passthrough: Boolean,
  },
  setup() {
    const self = ref();
    return {
      self,
    };
  },
});
</script>

<style>
.action-box {
  position: fixed;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  z-index: 70;
}

.action-box:not(.passthrough) {
  cursor: pointer;
}

.action-box:hover,
.action-box.passthrough {
  border-color: var(--edit_text_hover_color);
}

.action-box.passthrough,
body.scrolling .action-box {
  pointer-events: none;
}
</style>
