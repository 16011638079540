import { type EditorFontsList } from "@/stores/types";
import {
  type BodyStyles,
  type FontData,
  useCommonStylesStore,
} from "@cna/common";
import { defineStore } from "pinia";
import { computed } from "vue";

export const useStylesStore = defineStore("styles", () => {
  const commonStyles = useCommonStylesStore();

  const hasButtonBorder = computed(() => {
    const { buttons_props } = commonStyles.appliedStyles.body as BodyStyles;

    return buttons_props.border_width > 0;
  });

  const buttonProps = computed(() => {
    const { buttons_props } = commonStyles.appliedStyles.body as BodyStyles;
    return buttons_props;
  });

  const fontsList = computed(() =>
    commonStyles.appliedStyles.general.fonts?.list?.reduce(
      (fontsList: EditorFontsList, element: FontData) => {
        return {
          ...fontsList,
          [element.value]: element.title,
        };
      },
      {}
    )
  );

  return {
    hasButtonBorder,
    buttonProps,
    fontsList,
  };
});
