import {
  useElementVisibility,
  watchDebounced,
  type MaybeComputedElementRef,
} from "@vueuse/core";

export const onElementInvisible = (
  el: MaybeComputedElementRef,
  cb: () => void
) => {
  const isVisible = useElementVisibility(el);

  watchDebounced(
    isVisible,
    (val, prevVal) => {
      if (prevVal && !val) {
        cb();
      }
    },
    { debounce: 500, maxWait: 1000 }
  );
};
