import { type UseElementBoundingReturn } from "@vueuse/core";
import { computed, ref, type Ref } from "vue";
import { editId } from "./global";

export const useContainerPosition = (
  padding: Ref<string>,
  container: () => HTMLDivElement | null | undefined,
  containerBounds: UseElementBoundingReturn
) => {
  const generalPage = document.querySelector(".general-page");
  const getContainerPosition = () => {
    const generalPageBounds = generalPage?.getBoundingClientRect();
    const roContainer = container();

    if (!roContainer) {
      return;
    }

    containerBounds.update();
    const parent = roContainer.parentElement;
    const parentRect = parent!.getBoundingClientRect();

    const selfTop = Math.max(containerBounds.top.value, 0);
    const parentTop = Math.max(parentRect.top, 0);

    const selfBottom = Math.max(
      window.innerHeight - containerBounds.bottom.value,
      0
    );
    const parentBottom = Math.max(window.innerHeight - parentRect.bottom, 0);
    const pageBottom = Math.max(
      window.innerHeight - generalPageBounds!.bottom,
      0
    );

    return {
      top: `max(calc(${selfTop}px - ${padding.value}), calc(${parentTop}px - ${
        padding.value
      }), ${generalPageBounds!.top}px)`,
      left: `calc(${containerBounds.left.value}px - ${padding.value})`,
      width: `calc(${containerBounds.width.value}px + 2 * ${padding.value})`,
      bottom: `max(calc(${selfBottom}px - ${padding.value}), calc(${parentBottom}px - ${padding.value}), ${pageBottom}px)`,
    };
  };

  return {
    getContainerPosition,
  };
};
export type UseContainerPosition = ReturnType<typeof useContainerPosition>;
export const useActionBox = (
  idx: Ref<number>,
  editingDisabled: Ref<boolean>,
  getContainerPosition: UseContainerPosition["getContainerPosition"]
) => {
  const actionBox = ref<{ self: HTMLDivElement } | null>(null);

  const positionActionBox = () => {
    if (!actionBox.value) {
      return;
    }
    const position = getContainerPosition();

    if (!position) {
      return;
    }

    const { top, bottom, width, left } = position;

    actionBox.value.self.style.top = top;
    actionBox.value.self.style.left = left;
    actionBox.value.self.style.width = width;
    actionBox.value.self.style.bottom = bottom;
  };

  const actionBoxEnabled = computed(
    () =>
      !editingDisabled.value &&
      (editId.value === idx.value || editId.value === -1)
  );

  return {
    actionBox,
    positionActionBox,
    actionBoxEnabled,
  };
};
