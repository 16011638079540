import { usePermissions } from "@/hooks/usePermissions";
import { openModal } from "@/modals";
import { storeToRefs } from "pinia";
import { type DirectiveBinding } from "vue";
import { useProjectsStore } from "../stores/projects";

const selectedClass = "theme-editable-selected";

export default {
  mounted(element: any, prop: DirectiveBinding) {
    const { projectdesignwrite } = usePermissions();

    if (!projectdesignwrite.value) {
      return;
    }

    const projects = useProjectsStore();
    const { isDBPortalType } = storeToRefs(projects);
    const isPreview = element.closest(".portal-preview");

    if (isPreview || isDBPortalType.value) return;

    const actionBox = document.createElement("button");
    actionBox.id = `edit-theme-${prop.value.fieldName}`; // useful for tests
    let type = "";
    for (const [key, value] of Object.entries(prop.modifiers)) {
      if (value === true) {
        type = key;
      }
    }
    actionBox.className = `theme-edit-box theme-edit-box--${type}`;

    if (prop.value.spaced) {
      actionBox.classList.add("theme-edit-box--spaced");
    }
    element.style.position = "relative";
    actionBox.setAttribute("aria-label", "Edit actionBox");

    actionBox.onclick = (event: Event) => {
      // Prevent form events when edit actionBox is inside of the button
      event.preventDefault();
      if (actionBox.classList.contains(selectedClass)) {
        return;
      }
      const editedElement = document.querySelector("." + selectedClass);
      editedElement?.classList.remove(selectedClass);
      actionBox.classList.add(selectedClass);

      const baseProps = {
        templateName: prop.value.templateName,
        title: prop.value.modalTitle,
        pageName: prop.value.pageName,
        closable: true,
      };

      const props =
        prop.value.modalType === "ButtonStylesEditingModal"
          ? baseProps
          : {
              fieldName: prop.value.fieldName,
              ...baseProps,
            };

      openModal(prop.value.modalType, props);
    };

    element.appendChild(actionBox);

    element.style.zIndex = String(countParents(element));
    actionBox.style.zIndex = String(countParents(element));
  },
};

function countParents(element: HTMLElement) {
  let count = 0;
  let currentElement = element.parentElement;

  while (currentElement) {
    count++;
    currentElement = currentElement.parentElement;
  }

  return count;
}
