import "froala-editor/css/froala_style.min.css";

import "@/assets/styles/main.scss";
import Tooltip from "@/directives/tooltip";
import ContextMenu from "@imengyu/vue3-context-menu";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import { createApp } from "vue";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import App from "./App.vue";
import router from "./routes/router";
import { pinia } from "./stores";

import { applyDefaultStyles } from "@cna/common";
import EmailRegFieldEditor from "./components/editor/EmailRegFieldEditor.vue";
import MarkdownEditor from "./components/editor/MarkdownEditor.vue";
import SimpleTextEditor from "./components/editor/TextEditor.vue";
import ThemeActionBox from "./directives/themeActionBox";

createApp(App)
  .use(pinia)
  .use(router)
  .use(Tooltip)
  .use(ContextMenu)
  .use(VueAwesomePaginate)
  .directive("theme-action-box", ThemeActionBox)
  .component("Markdown", MarkdownEditor)
  .component("EmailField", EmailRegFieldEditor)
  .component("SimpleText", SimpleTextEditor)
  .mount("#app");

applyDefaultStyles();
