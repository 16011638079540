import { defaultStyles, useCommonImagesStore } from "@cna/common";
import { defineStore } from "pinia";
import { computed } from "vue";

export const useImagesStore = defineStore("images", () => {
  const commonImages = useCommonImagesStore();
  const bodyBackground = computed(() => {
    const { background_color } = commonImages.imagesData.body.background.props;
    if (!background_color) {
      commonImages.imagesData.body.background.props.background_color =
        defaultStyles.background_color;
    }
    return commonImages.imagesData.body.background;
  });

  return {
    bodyBackground,
  };
});
